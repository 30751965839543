import {
  recipeUtils,
  restaurantUtils,
  businessUtils,
  benefitUtils,
  companyUtils,
  blogUtils,
  employerUtils
} from "../../../utils/entityUtils";

export const prepareSearchResults = (searchResults, entityCategories, entityTags, userLocation) => {
  //prepare entities
  searchResults.restaurants = prepareRestaurants(
    searchResults.restaurants,
    entityCategories,
    entityTags,
    userLocation
  );

  searchResults.businesses = prepareBusinesses(
    searchResults.businesses,
    entityCategories,
    entityTags,
    userLocation
  );

  searchResults.recipes = prepareRecipes(
    searchResults.recipes,
    entityCategories,
    entityTags,
    userLocation
  );
  searchResults.benefits = prepareBenefits(searchResults.restaurants, searchResults.businesses);
  searchResults.companies = prepareCompanies(
    searchResults.companies,
    entityCategories,
    entityTags,
    userLocation
  );

  searchResults.blogs = prepareBlogs(
    searchResults.blogs,
    entityCategories,
    entityTags,
    userLocation
  )

  searchResults.employers = prepareEmployers(
    searchResults.employers,
    entityCategories,
    entityTags,
    userLocation
  )

};

const prepareRestaurants = (restaurants, entityCategories, entityTags, userLocation) => {
  if (restaurants) {
    return restaurants.map((restaurant) =>
      restaurantUtils.prepareRestaurant(
        restaurant,
        entityCategories.restaurants,
        entityTags,
        userLocation
      )
    );
  }
  return restaurants;
};

const prepareBusinesses = (businesses, entityCategories, entityTags, userLocation) => {
  if (businesses) {
    const branches = businessUtils.prepareBusinessBranchesList(businesses);

    return branches.map((businessBranch) =>
      businessUtils.prepareBusinessBranch(
        businessBranch,
        entityCategories.businessesSubs,
        entityTags,
        userLocation,
        entityCategories.businesses
      )
    );
  }
  return [];
};

const prepareRecipes = (recipes, entityCategories) => {
  if (recipes) {
    return recipes.map((recipe) => recipeUtils.prepareRecipe(recipe, entityCategories.recipes));
  }
  return recipes;
};

const prepareBenefits = (restaurants, businesses) => {
  return benefitUtils.prepareBenefitsList(restaurants, businesses);
};

const prepareCompanies = (companies, entityCategories, entityTags, userLocation) => {
  if (companies) {
    return companies.map((company) =>
      companyUtils.prepareCompany(company, entityCategories.companiesSubs, entityTags, userLocation)
    );
  }
  return companies;
};


const prepareBlogs = (blogs, entityCategories, entityTags, userLocation) => {
  if (blogs) {
    return blogs.map((blog) =>
      blogUtils.prepareBlog(blog, entityCategories.blogs, entityTags, userLocation)
    );
  }
  return blogs;
};

const prepareEmployers = (employers, entityCategories, entityTags, userLocation) => {
  if (employers) {
    return employers.map((employer) =>
  
      employerUtils.prepareEmployer(employer, entityCategories.employers, entityTags, userLocation)
    );
  }
  return employers;
};
