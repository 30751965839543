import { SET_FULL_RESTAURANT } from "../actions/restaurantActions";
import { ADD_NEW_REVIEW_RESTAURANT } from "../actions/entitiesActions";

const defaultState = {
  fullRestaurant: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_FULL_RESTAURANT:
      return { ...state, fullRestaurant: action.payload };
    case ADD_NEW_REVIEW_RESTAURANT:
      const newRestaurant = { ...state.fullRestaurant };
      newRestaurant.reviews = [action.payload, ...newRestaurant.reviews];
      return { ...state, fullRestaurant: newRestaurant };
  }
  return state;
};
