import { t } from "i18next";
import PropTypes from "prop-types";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router";
import config from "../../../../config";
import { BenefitModal } from "../../../../modals";
import { BENEFITS, RESTAURANTS, showVeganActive } from "../../../../resources/config";
import icons, { iconsAlts } from "../../../../resources/icons";
import images, { imagesAlts } from "../../../../resources/images";
import * as links from "../../../../utils/links";
import { PrimaryButton } from "../../../Common";
import classes from "./BusinessBenefits.module.css";

const BusinessBenefits = ({
  entity,
  entityType,
  benefits,
  businessIcon,
  className,
  isLoading,
  entityName,
}) => {
  const navigate = useNavigate();
  const navigateToVeganActive = () => {
    links.linkToExternalPage(links.URLS.vegan_active);
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedBenefit, setSelectedBenefit] = useState(null);

  const onBenefitClick = (benefit) => {
    if (!isModalOpen) {
      benefit.entity_type = entityType;
      if (entityType === RESTAURANTS) {
        benefit.restaurant = entity;
        // benefit.restaurant.logo = businessIcon;
      } else {
        benefit.branch = entity;
        // benefit.business.logo = businessIcon;
      }
      setSelectedBenefit(benefit);
      setModalOpen(true);
    }
  };

  const goToBenefitsHandler = () => {
    navigate(links.linkToSearch({ entityType: BENEFITS }));
  };

  return !isLoading ? (
    entity.has_discount && benefits && benefits.length > 0 ? (
      <div className={ `${classes.mainContainer} ${className}` } id='benefit_container'>
        <div className={ classes.divider }></div>
        <div className={ classes.rowSpaced }>
          <div className={ classes.row }>
            <h4 className={ classes.mainHeader }>{ t("benefit") }</h4>
            <img className={ classes.giftIcon } src={ icons.benefitGift } alt={ iconsAlts.benefitGift } />
          </div>
          { showVeganActive && (
            <button type="button" className={ classes.joinButton } onClick={ navigateToVeganActive }>
              <h6 className={ classes.joinText }>{ t("join_vegan_active") }</h6>
              <img
                className={ classes.joinIcon }
                src={ images.logoVeganActiveSmall }
                alt={ iconsAlts.logoVeganActiveSmall }
              />
            </button>
          ) }
        </div>
        {/* <div className={classes.row}>
          <img className={classes.informationIcon} src={icons.informationGrey} />
          <p className={classes.subHeader}>{t("click_redeem")}</p>
        </div> */}
        { benefits.map((benefit) => (
          <div className={ classes.benefitContainer } onClick={ () => onBenefitClick(benefit) }>
            <img className={ classes.benefitImage } src={ businessIcon } alt={ iconsAlts.businessIcon } />
            <div className={ classes.benefitContent }>
              <p className={ classes.benefitName }>{ benefit.description }</p>
              <p className={ classes.delivery }>{ t(benefit.discount_type) }</p>
              <p className={ classes.information }>{ t("benefit_information") }</p>
            </div>
          </div>
        )) }
        <BenefitModal
          show={ isModalOpen }
          onHide={ () => setModalOpen(false) }
          benefit={ selectedBenefit }
        />
      </div>
    ) : config.currentCountry === "GB" ? (
      <div className={ classes.menuPlaceholderContainer }>
        <div className={ classes.divider } />
        <img
          className={ classes.placeholderImage }
          src={ images.benefitPlaceholder }
          alt={ imagesAlts.benefitPlaceholder }
        />
        <p className={ classes.placeholderText }>
          { entityName }
          { t("benefit_placeholder_title") }
        </p>
        <PrimaryButton
          title={ t("benefit_placeholder_subtitle") }
          isSlim={ true }
          onClick={ goToBenefitsHandler }
        />
      </div>
    ) : (
      <></>
    )
  ) : (
    <></>
  );
};

BusinessBenefits.propTypes = {
  benefits: PropTypes.array,
};

export default BusinessBenefits;
