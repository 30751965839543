import { t } from "i18next";
import moment from "moment-timezone";

const utcHour = moment().tz("Europe/London").hour();

/* Gets an entity and returns if entity is open now (true/false) */
export function checkIfEntityIsOpen(item) {
  let itemRanges, currentRange;
  if (item.schedule) {
    if (item.schedule.temporarily_closed) return false;
    if (item.schedule.always_open) return true;

    itemRanges = item.schedule ? item.schedule.daily_opening : [];

    if (itemRanges) {
      currentRange = getCurrentRange(itemRanges, item.timezone_offset);

      const openClosed = parseOpenClosed(itemRanges, currentRange, item.timezone_offset, item);

      const isOpen = openClosed === "open";
      return isOpen;
    }
  }

  return null;
}

function getCurrentRange(ranges, timezoneOffset) {
  const t = new Date(),
    dateStr = t.getFullYear() + "/" + (t.getMonth() + 1) + "/" + t.getUTCDate(),
    dow = t.getDay() + 1,
    tm =
      ((t.getUTCHours() + 24 + timezoneOffset) % 24) +
      ":" +
      (t.getMinutes() < 10 ? "0" : "") +
      t.getMinutes() +
      ":00";

  let dh,
    rts,
    rte,
    am = false;

  dh = Date.parse(dateStr + " " + tm);

  for (let r of ranges) {
    rts = Date.parse(dateStr + " " + r[2]);

    rte = Date.parse(dateStr + " " + r[3]);

    if (
      (r[0] + 1 <= dow && r[1] + 1 >= dow) ||
      (r[0] > r[1] &&
        ((dow + 1 >= r[0] && dow <= r[1] + 8) || (dow <= r[0] + 1 && dow <= r[1] + 1)))
    ) {
      if (r[4] == true) {
        return r;
      }

      if (rts >= rte) {
        // closing after midnight,
        am = true;
        let tmw = new Date();
        tmw = tmw.setDate(tmw.getUTCDate());
        rte = new Date(rte);
        rte = rte.setDate(rte.getUTCDate());

        if (tmw <= rte || dh >= rts) {
          return r;
        }
      } else if (dh >= rts && dh <= rte) {
        return r;
      }
    }

    // see if closing the day before is still in today's range (if closing after midnight)
    if (r[1] === dow - 1 || (r[1] === 7 && dow === 1)) {
      if (rts >= rte && dh <= rte) {
        return r;
      }
    }
  }
}

function parseOpenClosed(ranges, range, timezoneOffset, item) {
  if (!range && ranges.length === 0) return "na";
  let isClosingSoon = false;

  if (range) {
    const minsToClose = getMinutesToClosure(range, timezoneOffset, item);
    if (minsToClose < 30) {
      // Closing in half an hour!
      isClosingSoon = true;
    }
  }

  if (isClosingSoon) return "open";

  return range ? "open" : "closed";
}

function getMinutesToClosure(range, timezoneOffset, item) {
  const t = new Date();
  const tm =
    t.getUTCHours() +
    timezoneOffset +
    ":" +
    (t.getMinutes() < 10 ? "0" : "") +
    t.getMinutes() +
    ":00";
  let dh, rte;
  rte = Date.parse("January 1, 2019 " + range[3]);
  dh = Date.parse("January 1, 2019 " + tm);
  const minutes = (rte - dh) / 1000 / 60;

  return minutes;
}

export function timeSince(date) {
  if (typeof date !== "object") {
    date = new Date(date);
  }

  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    return interval > 1 ? interval + " " + t("years") : t("year");
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      return interval > 1 ? interval + " " + t("months") : t("month");
    } else {
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        return interval > 1 ? interval + " " + t("days") : t("day");
      } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
          return interval > 1 ? interval + " " + t("hours") : t("hour");
        } else {
          interval = Math.floor(seconds / 60);
          if (interval >= 1) {
            return interval > 1 ? interval + " " + t("minutes") : t("minute");
          } else {
            interval = seconds;
            return interval > 1 ? interval + " " + t("seconds") : t("second");
          }
        }
      }
    }
  }
}
