import classes from "./BusinessBanner.module.css";
import images, { imagesAlts } from "../../../resources/images";
import React from "react";
import { NewsModal } from "../../../modals";
import config from "../../../config";
import * as links from "../../../utils/links";
import { logEvents } from "../../../eventsManager";
import { t } from "i18next";

const BusinessBanner = ({ isGreen }) => {
  const [isNewsModalOpen, setNewsModalOpen] = React.useState(false);

  const onBannerClick = () => {
    logEvents("ad_click", {
      banner_name: "get_certified",
      banner_location: isGreen ? "footer" : "home",
    });

    if (config.currentCountry === "US") {
      if (!isNewsModalOpen) {
        setNewsModalOpen(true);
      }
    } else {
      links.linkToExternalPage(links.URLS.get_certified);
    }
  };

  const onCertifiedClick = () => {
    if (config.currentCountry === "US") {
      if (!isNewsModalOpen) {
        setNewsModalOpen(true);
      }
    } else {
      links.linkToExternalPage(links.URLS.get_certified);
    }
  };

  return (
    <button
      type="button"
      className={isGreen ? classes.mainContainerGreen : classes.mainContainer}
      onClick={onBannerClick}
    >
      <img
        className={isGreen ? classes.iconGreen : classes.icon}
        src={images.bannerSuitcase}
        alt={imagesAlts.bannerSuitcase}
      />
      <div className={isGreen ? classes.contentContainerGreen : classes.contentContainer}>
        <div className={classes.row}>
          <h4 className={isGreen ? classes.titleGreen : classes.title}>
            {t("business_banner_title")}
          </h4>
        </div>
        {isGreen ? (
          <h4 className={classes.subtitleGreen}>{t("business_banner_subtitle")}</h4>
        ) : (
          <h4 className={classes.subtitle}>{t("business_banner_subtitle")}</h4>
        )}
      </div>
      <NewsModal show={isNewsModalOpen} onHide={() => setNewsModalOpen(false)} newsId={1} />
    </button>
  );
};

export default BusinessBanner;
