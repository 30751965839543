import * as entitiesUtils from "./entitiesUtils";
import * as scheduleUtils from "./scheduleUtils";
import { s3 } from "../../resources/config";
import { cleanSearchNames } from "../../components/HomePage/HomePageSearch/searchResultsPreparation";

/* Preparing business entity - adding full categories tags and add missing fields */
export function prepareCompany(company, companyCategories) {
  const preparedCompany = JSON.parse(JSON.stringify(company));
  preparedCompany.logo = company.logo ? s3 + company.logo : "";

  preparedCompany.subCategories = entitiesUtils.prepareFullCategories(
    company.categories ? company.categories : company.prod_business_sub,
    companyCategories
  );

  preparedCompany.search_names = [...cleanSearchNames(company.search_names), ...preparedCompany.subCategories.map(r => cleanSearchNames(r.search_names)).flat()];


  let sortedProducts = []
  if (company.products?.length > 0) {
      sortedProducts = companyCategories.reduce((acc, category) => {
          const filteredProducts = company.products.filter(product => product.prod_business_sub.includes(category.id));
          if (filteredProducts.length > 0) {
              acc.push({
                  name: companyCategories.find(c => c.id == category.id)?.category_name,
                  products: filteredProducts
              });
          }
          return acc;
      }, []);
  }

  preparedCompany.catalog = sortedProducts;

  return preparedCompany;
}
