import app_component_phone from "../assets/images/app_component_phone.png";
import logo_vegan_active_small from "../assets/images/logo_vegan_active_small.png";
import vegan_logo_small from "../assets/images/vegan-logo-small.webp";
import marker from "../assets/images/marker.webp";
import marker_big from "../assets/images/marker_big.webp";
import marker_me from "../assets/images/marker_me.svg";
import footer_cat from "../assets/images/footer_cat.svg";
import recipe_chef from "../assets/images/recipe_chef.svg";
import download_app_store from "../assets/images/download_app_store.svg";
import download_google_play from "../assets/images/download_google_play.svg";
import newsletter from "../assets/images/newsletter.svg";
import page404 from "../assets/images/404_page.svg";
import error500 from "../assets/images/500_error.svg";
import no_results from "../assets/images/no_results.svg";
import tivoniTaim from "../assets/images/tivoni_taim.svg";
import camera from "../assets/images/camera.svg";
import camera_small from "../assets/images/camera_small.svg";
import user_thumbnail from "../assets/images/user_thumbnail.svg";
import cow_with_computer from "../assets/images/cow_with_computer.svg";
import cow_with_phone from "../assets/images/cow_with_phone.svg";
import benefit_mark from "../assets/images/benefit_mark.svg";
import contact_us_girl from "../assets/images/contact_us_girl.svg";
import map_button from "../assets/images/map_button.svg";
import previousButton from "../assets/images/previousButton.svg";
import nextButton from "../assets/images/nextButton.svg";

import company1 from "../assets/images/companies/company1.svg";
import company2 from "../assets/images/companies/company2.svg";
import company3 from "../assets/images/companies/company3.svg";
import company4 from "../assets/images/companies/company4.svg";
import company5 from "../assets/images/companies/company5.svg";
import company6 from "../assets/images/companies/company6.svg";
import company7 from "../assets/images/companies/company7.svg";
import company8 from "../assets/images/companies/company8.svg";
import company9 from "../assets/images/companies/company9.svg";
import company10 from "../assets/images/companies/company10.svg";
import company11 from "../assets/images/companies/company11.svg";
import company12 from "../assets/images/companies/company12.svg";
import company13 from "../assets/images/companies/company13.svg";
import company14 from "../assets/images/companies/company14.svg";
import company15 from "../assets/images/companies/company15.svg";
import company16 from "../assets/images/companies/company16.svg";
import company17 from "../assets/images/companies/company17.svg";
import company18 from "../assets/images/companies/company18.svg";
import company19 from "../assets/images/companies/company19.svg";
import company20 from "../assets/images/companies/company20.svg";
import company21 from "../assets/images/companies/company21.svg";
import company22 from "../assets/images/companies/company22.svg";
import company23 from "../assets/images/companies/company23.svg";
import company24 from "../assets/images/companies/company24.svg";
import company25 from "../assets/images/companies/company25.svg";
import company26 from "../assets/images/companies/company26.svg";
import company27 from "../assets/images/companies/company27.svg";
import company28 from "../assets/images/companies/company28.svg";

import avatar1 from "../assets/images/avatars/avatar_1.webp";
import avatar2 from "../assets/images/avatars/avatar_2.webp";
import avatar3 from "../assets/images/avatars/avatar_3.webp";
import avatar4 from "../assets/images/avatars/avatar_4.webp";
import avatar5 from "../assets/images/avatars/avatar_5.webp";
import avatar6 from "../assets/images/avatars/avatar_6.webp";
import avatar7 from "../assets/images/avatars/avatar_7.webp";
import avatar8 from "../assets/images/avatars/avatar_8.webp";
import avatar9 from "../assets/images/avatars/avatar_9.webp";

import banner_suitcase from "../assets/images/banner/banner_suitcase.svg";
import banner_benefit from "../assets/images/banner/banner_benefit.png";
import banner_about from "../assets/images/banner/banner_about.png";
import vf_logo from "../assets/images/banner/vf_logo.svg";
import default_restaurant_cover from "../assets/images/default_restaurant_cover.png";
import default_entity_logo from "../assets/images/default_entity_logo.png";
import reviews_empty_placeholder from "../assets/images/reviews_empty_placeholder.png";
import vegan_friendly_white from "../assets/images/vegan_friendly_white.svg";
import news_cover from "../assets/images/news_cover.png";
import menu_placeholder from "../assets/images/menu_placeholder.png";
import benefit_placeholder from "../assets/images/benefit_placeholder.png";

import about_green_arrow from "../assets/images/about_us/about_green_arrow.svg";

import about_label_restaurants_il from "../assets/images/about_us/about_label_restaurants_il.png"
import about_label_product_il from "../assets/images/about_us/about_label_product_il.png"

import about_label_restaurants_us from "../assets/images/about_us/about_label_restaurants_us.png";
import about_label_businesses_us from "../assets/images/about_us/about_label_businesses_us.webp";
import about_label_product_us from "../assets/images/about_us/about_label_product_us.webp";

import about_label_restaurants_uk from "../assets/images/about_us/about_label_restaurants_uk.png";
import about_label_businesses_uk from "../assets/images/about_us/about_label_businesses_uk.webp";
import about_label_product_uk from "../assets/images/about_us/about_label_product_uk.png";

export default {
  bannerSuitcase: banner_suitcase,
  bannerBenefit: banner_benefit,
  bannerAbout: banner_about,
  vfLogo: vf_logo,
  appComponentPhone: app_component_phone,
  logoVeganActiveSmall: logo_vegan_active_small,
  veganLogoSmall: vegan_logo_small,
  mapMarker: marker,
  mapMarkerBig: marker_big,
  mapMarkerMe: marker_me,
  footerCat: footer_cat,
  recipeChef: recipe_chef,
  downloadAppStore: download_app_store,
  downloadGooglePlay: download_google_play,
  newsletter: newsletter,
  tivoniTaim: tivoniTaim,
  galleryPreviousButton: previousButton,
  galleryNextButton: nextButton,
  aboutLabelProductUS: about_label_product_us,
  aboutLabelRestaurantUS: about_label_restaurants_us,
  aboutLabelRestaurantIL: about_label_restaurants_il,
  aboutLabelProductIL: about_label_product_il,
  aboutLabelBusinessesUS: about_label_businesses_us,
  aboutLabelProductUK: about_label_product_uk,
  aboutLabelRestaurantUK: about_label_restaurants_uk,
  aboutLabelBusinessesUK: about_label_businesses_uk,
  aboutGreenArrow: about_green_arrow,
  mapButton: map_button,
  contactUsGirl: contact_us_girl,
  benefitMark: benefit_mark,
  noResults: no_results,
  userThumbnail: user_thumbnail,
  cowWithComputer: cow_with_computer,
  cowWithPhone: cow_with_phone,
  camera: camera,
  cameraSmall: camera_small,
  newsCover: news_cover,
  page404: page404,
  error500: error500,
  company1: company1,
  company2: company2,
  company3: company3,
  company4: company4,
  company5: company5,
  company6: company6,
  company7: company7,
  company8: company8,
  company9: company9,
  company10: company10,
  company11: company11,
  company12: company12,
  company13: company13,
  company14: company14,
  company15: company15,
  company16: company16,
  company17: company17,
  company18: company18,
  company19: company19,
  company20: company20,
  company21: company21,
  company22: company22,
  company23: company23,
  company24: company24,
  company25: company25,
  company26: company26,
  company27: company27,
  company28: company28,
  avatar1: avatar1,
  avatar2: avatar2,
  avatar3: avatar3,
  avatar4: avatar4,
  avatar5: avatar5,
  avatar6: avatar6,
  avatar7: avatar7,
  avatar8: avatar8,
  avatar9: avatar9,
  defaultRestaurantCover: default_restaurant_cover,
  defaultEntityLogo: default_entity_logo,
  reviewsEmptyPlaceholder: reviews_empty_placeholder,
  veganFriendlyWhite: vegan_friendly_white,
  menuPlaceholder: menu_placeholder,
  benefitPlaceholder: benefit_placeholder,
};

export const imagesAlts = {
  bannerSuitcase: "Small orange suitcase",
  vf_logo: "Small vegan friendly logo",
  appComponentPhone:
    "a phone presenting the Vegan Friendly application and restaurant benefits that are redeemable through it",
  logoVeganActiveSmall: "small Vegan Active logo",
  veganLogoSmall: "small vegan friendly logo",
  mapMarker:
    'small heart shaped radish (vegan friendly logo without the "Vegan friendly" written inside the radish)',
  mapMarkerBig: "small vegan friendly logo",
  mapMarkerMe: "green location dot that marks your location",
  footerCat: "cute cat peeking behing a computer screen",
  recipeChef:
    "cute smiling chef with a chef hat cooking something on a pot with kitchen instruments around him",
  downloadAppStore: 'black button that says "download on the app store" with an apple logo',
  downloadGooglePlay: 'black button that says "get it on google play" with the google play logo',
  newsletter: "a small baby chick winking placed inside an open envelope",
  tivoniTaim: 'logo of the "tivoni taim" group inside an animated hamburger',
  galleryPreviousButton: "grey arrow pointing to the left",
  galleryNextButton: "grey arrow pointing to the right",
  aboutLabelProduct: "mock minced meat product with the vegan friendly logo on the package",
  aboutLabelRestaurant: "A restaurant front window with the vegan friendly sticker on it",
  aboutLabelBusinesses: "white sneaker with a vegan friendly tag attached to it",
  aboutGreenArrow: "small green arrow pointing to the right",
  mapButton: 'a map icon that says "map"',
  contactUsGirl:
    "a smiling telemarketer wearing headphones, working on her laptop with a coffee mug and a plant on her side",
  benefitMark: "pink badge",
  noResults: "small yellow chick peeking out of an egg",
  userThumbnail: "small animated icon of a person",
  cowWithComputer: "hamster with a sunflower seed",
  cowWithPhone: "cow with a cellphone",
  camera: "camera icon",
  cameraSmall: "small camera icon",
  page404: "the number 404 in stylized graphics, where the zero is replaced by a surprised cow",
  error500: "yellow triangle with ! inside",
  company1: "company1",
  company2: "company2",
  company3: "company3",
  company4: "company4",
  company5: "company5",
  company6: "company6",
  company7: "company7",
  company8: "company8",
  company9: "company9",
  company10: "company10",
  company11: "company11",
  company12: "company12",
  company13: "company13",
  company14: "company14",
  company15: "company15",
  company16: "company16",
  company17: "company17",
  company18: "company18",
  company19: "company19",
  company20: "company20",
  company21: "company21",
  company22: "company22",
  company23: "company23",
  company24: "company24",
  company25: "company25",
  company26: "company26",
  company27: "company27",
  company28: "company28",
  avatar1: "radish making a kiss face",
  avatar2: "cute broccoli",
  avatar3: "smiling red chili pepper",
  avatar4: "cute carrot",
  avatar5: "cute tomato",
  avatar6: "cute eggplant",
  avatar7: "smiling pickle",
  avatar8: "cute pear",
  avatar9: "smiling orange",
  defaultRestaurantCover: "default restaurant cover",
  defaultEntityLogo: "Default entity logo - vegan friendly logo",
  reviewsEmptyPlaceholder: "Empty reviews list place holder",
  veganFriendlyWhite: "Vegan friendly logo in white",
  menuPlaceholder: "Tomato image",
  benefitPlaceholder: "Gift image",
};
