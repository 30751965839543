import { t } from "i18next";

import classes from "./EntityRating.module.css";
import { reviewUtils } from "../../../utils/entityUtils";

const EntityRating = ({ rating, reviewNum, className }) => {
  return (

    <div className={`${classes.mainContainer} ${className}`}>
      <img src={reviewUtils.getIconByRating(rating)} alt={"review icon"} />
      <p className={classes.rating}>{rating.replace(/\.0+$/, "")}/5</p>
      {reviewNum > 0 && (

        <p className={classes.reviews}>
          ({reviewNum} {t("reviews")})
        </p>
      )}
    </div>
  );
};

export default EntityRating;
