import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useParams, useNavigate } from "react-router-dom";
import * as entitiesActions from "../../actions/entitiesActions";
import ReactMarkdown from "react-markdown";
import { logEvents } from "../../eventsManager";
import classes from "./Blog.module.css";
import Skeleton from "react-loading-skeleton";
import { EntityHeader, EntityReviews } from "../../components/EntityPages";
import { blogUtils } from "../../utils/entityUtils";
import { t } from "i18next";


const BLOGS = "blogs";

const Blog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const blog = useSelector((state) => state.blog.fullBlog);
  const entityCategories = useSelector((state) => state.entities.entityCategories);

  const [preparedBlog, setPreparedBlog] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [blogEventProps, setBlogEventProps] = useState({});
  const { blogSlug } = useParams();


  useEffect(() => {
    entitiesActions.getFullBlog(dispatch, blogSlug).catch(() => {
      navigate("/not-found"); 
    });
    
    window.scrollTo(0, 0);

  }, []);

  const getBlogEventsProps = (blog) => {
    return {
      blog_id: blog.id,
      blog_name: blog.title,
      slug: blog.slug
    };
  };

  useEffect(() => {
    if (blog && entityCategories &&
      entityCategories.blogs) {

      if (blog.slug && blog.slug == blogSlug) {
        const prepared = blogUtils.prepareBlog(blog, entityCategories.blogs);
        setPreparedBlog(prepared);
        const props = getBlogEventsProps(prepared);
        logEvents("screen_blog_open", props);
        setBlogEventProps(props);
        setIsLoading(false);
      }
    }
  }, [blog, entityCategories]);

  const componentToPrint = useRef();

  function formatLineBreaks(inputText) {
    // Replace multiple consecutive line breaks with a single line break and &nbsp;
    const formattedText = inputText.replace(/(\n\s*){2,}/g, '\n&nbsp;\n');
    return formattedText;
  }

  const reactToPrintContent = useCallback(() => {
    return componentToPrint.current;
  }, [componentToPrint.current]);

  const blogContent = preparedBlog && preparedBlog.content ? formatLineBreaks(preparedBlog.content) : '';

  return (
      <div className={classes.mainContainer} ref={componentToPrint}>
        <EntityHeader
          entityType={BLOGS}
          className={classes.headerContainer}
          title={preparedBlog.title}
          thumbnail={preparedBlog.logo ? preparedBlog.logo : ""}
          categories={preparedBlog.blog_categories}
          author={{ name: preparedBlog.author ?? t("vegan_friendly"), link: preparedBlog.author_url, date: preparedBlog.article_date }}
          isLoading={isLoading}
          coverImages={[]}
          componentToPrint={reactToPrintContent}
          eventProps={blogEventProps}
        />
        {!isLoading && <h2 className={classes.description}>{preparedBlog.description}</h2>}

        <div className={classes.markdownContainer}>
          <ReactMarkdown className={classes.content}>{blogContent}</ReactMarkdown>
        </div>
      </div>)
};

export default Blog;
