
import classes from "./BenefitBanner.module.css";
import images, { imagesAlts } from "../../../resources/images";
import React from "react";
import { NewsModal } from "../../../modals";
import config from "../../../config";
import * as links from "../../../utils/links";
import { logEvents } from "../../../eventsManager";
import { t } from "i18next";

const BenefitBanner = ({ isGreen }) => {
  const onBannerClick = () => {
    links.linkToExternalPage(links.URLS.vegan_active);

    logEvents("ad_click", {
      banner_name: "join_vegan_active",
      banner_location: isGreen ? "footer" : "home",
    });
  };


  return (
    <button type="button" className={classes.mainContainer} onClick={onBannerClick}>
      <img className={classes.icon} src={images.bannerBenefit} alt={imagesAlts.bannerSuitcase} />
      <div className={classes.contentContainer}>
        <div className={classes.row}>
          <h4 className={classes.title}>{t("benefit_banner_title")}</h4>
        </div>
        {isGreen ? (
          <h4 className={classes.subtitleGreen}>{t("benefit_banner_subtitle")}</h4>
        ) : (
          <h4 className={classes.subtitle}>{t("benefit_banner_subtitle")}</h4>
        )}
      </div>

    </button>
  );
};


export default BenefitBanner;