import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import config from "../../../config";
import icons, { iconsAlts } from "../../../resources/icons";
import images, { imagesAlts } from "../../../resources/images";
import classes from "./ProductCatalog.module.css";
import ProductItem from "./ProductItem"

const ProductCatalog = ({ className, catalog, entityName }) => {
  const [isMenuExpanded, setMenuExpanded] = useState(false);
  const [isMenuExpandable, setMenuExpandable] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    try {
      const currentHeight = ref.current.clientHeight;
      const minHeight = convertRemToPixels(40);
      setMenuExpandable(currentHeight >= minHeight);
    } catch { }
  });

  function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

  return catalog && catalog.length > 0 ? (
    <div className={ `${classes.mainContainer} ${className}` } id="menu_container">
      <div className={ classes.divider }></div>
      <h3 className={ classes.mainHeader }>{ t("products") }</h3>
      <div
        id="containerDiv"
        ref={ ref }
        className={
          isMenuExpanded
            ? classes.menuContainerExpanded
            : catalog && catalog.length > 2
              ? classes.menuDoubleColumn
              : classes.menuGrid
        }
      >
        { catalog &&
          catalog.map((category) => (
            <div className={ classes.categoryContainer }>
              <h4 className={ classes.categoryHeader }>{ category.name }</h4>
              { category.products.map((product) => (
                <ProductItem product={product}/>
              )) }
            </div>
          )) }
        <div className={ classes.bottomShade } />
      </div>

      { isMenuExpandable && (
        <button
          type="button"
          className={ classes.expandButton }
          onClick={ () => {
            setMenuExpanded((expanded) => !expanded);
            const myDiv = document.getElementById("containerDiv");
            myDiv.scrollTop = 0;
          } }
        >
          <p>{ isMenuExpanded ? t("close_product_menu") : t("expand_product_menu") }</p>
          <img
            className={ isMenuExpanded ? classes.expandButtonIconExpanded : classes.expandButtonIcon }
            src={ icons.greyArrowDown }
            alt={ iconsAlts.greyArrowDown }
          />
        </button>
      ) }
    </div>
  ) : (
    <></>
  );
};

export default ProductCatalog;
