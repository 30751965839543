import config from "../../config";

const US_REGIONS = [
  "al",
  "ak",
  "az",
  "ar",
  "ca",
  "co",
  "ct",
  "de",
  "fl",
  "ga",
  "hi",
  "id",
  "il",
  "in",
  "ia",
  "ks",
  "ky",
  "la",
  "me",
  "md",
  "ma",
  "mi",
  "mn",
  "ms",
  "mo",
  "mt",
  "ne",
  "nv",
  "nh",
  "nj",
  "nm",
  "ny",
  "nc",
  "nd",
  "oh",
  "ok",
  "or",
  "pa",
  "ri",
  "sc",
  "sd",
  "tn",
  "tx",
  "ut",
  "vt",
  "va",
  "wa",
  "wv",
  "wi",
  "wy",
];
const GB_REGIONS = [];
const IL_REGIONS = ["center", "north", "south"];

const getRegionsForCurrentCountry = () => {
  switch (config.currentCountry) {
    case "IL":
      return IL_REGIONS;
    case "GB":
      return GB_REGIONS;
    case "US":
      return US_REGIONS;
  }
};

export const REGIONS = getRegionsForCurrentCountry();
