import classes from "./RecipeDetails.module.css";
import icons, { iconsAlts } from "../../../../resources/icons";
import { t } from "i18next";
import Skeleton from "react-loading-skeleton";

const RecipeDetails = ({
  difficulty,
  preparationTime,
  serving,
  showDividers = false,
  className,
  isLoading,
  isSmall = true,
  about,
}) => {
  const getDifficultyText = (difficult) => {
    switch (difficult) {
      case 1:
        return t("easy");
      case 2:
        return t("medium");
      case 3:
        return t("hard");
    }
  };

  const calcDuration = (duration) => {
    if (duration > 60) {
      const quotient = Math.floor(duration / 60);
      const remainder = duration % 60;
      return `${quotient}.${remainder} ${t("hours")}`;
    } else {
      return `${duration} ${t("min")}`;
    }
  };

  return (
    <div className={`${classes.mainContainer} ${className}`}>
      {isLoading ? (
        <div className={classes.detailsContainer}>
          <Skeleton
            className={classes.singleDetailsContainer}
            width="8rem"
            height="100%"
            borderRadius="10px"
            style={{ marginInlineEnd: "1rem" }}
          />
          <Skeleton
            className={classes.singleDetailsContainer}
            width="8rem"
            height="100%"
            borderRadius="10px"
            style={{ marginInlineEnd: "1rem" }}
          />
          <Skeleton
            className={classes.singleDetailsContainer}
            width="8rem"
            height="100%"
            borderRadius="10px"
            style={{ marginInlineEnd: "1rem" }}
          />
        </div>
      ) : (
        <div className={classes.detailsContainer}>
          {difficulty && (
            <div className={classes.singleDetailsContainer}>
              <img
                className={isSmall ? classes.iconSmall : classes.icon}
                src={icons.recipePreparation}
                alt={iconsAlts.recipePreparation}
              />
              <p className={isSmall ? classes.detailsNameSmall : classes.detailsName}>
                {getDifficultyText(difficulty)}
              </p>
            </div>
          )}
          {showDividers && preparationTime > 0 && <p className={classes.dot}>·</p>}
          {preparationTime > 0 && (
            <div className={classes.singleDetailsContainer}>
              <img
                className={classes.icon}
                src={icons.recipeDuration}
                alt={iconsAlts.recipeDuration}
              />
              <p className={isSmall ? classes.detailsNameSmall : classes.detailsName}>
                {calcDuration(preparationTime)}
              </p>
            </div>
          )}
          {showDividers && serving && <p className={classes.dot}>·</p>}
          {serving && (
            <div className={classes.singleDetailsContainer}>
              <img
                className={classes.icon}
                src={icons.recipeServing}
                alt={iconsAlts.recipeServing}
              />
              <p className={isSmall ? classes.detailsNameSmall : classes.detailsName}>
                {serving} {t("servings")}
              </p>
            </div>
          )}
        </div>
      )}
      <p className={classes.about}>{about}</p>
      {showDividers && <div className={classes.divider}></div>}
    </div>
  );
};

export default RecipeDetails;
