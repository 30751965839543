import classes from "./NewsItem.module.css";
import { useNavigate } from "react-router-dom";
import * as navigationLinks from "../../../utils/links";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { s3 } from "../../../resources/config";
import { useState } from "react";
import { NewsModal } from "../../../modals";
import { t } from "i18next";
import images from "../../../resources/images";

const NewsItem = ({ news, style, isLoading = true }) => {
  const [isNewsModalOpen, setNewsModalOpen] = useState(false);

  const onNewsClick = () => {
    if (!isNewsModalOpen) {
      setNewsModalOpen(true);
    }
  };

  return (
    <div className={`${style} ${classes.mainContainer} `} onClick={onNewsClick}>
      <div className={classes.imageContainer}>
        {isLoading ? (
          <Skeleton
            height="100%"
            style={{
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
            }}
          />
        ) : (
          <img
            className={classes.image}
            src={news.logo ? s3 + news.logo : images.newsCover}
            alt={"news image"}
          />
        )}
      </div>
      <div className={classes.contentContainer}>
        <p className={classes.newsTitle}>
          {isLoading ? <Skeleton borderRadius="20px" width="80%" /> : news.title}
        </p>
        <p className={classes.newsDescription}>
          {isLoading ? <Skeleton borderRadius="20px" width="80%" /> : news.subtitle ?? ""}
        </p>

        {news && news.categories && news.categories.length > 0 && (
          <p className={classes.tags}>
            #
            {news.categories
              .map((c) => t(c.type))
              .join(" #")
              .toLowerCase()}
          </p>
        )}
        <NewsModal show={isNewsModalOpen} onHide={() => setNewsModalOpen(false)} newsId={news.id} />
      </div>
    </div>
  );
};

export default NewsItem;
