import {
  RESTAURANTS,
  RECIPES,
  BRANCHES,
  BENEFITS,
  VF_ENTITIES_TYPE,
  VF_COLORS,
  BRANDS,
  BLOGS,
  EMPLOYERS
} from "../../../resources/config";

import { BENEFITS_TAGS } from "../../../resources/entities/entityTags";
import classes from "./EntityButtons.module.css";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { linkToSearch } from "../../../utils/links";
import GreenTag from "../../Common/Tags/GreenTag";
import { isBrowser, isMobile } from "react-device-detect";
import { checkEntityByCountry } from "../../../utils/utilFunctions";
import { logEvents } from "../../../eventsManager";

const EntityButtons = ({ entityCategories }) => {
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [displayedCategories, setDisplayedCategories] = useState([]);
  const [displayedTags, setDisplayedTags] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      entityCategories &&
      selectedEntity &&
      displayedCategories &&
      displayedCategories.length === 0
    ) {
      updateDisplayedCategories(RESTAURANTS);
    }
  }, [entityCategories]);

  const entitySelectHandler = (name) => {
    if (selectedEntity != name) {
      logEvents("entity_selector_click", { entity: name });
      setSelectedEntity(name);
      updateDisplayedCategories(name);
    } else {
      setSelectedEntity(null);
      updateDisplayedCategories([]);
    }
  };

  const updateDisplayedCategories = (type) => {
    switch (type) {
      case BRANCHES:
        setDisplayedTags([]);
        setDisplayedCategories(entityCategories.businessesSubs.filter((c) => c.show));
        break;
      case RESTAURANTS:
        setDisplayedTags([]);
        setDisplayedCategories(entityCategories.restaurants.filter((c) => c.show));
        break;
      case RECIPES:
        setDisplayedTags([]);
        setDisplayedCategories(entityCategories.recipes);
        break;
      case BENEFITS:
        // setDisplayedTags(BENEFITS_TAGS);
        setDisplayedTags([]);
        setDisplayedCategories(entityCategories.benefits.filter((c) => c.show));
        break;
      case BRANDS:
        setDisplayedTags([]);
        setDisplayedCategories(entityCategories.companiesSubs);
        break;
      case BLOGS:
          setDisplayedTags([]);
          setDisplayedCategories(entityCategories.blogs);
          break;
      case EMPLOYERS:
        setDisplayedTags([]);
        setDisplayedCategories(entityCategories.employers);
        break;
      default:
        setDisplayedCategories([]);
    }
  };

  const categorySelectHandler = (category) => {
    navigate(linkToSearch({ entityType: selectedEntity, categoryNames: [category.category_name], suggestOverride: true }));
  };

  const tagSelectHandler = (tag) => {
    navigate(linkToSearch({ entityType: selectedEntity, tagNames: [tag.name], suggestOverride: true }));
  };

  const allTagSelected = () => {
    navigate(linkToSearch({ entityType: selectedEntity, suggestOverride: true }));
  };

  const activeEntitiesTypes = () => {
    return VF_ENTITIES_TYPE.slice(1).filter((e) => checkEntityByCountry(e.name));
  };

  const nonActiveEntitiesTypes = () => {
    return VF_ENTITIES_TYPE.slice(1).filter((e) => !checkEntityByCountry(e.name));
  };

  const getTitleByType = () => {
    switch (selectedEntity) {
      case BRANCHES:
        return t("business_entity_title");
      case RESTAURANTS:
        return t("restaurant_entity_title");
      case RECIPES:
        return t("recipes_entity_title");
      case BENEFITS:
        return t("benefits_entity_title");
      case BRANDS:
        return t("products_entity_title");
      case BLOGS:
        return t("blogs_entity_title");
      case EMPLOYERS:
        return t("employers_entity_title");
    }
  };


  return (
    <div className={classes.mainContainer}>
      <div
      id='entities_buttons_container'
        className={
          isBrowser
            ? classes.entityButtonsContainer
            : `${classes.entityButtonsContainerMobile} ${"hideScrollBars"}`
        }
      >
        {activeEntitiesTypes().map((e) => (
          <button
            type="button"
            style={{
              backgroundColor: selectedEntity === e.name ? VF_COLORS.MAIN_GREEN : null,
            }}
            className={classes.entityButtonContainer}
            key={e.name}
            onClick={() => entitySelectHandler(e.name)}
          >
            <div
              className={classes.entityButtonInnerContainer}
              style={{
                cursor: "pointer",
              }}
            >
              <img
                className={
                  selectedEntity === e.name ? classes.entityIconSelected : classes.entityIcon
                }
                src={e.img}
                alt="entity icon"
              />
              <p
                className={
                  selectedEntity === e.name ? classes.entityNameSelected : classes.entityName
                }
              >
                {t(e.label)}
              </p>
            </div>
          </button>
        ))}
        {nonActiveEntitiesTypes().length > 0 && (
          <div className={classes.comingSoonContainer}>
            {nonActiveEntitiesTypes().map((e) => (
              <button
                type="button"
                style={{
                  opacity: 0.5,
                  backgroundColor: selectedEntity === e.name ? VF_COLORS.MAIN_GREEN : null,
                }}
                className={classes.entityButtonContainer}
                key={e.name}
              >
                <div
                  className={classes.entityButtonInnerContainer}
                  style={{
                    cursor: "default",
                  }}
                >
                  <img className={classes.entityIconGrey} src={e.img} alt="entity icon" />
                  <p className={classes.entityNameGrey}>{t(e.label)}</p>
                </div>
              </button>
            ))}
            <div className={classes.comingSoonTag}>
              <p className={classes.comingSoonText}>Coming soon!</p>
            </div>
          </div>
        )}
      </div>
      {(displayedCategories.length > 0 || displayedTags.length > 0) && (
        <div>
          {false && <h6 className={classes.categoriesTitle}>{getTitleByType()}</h6>/**Copy not good for now */}
          <div className={classes.categoriesContainer} id='entity_categories_container'>
            <GreenTag
              key={`all`}
              tag={"all"}
              tagName={t("all")}
              onTagSelected={() => allTagSelected()}
              style={{
                marginInline: 2,
                marginBlock: 3,
              }}
            />
            {displayedCategories.map((category, index) => (
              <GreenTag
                className={classes.tag}
                key={`${category.category_name}:${category.id}`}
                tag={category}
                tagName={category.category_name}
                onTagSelected={() => categorySelectHandler(category)}
                style={{
                  marginInline: 2,
                  marginBlock: 3,
                }}
              />
            ))}

            {displayedTags.map((tag, index) => (
              <GreenTag
                key={`${tag.name}`}
                tag={tag}
                tagName={t(tag.label)}
                onTagSelected={() => tagSelectHandler(tag)}
                style={{
                  marginInline: 2,
                  marginBlock: 3,
                }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default EntityButtons;
