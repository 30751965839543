import { getCurrentCountry } from "../../utils/utilFunctions";

export const getOurBusinessListByCountry = () => {
  switch (getCurrentCountry()) {
    case "US":
      return [ourBusinessItems.restaurants, ourBusinessItems.businesses, ourBusinessItems.products];
    case "IL":
      return [...Object.values(ourBusinessItems)];
    case "GB":
      return [...Object.values(ourBusinessItems)];
  }
};

export const getInvolvedListByCountry = () => {
  switch (getCurrentCountry()) {
    case "US":
      return [
        getInvolvedItems.donate,
        getInvolvedItems.about_us,
        getInvolvedItems.volunteer,
        getInvolvedItems.careers,
      ];
    case "IL":
      return [...Object.values(getInvolvedItems)];
    case "GB":
      return [
        getInvolvedItems.about_us,
        getInvolvedItems.volunteer,
        getInvolvedItems.careers,
        getInvolvedItems.newsletter_signup,
      ];
  }
};

export const getLegalListByCountry = () => {
  switch (getCurrentCountry()) {
    case "US":
      return [...Object.values(legalItems)];
    case "IL":
      return [...Object.values(legalItems), accessibility];
    case "GB":
      return [...Object.values(legalItems)];
  }
};

const ourBusinessItems = {
  getCertified: {
    label: "get_certified",
    action: "getCertified",
    isExternal: false,
    isLink: false,
  },
  restaurants: {
    label: "restaurants",
    action: "restaurants",
    isExternal: false,
    isLink: true,
  },
  businesses: {
    label: "branches",
    action: "branches",
    isExternal: false,
    isLink: true,
  },
  products: {
    label: "companies",
    action: "companies",
    isExternal: false,
    isLink: true,
  },
  benefits: {
    label: "benefits",
    action: "benefits",
    isExternal: false,
    isLink: true,
  },
};

const getInvolvedItems = {
  donate: {
    label: "donate",
    action: "donate",
    isExternal: true,
    isLink: true,
  },
  join_vegan_active: {
    label: "join_vegan_active",
    action: "joinVeganActive",
    isExternal: true,
    isLink: true,
  },
  about_us: {
    label: "about_us",
    action: "aboutUs",
    isExternal: false,
    isLink: true,
  },
  volunteer: {
    label: "volunteer",
    action: "volunteer",
    isExternal: false,
    isLink: false,
  },
  criteria: {
    label: "criteria_for_certification",
    action: "criteria-for-certification",
    isExternal: false,
    isLink: true,
  },
  careers: {
    label: "careers",
    action: "careers",
    isExternal: true,
    isLink: true,
  },
  newsletter_signup: {
    label: "join_our_newsletter",
    action: "newsletter",
    isExternal: false,
    isLink: false,
  },
};

const legalItems = {
  terms_of_service: {
    label: "terms_of_service",
    action: "termsOfService",
    isExternal: true,
    isLink: true,
  },
  privacy_policy: {
    label: "privacy_policy",
    action: "privacyPolicy",
    isExternal: true,
    isLink: true,
  }
};

const accessibility =  {
  label: "accessibility",
  action: "accessibility",
  isExternal: false,
  isLink: true,
}