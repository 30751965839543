import * as entitiesUtils from "./entitiesUtils";
import { s3 } from "../../resources/config";
import { cleanSearchNames } from "../../components/HomePage/HomePageSearch/searchResultsPreparation";

/* Preparing business entity - adding full categories tags and add missing fields */
export function prepareBlog(blog, blogCategories) {
  let preparedBlog = JSON.parse(JSON.stringify(blog));
  preparedBlog.logo = blog.logo ? s3 + blog.logo : "";
  preparedBlog.slug = blog.slug;

  preparedBlog.blog_categories = entitiesUtils.prepareFullCategories(
    blog.blog_categories,
    blogCategories
  );

  preparedBlog.search_names = [...cleanSearchNames(blog.search_names), ...preparedBlog.blog_categories.map(b => cleanSearchNames(b.search_names)).flat()];

  return preparedBlog;
}
