import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useParams, useNavigate } from "react-router-dom";
import * as recipeActions from "../../actions/recipeActions";
import { EntityHeader, EntityReviews, EntityWrongInfo } from "../../components/EntityPages";
import {
  RecipeDetails,
  RecipeIngredients,
  RecipeInstructions,
} from "../../components/EntityPages/Recipe";
import { logEvents } from "../../eventsManager";
import { RECIPES } from "../../resources/config";
import { recipeUtils, reviewUtils } from "../../utils/entityUtils";
import classes from "./Recipe.module.css";
import config from "../../config";

const Recipe = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { recipeId } = useParams();
  const recipe = useSelector((state) => state.recipe.fullRecipe);
  const entityCategories = useSelector((state) => state.entities.entityCategories);
  const reviewHashTags = useSelector((state) => state.entities.reviewHashTags);
  const pendingReviews = useSelector((state) => state.user.userPendingReviews);

  const [preparedRecipe, setPreparedRecipe] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [recipeEventProps, setRecipeEventProps] = useState({});

  useEffect(() => {
    recipeActions.getFullRecipe(dispatch, recipeId).catch(() => {
      navigate("/not-found"); 
    });

    window.scrollTo(0, 0);
  }, []);

  const getRecipeEventsProps = (preparedRecipe) => {
    return {
      business_id: preparedRecipe.id,
      business_type: RECIPES,
      business_name: preparedRecipe.name,
    };
  };

  useEffect(() => {
    if (
      recipe &&
      entityCategories &&
      entityCategories.recipes &&
      reviewHashTags &&
      reviewHashTags.recipeTags &&
      pendingReviews
    ) {
      if (recipe.id && recipe.id == recipeId) {
        const prepared = recipeUtils.prepareRecipe(recipe, entityCategories.recipes);
        if (prepared.reviews) {
          prepared.reviews.map((review) =>
            reviewUtils.prepareReview(review, reviewHashTags.recipeTags)
          );
        }
        prepared.userPendingReviews = pendingReviews.filter(
          (review) => review.entity_type == "recipe" && review.entity_id == recipeId
        );

        prepared.userPendingReviews.map((review) =>
          reviewUtils.prepareReview(review, reviewHashTags.recipeTags)
        );

        setPreparedRecipe(prepared);
        const props = getRecipeEventsProps(prepared);

        logEvents("screen_recipe_open", props);
        setRecipeEventProps(props);
        setIsLoading(false);
      }
    }
  }, [recipe, entityCategories, reviewHashTags, pendingReviews]);

  const componentToPrint = useRef();

  const reactToPrintContent = useCallback(() => {
    return componentToPrint.current;
  }, [componentToPrint.current]);

  return (
    <div className={classes.mainContainer} ref={componentToPrint}>
      <div>
        <EntityHeader
          entityType={RECIPES}
          title={preparedRecipe.name}
          rating={preparedRecipe.rating}
          numOfReviews={preparedRecipe.num_of_reviews}
          categories={preparedRecipe.recipe_categories}
          coverImages={preparedRecipe.pics?.length > 0 ? preparedRecipe.pics.map((p) => p.url) : [preparedRecipe.logo]}
          isLoading={isLoading}
          componentToPrint={reactToPrintContent}
        />
        <div className={classes.gridContainer}>
          <RecipeIngredients
            recipeName={preparedRecipe.name}
            className={classes.ingredientsContainer}
            ingredients={preparedRecipe.ingredient_sections}
            isLoading={isLoading}
          />
          <RecipeDetails
            className={classes.detailsContainer}
            difficulty={preparedRecipe.difficulty_level}
            preparationTime={preparedRecipe.preparation_time}
            serving={preparedRecipe.serving}
            showDividers={true}
            about={preparedRecipe.about}
            isLoading={isLoading}
          />
          <RecipeInstructions
            className={classes.instructionsContainer}
            instructions={preparedRecipe.preparation_sections}
            isTivoniTaim={preparedRecipe.is_tivoni_taim}
            credit={preparedRecipe.credit}
            creditLink={preparedRecipe.credit_link}
            isLoading={isLoading}
          />
          {!isLoading && (
            <EntityWrongInfo
              className={classes.wrongInfoContainer}
              entityName={preparedRecipe.name}
              showUpdated={config.currentCountry == "IL" ? false : true}
            />
          )}
          {!isLoading && (
            <EntityReviews
              className={classes.reviewsContainer}
              numOfReviews={preparedRecipe.num_of_reviews}
              rating={preparedRecipe.rating}
              reviews={preparedRecipe.reviews}
              pendingReviews={preparedRecipe.userPendingReviews}
              entityType={RECIPES}
              entityId={preparedRecipe.id}
              entityName={preparedRecipe.name}
              eventProps={recipeEventProps}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Recipe;
