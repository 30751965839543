import { t } from "i18next";
import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import { BRANCHES, RESTAURANTS } from "../../../resources/config";
import icons, { iconsAlts } from "../../../resources/icons";
import * as navigationLinks from "../../../utils/links";
import { getCurrentCountry } from "../../../utils/utilFunctions";
import EntityBenefitMark from "../EntityBenefitMark/EntityBenefitMark";
import EntityRating from "../EntityRating/EntityRating";
import GreyIconTags from "../IconTags/GreyIconTags/GreyIconTags";
import classes from "./BusinessesItem.module.css";

const BusinessesItem = ({ entity, type, style, isHovered, onEntityHover, isLoading = true }) => {
  const ref = React.useRef();
  const categories = type === RESTAURANTS ? entity.rest_categories : entity.business_subcats;

  // useEffect(() => {
  //   if (isHovered && ref && ref.current) {
  //     // ref.current.scrollIntoView({
  //     //   behavior: "smooth",
  //     //   block: "start",
  //     // });
  //   }
  // }, [isHovered]);

  const navigate = useNavigate();

  const getEntityClickHandler = () => {
    switch (type) {
      case RESTAURANTS:
        navigate(navigationLinks.linkToRestaurant(entity.id, entity.name, entity.location));
        break;
      case BRANCHES:
        navigate(
          navigationLinks.linkToBusiness(
            entity.branch_id,
            entity.name,
            entity.location,
            entity.is_online
          )
        );
        break;
    }
  };

  return (
    <div
      className={isHovered ? classes.itemContainerHover : classes.itemContainer}
      onClick={() => getEntityClickHandler(entity)}
      ref={ref}
      onMouseOver={() => onEntityHover(entity)}
      onMouseOut={() => onEntityHover(null)}
    >
      <div className={classes.logoContainer}>
        {isLoading && <Skeleton height="100%" borderRadius="20px" />}
        {!isLoading && <img className={classes.logo} src={entity.logo} alt="business logo" />}
      </div>

      <div className={classes.contentContainer}>
        <div className={classes.topContainer}>
          <div className={classes.nameContainer}>
            <p className={classes.restName}>
              {isLoading ? <Skeleton height="60%" borderRadius="20px" width="70%" /> : entity.name}
            </p>
            {entity.reviews != null && entity.rating > 0 && (
              <EntityRating
                className={classes.rating}
                rating={entity.rating}
                reviewNum={entity.reviews}
              />
            )}
            {!isLoading && entity.distance != null && (
              <div className={classes.distanceRow}>
                <p className={classes.distance}>
                  {parseFloat(entity.distance).toFixed(1)} {getCurrentCountry() === "IL" ? t("km") : t("mi")}
                </p>
                <img src={icons.distance} alt={iconsAlts.distance} />
              </div>
            )}
          </div>
          {entity.has_discount && <EntityBenefitMark />}
        </div>

        <p className={classes.restDescription}>
          {isLoading ? (
            <Skeleton height="100%" borderRadius="20px" width="40%" />
          ) : (
            entity.description
          )}
        </p>
        {!isLoading && categories && (
          <div className={classes.categoriesContainer}>
            {categories.map((category) => (
              <div className={classes.categoryContainer} key={category.id}>
                <p className={classes.categoryDot}>•</p>
                <p className={classes.restCategory}>
                  {category && category.category_name ? category.category_name : ""}
                </p>
              </div>
            ))}
          </div>
        )}

        <div className={classes.row}>
          <div className={classes.tagsContainer}>
            {isLoading && <Skeleton height="2rem" borderRadius="20px" width="10rem" />}
            {!isLoading && (
              <GreyIconTags
                className={classes.greyIconTags}
                entity={entity}
                showNames={true}
                showLocation={false}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessesItem;
