import PropTypes from "prop-types";
import Map from "./Map";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import PrimaryButton from "../Common/PrimaryButton/PrimaryButton";
import * as userActions from "../../actions/userActions";
import classes from "./MapView.module.css";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import icons, { iconsAlts } from "../../resources/icons";

const MapView = ({
  center,
  zoom,
  markers,
  onMarkerHover,
  hoveredEntity,
  showLocationRequest = false,
  showInfoWindow = false,
  isLoading = false,
}) => {
  const dispatch = useDispatch();
  const [userMarker, setUserMarker] = useState(null);
  const locationPermission = useSelector((state) => state.user.hasLocationPermissions);
  const hasLocationPermission = locationPermission === userActions.L_PERMISSIONS_GRANTED;

  useEffect(() => {
    if (
      center &&
      center.lat != null &&
      center.lon != null &&
      (!userMarker || center.lat != userMarker.lat || center.lon != userMarker.lon)
    ) {
      {
        setUserMarker({ lat: center.lat, lng: center.lon });
      }
    }
  }, [center]);

  const getUserLocation = () => {
    userActions.getPreciseLocation(dispatch);
  };

  const relocateHandler = () => {
    if (
      center &&
      center.lat != null &&
      center.lon != null &&
      (!userMarker || center.lat != userMarker.lat || center.lon != userMarker.lon)
    ) {
      {
        setUserMarker({ lat: center.lat, lng: center.lon });
      }
    }
  };

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      {isLoading && <Skeleton height="100%" borderRadius="20px" />}
      {!isLoading && userMarker && (
        <Map
          center={userMarker}
          zoom={zoom}
          markers={markers}
          onMarkerHover={onMarkerHover}
          hoveredEntity={hoveredEntity}
          showInfoWindow={showInfoWindow}
        />
      )}

      <button type="button" onClick={relocateHandler}>
        <img
          className={classes.locationButton}
          src={icons.locationButton}
          onClick={relocateHandler}
          alt={iconsAlts.locationButton}
        />
      </button>
      {!hasLocationPermission && showLocationRequest && (
        <PrimaryButton
          title={t("see_places_nearby")}
          onClick={getUserLocation}
          iconLeft={icons.locationWhite}
          isSlim={true}
          fontSize="1.9"
          className={classes.button}
        />
      )}
      {/* <Tooltip /> */}
    </div>
  );
};

MapView.propTypes = {
  center: PropTypes.object,
  zoom: PropTypes.number,
  markers: PropTypes.array,
};

export default MapView;
