import {
  RESET_ENTITIES_STATE,
  SET_IS_LOADING,
  SET_CURRENT_STATE,
  SET_WEBSITE_ENTITIES,
  SET_HOME_PAGE_SEARCH_RESULTS,
  SET_SEARCH_RESULTS,
  SET_SEARCH_RESULT_REQUESTED
} from "../actions/entitiesActions";

const defaultState = {
  homePageEntities: null,
  instagramPosts: [],
  entityCategories: null,
  entityTags: null,
  homePageSearchResults: null,
  reviewHashTags: [],
  newsEntities: [],
  isSearchResultRequested: false,
  searchResults: null,
  currentStateOfData: "",
  isLoading: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESET_ENTITIES_STATE:
      return {
        ...defaultState,
        isLoading: true,
      };
    case SET_CURRENT_STATE:
      return {
        ...state,
        currentStateOfData: action.payload,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_WEBSITE_ENTITIES:
      return {
        ...state,
        homePageEntities: action.payload.homePageEntities,
        instagramPosts: action.payload.instagramWebsitePosts,
        entityCategories: action.payload.entityCategories,
        entityTags: action.payload.entityTags,
        reviewHashTags: action.payload.reviewHashTags,
        newsEntities: action.payload.news,
      };
    case SET_HOME_PAGE_SEARCH_RESULTS:
      return {
        ...state,
        homePageSearchResults: {
          ...state.homePageSearchResults,
          ...action.payload,
        },
      };
    case SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      };

    case SET_SEARCH_RESULT_REQUESTED:
      return {
        ...state,
        isSearchResultRequested: action.payload,
      };
  }

  return state;
};
