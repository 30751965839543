import classes from "./PageError.module.css";
import images, { imagesAlts } from "../../resources/images";
import { PrimaryButton } from "../../components/Common";
import { useNavigate } from "react-router-dom";
import { linkToMainPage } from "../../utils/links";
import { t } from "i18next";

const PageError = ({ onTryAgain }) => {
  return (
    <div className={classes.mainContainer}>
      <img className={classes.image} src={images.error500} alt={imagesAlts.error500} />
      <p className={classes.title}>{t("oh_snap")}</p>
      <p className={classes.text}>{t("500page")}</p>
      <PrimaryButton title={t("try_again")} isSlim={true} onClick={onTryAgain} />
    </div>
  );
};

export default PageError;
