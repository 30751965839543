import { SET_FULL_BUSINESS } from "../actions/businessActions";

const defaultState = {
  fullBusiness: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_FULL_BUSINESS:
      return { ...state, fullBusiness: action.payload };
  }
  return state;
};
