import api from "../api";
import { RESTAURANTS } from "../resources/config";

export const SET_FEATURED_INSTAGRAM_POST = "FEATURED_INSTAGRAM_POST";
export const SET_ABOUT_US_ENTITIES = "SET_ABOUT_US_ENTITIES";

export const getAboutUsEntities = async (dispatch) => {
  const benefitItems = [];
  const campaignItems = [];
  const vaCampaigns = await api.getVaCampaigns();
  const aboutUsItems = await api.getAboutUsItems();
  aboutUsItems.map((item) => {
    if (item.type === "campaign") {
      const found = vaCampaigns.find((element) => element.id == item.va_id);
      if (found) {
        item.num_of_supporters = found.num_of_supporters;
      }
      campaignItems.push(item);
    } else {
      const benefit = {
        entity_type: RESTAURANTS,
        restaurant: {
          logo: item.image_url,
          name: item.title,
        },
        description: item.description,
      };
      //missing here the entity type + what to do with discount type

      benefitItems.push(benefit);
    }
  });
  dispatch({
    type: SET_ABOUT_US_ENTITIES,
    payload: { benefitItems, campaignItems },
  });
};

export const getInstagramPost = async (dispatch, postsList) => {
  try {
    const accessToken = "510678230577795|fe6f2fb235c429aa44a60b1d27de2f26";
    const resultArray = await Promise.all(
      postsList.map(async (post) => {
        const { thumbnail_url } = await api.getInstagramPost(post.post_url, accessToken);
        return {
          appearance_order: post.appearance_order,
          url: post.post_url,
          thumbnail_url,
        };
      })
    );

    dispatch({
      type: SET_FEATURED_INSTAGRAM_POST,
      payload: resultArray,
    });
  } catch (e) {
    console.log(e);
  }
};

export const sendContact = async (fullName, email, subject, details, additionalDetails) => {
  const result = await api.contactUs(fullName, email, subject, details, additionalDetails);
  return result;
};

export const uploadFiles = async (files) => {
  return await api.filesUpload(files);
};
