import * as entitiesUtils from "./entitiesUtils";
import * as scheduleUtils from "./scheduleUtils";
import { s3 } from "../../resources/config";
import { cleanSearchNames } from "../../components/HomePage/HomePageSearch/searchResultsPreparation";

/* Preparing business entity - adding full categories tags and add missing fields */
export function prepareRecipe(recipe, recipeCategories) {
  const preparedRecipe = JSON.parse(JSON.stringify(recipe));
  preparedRecipe.logo = s3 + recipe.logo;
  preparedRecipe.about = recipe.about;
  preparedRecipe.rating = entitiesUtils.numberToFixed(preparedRecipe.rating);

  // preparedRecipe.is_tivoni_taim = checkTivoni !== undefined;
  preparedRecipe.is_tivoni_taim = false;

  preparedRecipe.recipe_categories = entitiesUtils.prepareFullCategories(
    recipe.recipe_categories,
    recipeCategories
  );

  preparedRecipe.search_names = [...cleanSearchNames(recipe.search_names), ...preparedRecipe.recipe_categories.map(r => cleanSearchNames(r.search_names)).flat()]

  return preparedRecipe;
}
