import classes from "./ReviewItem.module.css";
import { t } from "i18next";
import { reviewUtils, scheduleUtils } from "../../../utils/entityUtils";
import images, { imagesAlts } from "../../../resources/images";
import icons, { iconsAlts } from "../../../resources/icons";
import { MobileView, BrowserView } from "react-device-detect";
import { getCurrentLocale } from "../../../utils/utilFunctions";
import { useState } from "react";
import GalleryModal from "../../../modals/GalleryModal/GalleryModal";

const ReviewItem = ({ review, isPending = false }) => {
  const getTranslatedName = (tag) => {

    let translation;
    if (tag.translations) {
      translation = tag.translations.find((item) => item.locale == getCurrentLocale());
    }
    return translation ? translation.value : "";
  };

  const getPendingStyle = () => {
    return isPending ? { opacity: 0.5 } : {};
  };

  const [isGalleryOpen, setGalleryOpen] = useState(false);
  const [selectedImage, setSelectedImages] = useState(0);

  const imageClickHandler = (index) => {
    setGalleryOpen(true);
    setSelectedImages(index);
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.reviewContainer}>
        <img
          className={classes.reviewerImage}
          src={review.avatar === 0 ? images.avatar1 : images.avatar2}
          style={getPendingStyle()}
          alt={review.avatar === 0 ? imagesAlts.avatar1 : imagesAlts.avatar2}
        />
        <div className={classes.contentContainer}>
          <div className={classes.reviewerDetails}>
            <p className={classes.reviewerName} style={getPendingStyle()}>
              {review.reviewer_name}
            </p>
            {isPending && <p className={classes.pendingTag}>Pending</p>}
            <div className={classes.ratingTag} style={getPendingStyle()}>
              <img src={reviewUtils.getIconByRating(review.score, true)} alt="rating icon" />
              <p className={classes.ratingTagText}>
                {review.score}/5 {reviewUtils.getTextByRating(review.score)}
              </p>
            </div>
          </div>

          <p className={classes.reviewerTag} style={getPendingStyle()}>
            {/* {review.user_status === "anonymous" ? t("anonymous_user") : t("vf_member")} {"   "} ·{" "}
            {"   "} */}
            {scheduleUtils.timeSince(review.created_at)}
            {t("ago")}
          </p>
          <p className={classes.description} style={getPendingStyle()}>
            {review.description}
          </p>
          {review.tags && review.tags.length > 0 && (
            <p className={classes.reviewTags} style={getPendingStyle()}>
              #
              {review.tags
                .map((tag) => tag.name)
                .join(" #")
                .toLowerCase()}
            </p>
          )}
          {review.pics && review.pics.length > 0 && (
            <div className={classes.test} style={getPendingStyle()}>
              <BrowserView>
                <div className={classes.imagesGrid}>
                  {review.pics
                    .map((pic, index) => (
                      <div
                        className={classes.imageContainer}
                        onClick={() => imageClickHandler(index)}
                      >
                        <img className={classes.uploadedImage} src={pic} alt="review image" />
                        {review.pics.length > 3 && (
                          <div className={classes.showMoreImagesContainer}>
                            <p className={classes.showMoreText}>+{review.pics.length - 3}</p>
                          </div>
                        )}
                      </div>
                    ))
                    .slice(0, 3)}
                </div>
              </BrowserView>
              <MobileView>
                <div className={`${classes.mobileContainer} ${"hideScrollBars"}`}>
                  {review.pics.map((pic, index) => (
                    <img
                      className={classes.uploadedImage}
                      src={pic}
                      onClick={() => imageClickHandler(index)}
                      alt="review image"
                    />
                  ))}
                </div>
              </MobileView>
            </div>
          )}
          <div className={classes.bottomRow} style={getPendingStyle()}>
            {review.vote_counter > 0 && (
              <>
                <img src={icons.likeGrey} alt={iconsAlts.likeGrey} />
                <p className={classes.voteCount}>{2}</p>
                {/* <p className={classes.voteCount}>{review.vote_counter}</p> */}
              </>
            )}
          </div>
        </div>

        <GalleryModal
          show={isGalleryOpen}
          onHide={() => {
            setSelectedImages(0);
            setGalleryOpen(false);
          }}
          selectedIndex={selectedImage}
          coverImages={review.pics ? review.pics : []}
        />
      </div>
    </div>
  );
};

export default ReviewItem;
