import React, { useState } from "react";

import update from "immutability-helper";
import { useCallback } from "react";
import { ReorderablePhoto } from "./ReorderablePhoto.js";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import classes from "./PhotoReorder.module.css";
import FileDragArea from "../../../components/FileUpload/FileDragArea";
import { TouchBackend } from "react-dnd-touch-backend";
import { isMobile } from "react-device-detect";

const PhotoReorder = ({ uploads, onUploadsChange, photoSelectedHandler, onRemoveImage }) => {
  {
    const moveCard = useCallback((dragIndex, hoverIndex) => {
      onUploadsChange((uploads) =>
        update(uploads, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, uploads[dragIndex]],
          ],
        })
      );
    }, []);

    const renderCard = useCallback((upload, index) => {
      return (
        <ReorderablePhoto
          className={classes.item}
          key={JSON.stringify(upload.preview)}
          index={index}
          id={JSON.stringify(upload.preview)}
          moveCard={moveCard}
          src={upload.preview}
          onRemoveImage={onRemoveImage}
        />
      );
    }, []);

    const getFileDragItems = (imgCount) => {
      const items = [];
      for (let i = 0; i < 5 - imgCount; i++) {
        items.push(
          <FileDragArea
            className={classes.item}
            onPhotoSelected={photoSelectedHandler}
            text={`${i + 1 + uploads.length}nd image`}
          />
        );
      }
      return items;
    };

    return (
      <>
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
          <div className={classes.photoContainer}>
            {uploads.map((upload, i) => renderCard(upload, i))}
            {getFileDragItems(uploads.length)}
          </div>
        </DndProvider>
      </>
    );
  }
};

export default PhotoReorder;
