import classes from "./AddReviewModal.module.css";
import Modal from "react-bootstrap/Modal";
import ErrorableInput from "../../components/Common/Input/ErrorableInput";
import { t } from "i18next";
import { PrimaryButton, GreenTag } from "../../components/Common/index";
import useInput from "../../hooks/use-input";
import ErrorableTextArea from "../../components/Common/Input/ErrorableTextArea";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FileDragArea from "../../components/FileUpload/FileDragArea";
import icons, { iconsAlts } from "../../resources/icons";
import * as generalActions from "../../actions/generalActions";
import * as userActions from "../../actions/userActions";
import PhotoReorder from "../../components/FileUpload/PhotoReorder/PhotoReorder";
import images from "../../resources/images";
import { reviewUtils } from "../../utils/entityUtils";
import { getCurrentLocale } from "../../utils/utilFunctions";
import { getTextByRating } from "../../utils/entityUtils/reviewUtils";
import Loader from "../../components/Loader/Loader";
import { logEvents } from "../../eventsManager";
import Compressor from "compressorjs";
import { BRANCHES, RECIPES, RESTAURANTS } from "../../resources/config";

const AddReviewModal = ({
  show,
  onHide,
  entityType,
  entityId,
  entityName,
  entityRating,
  onSuccess,
  eventProps,
}) => {
  const [selectedRating, setSelectedRating] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const user = useSelector((state) => state.user.user);
  const hashTags = useSelector((state) => state.entities.reviewHashTags);
  const dispatch = useDispatch();

  const {
    value: enteredName,
    isValid: enteredNameIsValid,
    hasError: nameInputHasError,
    defaultValueHandler: nameDefaultHandler,
    valueChangeHandler: nameChangedHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetNameInput,
  } = useInput((value) => value.trim().length > 1);

  const {
    value: enteredReview,
    isValid: enteredReviewIsValid,
    hasError: reviewHasError,
    defaultValueHandler: reviewDefaultHandler,
    valueChangeHandler: reviewChangedHandler,
    inputBlurHandler: reviewBlurHandler,
    reset: resetReviewInput,
  } = useInput((value) => value.trim().length > 14);

  let formIsValid = false;

  if (enteredNameIsValid && enteredReviewIsValid && selectedRating != null) {
    formIsValid = true;
  }

  const previousPageHandler = () => {
    setPageNumber(1);
  };

  const [hasRatingError, setHasRatingError] = useState(false);
  const nextPageHandler = () => {
    logEvents("review_complete_first_step", {
      ...eventProps,
      rating_error: selectedRating === null || !formIsValid,
      rating_error: selectedRating === null,
      name_error: !enteredNameIsValid,
      text_error: !enteredReviewIsValid,
    });

    if (!formIsValid || selectedRating === null) {
      if (selectedRating === null) {
        setHasRatingError(true);
      }
      if (!formIsValid) {
        nameBlurHandler();
        reviewBlurHandler();
        window.scrollTo(0, 0);
      }
      return;
    }
    setPageNumber(2);
  };

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const photoSelectedHandler = (file) => {
    logEvents("review_add_photos", eventProps);
    const newFile = renameFile(file, Date.now());
    new Compressor(newFile, {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (res) => {
        const previewUrl = URL.createObjectURL(res);
        const uploadFile = { preview: previewUrl, file: res };
        setUploadedFiles((files) => [...files, uploadFile]);
      },
    });
  };

  const renameFile = (originalFile, newName) => {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  };

  const ratingClickHandler = (rating) => {
    setSelectedTags([]);
    if (selectedRating === rating) {
      setSelectedRating(null);
    } else {
      setSelectedRating(rating);
      setHasRatingError(false);
    }
  };

  const removeFileHandler = (index) => {
    setUploadedFiles((list) => {
      let array = [...list];
      array.splice(index, 1);
      return array;
    });
  };

  const [selectedTags, setSelectedTags] = useState([]);

  const getHashTagsByEntity = (hashTags) => {
    switch (entityType) {
      case RESTAURANTS:
        return hashTags.restaurantTags;
      case BRANCHES:
        return hashTags.businessTags;
      case RECIPES:
        return hashTags.recipeTags;
    }
    return [];
  };

  const tagsSelectHandler = (tag) => {
    setSelectedTags((hashTags) => {
      const tagAlreadySelected = hashTags.find((t) => t.id === tag.id);
      if (tagAlreadySelected) {
        return hashTags.filter((t) => t.id != tag.id);
      } else {
        return [tag, ...hashTags];
      }
    });
  };

  const formSubmissionHandler = async () => {
    setIsLoading(true);
    let images = [];
    if (uploadedFiles && uploadedFiles.length > 0) {
      images = await generalActions.uploadFiles(uploadedFiles.map((uploaded) => uploaded.file));
    }

    if (user) {
      const result = await userActions.postNewReview(
        dispatch,
        entityType,
        entityId,
        selectedRating,
        enteredReview,
        enteredName,
        "",
        images,
        selectedTags.map((tag) => tag.id)
      );

      if (result.success) {
        logEvents("entity_review_added", {
          ...eventProps,
          review_num_hearts: selectedRating,
          review_message: enteredReview,
          current_rating: entityRating,
        });
        setIsLoading(false);
        setHasError(false);
        onSuccess();
        resetState();
      } else {
        setIsLoading(false);
        setHasError(true);
      }
    } else {
      setIsLoading(false);
      setHasError(true);
    }
  };

  const resetState = () => {
    setPageNumber(1);
    setSelectedRating(null);
    resetNameInput();
    resetReviewInput();
    setUploadedFiles([]);
  };

  return (
    <Modal
      show={show}
      contentClassName={classes.mainContainer}
      onHide={onHide}
      size={"lg"}
      centered
    >
      {pageNumber === 1 ? (
        <div className={classes.contentContainer}>
          <p className={classes.pageNumber}>
            {t("step")} {pageNumber}/2
          </p>
          <img
            className={getCurrentLocale() === "he" ? classes.exitButtonIL : classes.exitButton}
            src={icons.closeXWhite}
            onClick={onHide}
            alt={iconsAlts.closeXWhite}
          />
          <p className={classes.addRatingTitle}>{t("rate_title")}</p>
          <div className={hasRatingError ? classes.ratingContainerError : classes.ratingContainer}>
            <img
              className={
                hasRatingError
                  ? classes.ratingFaceError
                  : selectedRating === 1
                  ? classes.ratingFaceSelected
                  : classes.ratingFace
              }
              src={reviewUtils.getIconByRating(1, true)}
              onClick={() => ratingClickHandler(1)}
              alt="rating icon"
            />
            <img
              className={
                hasRatingError
                  ? classes.ratingFaceError
                  : selectedRating === 2
                  ? classes.ratingFaceSelected
                  : classes.ratingFace
              }
              src={reviewUtils.getIconByRating(2, true)}
              onClick={() => ratingClickHandler(2)}
              alt="rating icon"
            />
            <img
              className={
                hasRatingError
                  ? classes.ratingFaceError
                  : selectedRating === 3
                  ? classes.ratingFaceSelected
                  : classes.ratingFace
              }
              src={reviewUtils.getIconByRating(3, true)}
              onClick={() => ratingClickHandler(3)}
              alt="rating icon"
            />
            <img
              className={
                hasRatingError
                  ? classes.ratingFaceError
                  : selectedRating === 4
                  ? classes.ratingFaceSelected
                  : classes.ratingFace
              }
              src={reviewUtils.getIconByRating(4, true)}
              onClick={() => ratingClickHandler(4)}
              alt="rating icon"
            />
            <img
              className={
                hasRatingError
                  ? classes.ratingFaceError
                  : selectedRating === 5
                  ? classes.ratingFaceSelected
                  : classes.ratingFace
              }
              src={reviewUtils.getIconByRating(5, true)}
              onClick={() => ratingClickHandler(5)}
              alt="rating icon"
            />
          </div>
          <p className={classes.rateTitle}>
            {selectedRating ? getTextByRating(selectedRating) : t("rate")}
          </p>
          <p className={classes.addTagsTitle}>{t("add_name_title")}</p>
          <ErrorableInput
            id="name"
            type="text"
            onChange={nameChangedHandler}
            onBlur={nameBlurHandler}
            value={enteredName}
            placeholder={t("full_name")}
            hasError={nameInputHasError}
            errorMessage={t("contact_us_name_error")}
          />
          <p className={classes.addTagsTitle}>{t("add_review_title")}</p>
          <ErrorableTextArea
            id="reviewDetails"
            type="text"
            onChange={reviewChangedHandler}
            onBlur={reviewBlurHandler}
            value={enteredReview}
            placeholder={t("tell_us")}
            hasError={reviewHasError}
            errorMessage={t("review_error")}
            minChars={15}
          />
          {hashTags && getHashTagsByEntity(hashTags) && (
            <>
              <p className={classes.addTagsTitle}>{t("add_tags_title")}</p>
              <div className={classes.hashTagsContainer}>
                {getHashTagsByEntity(hashTags)
                  .filter((tag) => tag.score === (selectedRating ?? 5))
                  .map((tag, index) => (
                    <GreenTag
                      key={index}
                      tag={tag}
                      tagName={tag.name}
                      isSelected={selectedTags.some((t) => t.id === tag.id)}
                      onTagSelected={(tag) => {
                        tagsSelectHandler(tag);
                      }}
                    />
                  ))}
              </div>
            </>
          )}
          <div className={classes.row}>
            <PrimaryButton title={t("next")} onClick={nextPageHandler} isSlim={true} />
          </div>
        </div>
      ) : (
        <div className={classes.contentContainer}>
          <p className={classes.pageNumber}>
            {t("step")} {pageNumber}/2
          </p>
          <img
            className={getCurrentLocale() === "he" ? classes.exitButtonIL : classes.exitButton}
            src={icons.closeXWhite}
            onClick={onHide}
            alt={iconsAlts.closeXWhite}
          />
          {uploadedFiles && uploadedFiles.length > 0 ? (
            <div>
              <div className={classes.titleContainer}>
                <p className={classes.addPhotosTitle}>{t("add_5_photo")}</p>
                <FileDragArea
                  onPhotoSelected={photoSelectedHandler}
                  className={classes.addMoreImageDragContainer}
                  image={images.cameraSmall}
                  dropAreaClassName={classes.addMoreImagesContainer}
                  text={t("add_more_images")}
                />
              </div>
              <div className={classes.photosContainer}>
                <PhotoReorder
                  uploads={uploadedFiles}
                  onUploadsChange={(files) => setUploadedFiles(files)}
                  photoSelectedHandler={photoSelectedHandler}
                  onRemoveImage={removeFileHandler}
                />
              </div>
            </div>
          ) : (
            <div>
              <div className={classes.columTitleContainer}>
                <p className={classes.addPhotosTitle}>{t("add_photos")}</p>
                <p className={classes.addPhotosSubtitle}>{t("add_images_subtitle")}</p>
              </div>
              <FileDragArea
                className={classes.fileDragArea}
                onPhotoSelected={photoSelectedHandler}
                image={images.camera}
                text={t("add_photo")}
              />
              <span className={classes.addPhotosPlaceHolder}>
                <p>Supported formats: JPG, JPEG, PNG, GIF, TIF, TIFF, HEIC, HEIF, HEIF</p>
                <p>{t("add_5_photo")} </p>
              </span>
            </div>
          )}
          <div className={classes.rowSecondPage}>
            <button className={classes.backButton} onClick={previousPageHandler}>
              {t("back")}
            </button>
            {hasError && (
              <p className={classes.error}>
                Uh oh! There was an error uploading your photos, please try again
              </p>
            )}
            <PrimaryButton title={t("submit")} onClick={formSubmissionHandler} isSlim={true} />
            {isLoading && (
              <div className={classes.loaderContainer}>
                <Loader />
              </div>
            )}

            {/* {true && (
              <div className={classes.loaderContainer}>
                <div class={classes.progress}>Uploaded 4/5 photos</div>
              </div>
            )} */}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AddReviewModal;
