import React, { useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  LoadScript,
  MarkerClusterer,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import images from "../../resources/images";
import classes from "./Map.module.css";
import { BRANCHES, RESTAURANTS } from "../../resources/config";
import "./Map.module.css";
import { useNavigate } from "react-router-dom";
import * as links from "../../utils/links";
import EntityRating from "../Entities/EntityRating/EntityRating";
import icons from "../../resources/icons";
import { t } from "i18next";
import { getCurrentCountry } from "../../utils/utilFunctions";

const containerStyle = {
  width: "100%",
  height: "100%",
  borderRadius: "10px",
};

const iconStyle = {
  height: "20rem",
  width: "4rem",
  objectFit: "cover",
};

const Map = ({ center, zoom, markers, onMarkerHover, hoveredEntity, showInfoWindow }) => {
  const navigate = useNavigate();
  const [map, setMap] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);

    map.setOptions({
      gestureHandling: "greedy",
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    });
    map.fitBounds(bounds);
    setTimeout(() => {
      map.setZoom(zoom);
    }, 3000);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  function createKey(location, index) {
    return location.lat + location.lon + index;
  }

  const calculator = function (markers, numStyles) {
    var index = 0;
    var count = markers.length;
    var dv = count;
    while (dv !== 0) {
      dv = parseInt(dv / 10, 10);
      index++;
    }

    index = Math.min(index, numStyles);

    return {
      text: count,
      index: index,
      title: "s",
    };
  };

  const clusterStyles = [
    {
      height: 50,
      textColor: "#ffffff",
      width: 50,
      url: images.mapMarker,
      anchorText: [6, 0],
    },
  ];

  const renderInfoWindow = (entity) => {
    const categories = entity.categories
      ? entity.categories.map((c) => c.category_name).join("*")
      : "";
    return (
      <div className={classes.infoWindowContainer} onClick={() => infoClickHandler(entity)}>
        <img className={classes.infoWindowImg} src={entity.logo} alt={"entity logo"} />
        <div className={classes.contentContainer}>
          <p className={classes.infoWindowName}>{entity.name}</p>
          {entity.categories && (
            <div className={classes.categoriesContainer}>
              {entity.categories.map((category) => (
                <div className={classes.categoryContainer} key={category.id}>
                  <p className={classes.categoryDot}>•</p>
                  <p className={classes.restCategory}>
                    {category && category.category_name ? category.category_name : ""}
                  </p>
                </div>
              ))}
            </div>
          )}
          {entity.rating > 0 && entity.reviews > 0 && (
            <EntityRating rating={entity.rating} reviewNum={entity.reviews} />
          )}
        </div>
      </div>
    );
  };

  const infoClickHandler = (entity) => {
    switch (entity.entityType) {
      case RESTAURANTS:
        navigate(links.linkToRestaurant(entity.id, entity.name, entity.location));
        break;
      case BRANCHES:
        navigate(
          links.linkToBusiness(entity.branch_id, entity.name, entity.location, entity.is_online)
        );
        break;
    }
  };

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={12}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker styles={iconStyle} icon={images.mapMarkerMe} key={"me"} position={center} />
      <MarkerClusterer
        options={{
          averageCenter: true,
          styles: clusterStyles,
        }}
        minimumClusterSize={999}
        calculator={calculator}
      >
        {(clusterer) =>
          markers.map((marker, index) => (
            <Marker
              icon={images.mapMarker}
              key={createKey(marker.location, index)}
              position={{ lat: marker.location.lat, lng: marker.location.lon }}
              clusterer={clusterer}
              onMouseOver={() => {
                if (onMarkerHover) onMarkerHover(marker);
              }}
              onMouseOut={() => {
                if (onMarkerHover) onMarkerHover(null);
              }}
              onClick={() => setSelectedMarker(marker)}
              animation={
                hoveredEntity && hoveredEntity.id == marker.id
                  ? window.google.maps.Animation.BOUNCE
                  : null
              }
            ></Marker>
          ))
        }
      </MarkerClusterer>
      {selectedMarker && showInfoWindow && (
        <InfoWindow
          position={{
            lat: selectedMarker.location.lat,
            lng: selectedMarker.location.lon,
          }}
          onCloseClick={() => setSelectedMarker(null)}
        >
          {renderInfoWindow(selectedMarker)}
        </InfoWindow>
      )}
      <></>
    </GoogleMap>
  );
};

export default Map;
