import { useEffect, useState } from "react";
import classes from "./PageSelector.module.css";
import { t } from "i18next";
import icons, { iconsAlts } from "../../../resources/icons";
import { getCurrentLocale } from "../../../utils/utilFunctions";

const PageSelector = ({ currentPage, totalPages, onPageSelected }) => {
  const [enteredPage, setEnteredPage] = useState(currentPage);
  const [hasError, setError] = useState(false);

  const isNextDisabled = currentPage == totalPages;
  const isPreviousDisabled = currentPage == 1;

  useEffect(() => {
    setEnteredPage(currentPage);
  }, [currentPage]);

  const onNextClick = () => {
    setEnteredPage(+currentPage + 1);
    onPageSelected(+currentPage + 1);
  };

  const onPreviousClick = () => {
    setEnteredPage(currentPage - 1);
    onPageSelected(currentPage - 1);
  };

  const inputHandler = (event) => {
    setEnteredPage(event.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setError(false);
      if (enteredPage <= totalPages) {
        onPageSelected(enteredPage);
      } else {
        setError(true);
      }
    }
  };

  const getIconStyle = () => {
    const locale = getCurrentLocale();
    return locale === "he" ? classes.btnIcon : classes.btnIconFlipped;
  };

  return (
    <div className={classes.mainContainer}>
      <p className={classes.text}>{t("page")}</p>
      <input
        id="input"
        className={`${classes.input} ${hasError ? classes.error : ""} `}
        value={enteredPage}
        onChange={inputHandler}
        onKeyDown={handleKeyDown}
        type="number"
      />
      <p className={classes.text}>
        {" "}
        {t("of")} {totalPages}
      </p>
      <div className={classes.btnContainer}>
        <button
          type="button"
          id="previous-page-button"
          className={`${classes.previousBtn} ${isPreviousDisabled ? classes.btnDisabled : ""}`}
          onClick={onPreviousClick}
        >
          <img
            className={getIconStyle()}
            src={icons.whiteArrowRight}
            alt={iconsAlts.whiteArrowRight}
          />
        </button>
        <button
          type="button"
          id="next-page-button"
          className={`${classes.nextBtn} ${isNextDisabled ? classes.btnDisabled : ""}`}
          onClick={onNextClick}
        >
          <img className={getIconStyle()} src={icons.whiteArrowLeft} alt={icons.whiteArrowLeft} />
        </button>
      </div>
    </div>
  );
};

export default PageSelector;
