import { ALL, BENEFITS, BRANCHES, RECIPES, RESTAURANTS } from "../../../resources/config";
import images, { imagesAlts } from "../../../resources/images";
import classes from "./NoSearchResults.module.css";
import { EntityCarousel } from "../../Entities/EntityContainers";
import { t } from "i18next";

const NoSearchResults = ({ selectedEntity, searchResults }) => {
  const getMayInterestYouEntities = () => {
    switch (selectedEntity.name) {
      case BRANCHES:
        return searchResults ? searchResults.businesses.slice(0, 11) : [];
      case RECIPES:
        return searchResults ? searchResults.recipes.slice(0, 11) : [];
      case BENEFITS:
        return searchResults ? searchResults.benefits.slice(0, 11) : [];
      default:
        return searchResults ? searchResults.restaurants.slice(0, 11) : [];
    }
  };
  const entities = getMayInterestYouEntities();

  return (
    <div className={classes.mainContainer}>
      <img src={images.noResults} alt={imagesAlts.noResults} />
      <p className={classes.title}>{t("no_results")}</p>
      {entities.length > 0 && (
        <EntityCarousel
          title={t("maybe_interest_you")}
          entities={entities}
          entityType={selectedEntity.name !== ALL ? selectedEntity.name : RESTAURANTS}
          isLoading={false}
          enableShowMore={false}
        />
      )}
    </div>
  );
};

export default NoSearchResults;
