import classes from "./NewsletterModal.module.css";
import Modal from "react-bootstrap/Modal";
import images, { imagesAlts } from "../../resources/images";
import { useState } from "react";
import { t } from "i18next";
import PrimaryButton from "../../components/Common/PrimaryButton/PrimaryButton";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { getCurrentLocale } from "../../utils/utilFunctions";
import icons, { iconsAlts } from "../../resources/icons";
import config from "../../config";
import { logEvents } from "../../eventsManager";
import ErrorableInput from "../../components/Common/Input/ErrorableInput";
import useInput from "../../hooks/use-input";

const NewsletterModal = ({ show, onHide }) => {
  const [isSuccess, setSuccess] = useState(false);
  const {
    value: enteredName,
    isValid: enteredNameIsValid,
    hasError: nameInputHasError,
    defaultValueHandler: nameDefaultHandler,
    valueChangeHandler: nameChangedHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetNameInput,
  } = useInput((value) => value.trim().length > 1);

  const validateEmail = (email) => {
    // Regular expression to check if string is email
    const regexExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    return regexExp.test(email);
  };

  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: emailInputHasError,
    defaultValueHandler: emailDefaultHandler,
    valueChangeHandler: emailChangedHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmailInput,
  } = useInput((value) => validateEmail(value));

  let formIsValid = false;

  if (enteredNameIsValid && enteredEmailIsValid) {
    formIsValid = true;
  }

  const onSubscribeHandler = (subscribe) => {
    if (!formIsValid) {
      nameBlurHandler();
      emailBlurHandler();

      return;
    }

    localStorage.setItem("subscribed_to_newsletter", true);
    subscribe({
      FNAME: enteredName,
      EMAIL: enteredEmail,
      tags: 6986,
      // "group[18][128]": true,
    });

    logEvents("newsletter_click", { submitted: true, name: enteredName, email: enteredEmail });
    // onHideHandler();
    setSuccess(true);
  };

  const onNewsletterHide = () => {
    logEvents("newsletter_click", { submitted: false, name: enteredName, email: enteredEmail });
    onHideHandler();
  };

  const onHideHandler = () => {
    setSuccess(false);
    onHide();
    resetNameInput();
    resetEmailInput();
  };

  const getMailChimpUrlByCountry = () => {
    const currentCountry = config.currentCountry;
    switch (currentCountry) {
      case "IL":
        return "https://vegan-friendly.us17.list-manage.com/subscribe/post?u=4b4678ac382a5c66ea5860f4f&amp;id=d0525c5c96";
      case "GB":
        return "https://vegan-friendly.us17.list-manage.com/subscribe/post?u=4b4678ac382a5c66ea5860f4f&amp;id=316f97a482";
    }
  };

  return (
    <Modal
      show={show}
      contentClassName={classes.mainContainer}
      onHide={onNewsletterHide}
      size={"xl"}
      centered
    >
      <MailchimpSubscribe
        url={getMailChimpUrlByCountry()}
        render={({ subscribe, status, message }) => (
          <div className={classes.contentContainer}>
            <img
              className={config.currentCountry == "IL" ? classes.exitButtonIL : classes.exitButton}
              src={icons.closeXWhite}
              onClick={onNewsletterHide}
              alt={iconsAlts.closeXWhite}
            />
            <img className={classes.img} src={images.newsletter} alt={imagesAlts.newsletter} />
            {isSuccess ? (
              <>
                <p className={classes.title}>{t("newsletter_success_title")}</p>
                <p className={classes.subtitle}>{t("newsletter_success_subtitle")}</p>
                <PrimaryButton
                  className={classes.button}
                  title={t("close")}
                  onClick={() => {
                    setSuccess(false);
                    onHide();
                  }}
                  isBig={true}
                />
              </>
            ) : (
              <>
                <p className={classes.title}>{t("newsletter_copy")}</p>
                <ErrorableInput
                  id="contact-us-modal-name"
                  type="text"
                  onChange={nameChangedHandler}
                  onBlur={nameBlurHandler}
                  value={enteredName}
                  placeholder={t("full_name")}
                  hasError={nameInputHasError}
                  errorMessage={t("contact_us_name_error")}
                  inputClass={classes.input}
                />
                <ErrorableInput
                  id="contact-us-modal-email"
                  type="text"
                  onChange={emailChangedHandler}
                  onBlur={emailBlurHandler}
                  value={enteredEmail}
                  placeholder={t("your_email")}
                  hasError={emailInputHasError}
                  errorMessage={t("contact_us_email_error")}
                  inputClass={classes.input}
                />
                <PrimaryButton
                  className={classes.button}
                  title={t("subscribe")}
                  onClick={() => onSubscribeHandler(subscribe)}
                  isBig={true}
                />
                <button type="button" className={classes.cancelButton} onClick={onNewsletterHide}>
                  {t("cancel")}
                </button>
              </>
            )}
          </div>
        )}
      />
    </Modal>
  );
};

export default NewsletterModal;
