import icons, { iconsAlts } from "../../../resources/icons";
import classes from "./EntityWrongInfo.module.css";
import { ContactUsModal } from "../../../modals";
import { useState } from "react";
import ContactUsSuccessModal from "../../../modals/ContactUs/ContactUsSuccessModal/ContactUsSuccessModal";
import * as dateUtils from "../../../utils/dateUtils";
import { t } from "i18next";

const EntityWrongInfo = ({ entityName, className, lastUpdate, showUpdated = true }) => {
  const [isContactUsModalOpen, setContactUsModalOpen] = useState(false);
  const [isContactUsSuccessOpen, setContactUsSuccessOpen] = useState(false);
  const [contactUsRequestCode, setContactUsRequestCode] = useState(null);

  return (
    <div className={`${classes.mainContainer} ${className}`}>
      <button
        type="button"
        className={classes.wrongInfoContainer}
        onClick={() => setContactUsModalOpen((isOpen) => !isOpen)}
      >
        <img src={icons.greenFlag} alt={iconsAlts.greenFlag} />
        <p className={classes.wrongInfoText}>{t("wrong_info_title")}</p>
      </button>
      {lastUpdate && showUpdated && (
        <p className={classes.lastUpdateText}>
          {t("updated")}
          {dateUtils.formatDateNumeric(lastUpdate).replaceAll(".", "/")}
        </p>
      )}
      <ContactUsModal
        show={isContactUsModalOpen}
        onHide={() => setContactUsModalOpen(false)}
        firstInfo={entityName}
        optionNumber={2}
        onSuccess={(code) => {
          setContactUsSuccessOpen(true);
          setContactUsRequestCode(code);
        }}
      />
      <ContactUsSuccessModal
        show={isContactUsSuccessOpen}
        onHide={() => setContactUsSuccessOpen(false)}
        caseNumber={contactUsRequestCode}
      />
    </div>
  );
};

export default EntityWrongInfo;
