import classes from "./SearchFiltersModal.module.css";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import { t } from "i18next";
import GreenTag from "../../Common/Tags/GreenTag";
import EntityCategories from "../../Entities/EntityCategories/EntityCategories";
import { ALL, RESTAURANTS, BRANCHES, RECIPES, BENEFITS, BRANDS, BLOGS, EMPLOYERS } from "../../../resources/config";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../Common";
import { getCurrentLocale } from "../../../utils/utilFunctions";
import icons, { iconsAlts } from "../../../resources/icons";

const SearchFiltersModal = ({
  show,
  onHide,
  entitiesTypeList,
  selectedEntity,
  onEntitySelected,
  categoriesList,
  selectedCategories,
  onCategorySelected,
  tagsList,
  selectedTags,
  onTagSelected,
  isLoading,
  hasResults,
  clearFilters,
  resultsNumber,
}) => {
  const navigate = useNavigate();
  const [displayedCategories, setDisplayedCategories] = useState([]);

  useEffect(() => {
    if (categoriesList) {
      updateDisplayedCategories(selectedEntity.name);
    }
  }, [selectedEntity, categoriesList]);

  const updateDisplayedCategories = (type) => {
    switch (type) {
      case BRANCHES:
        setDisplayedCategories(categoriesList.businessesSubs);
        break;
      case RESTAURANTS:
        setDisplayedCategories(categoriesList.restaurants.filter((c) => c.show));
        break;
      case RECIPES:
        setDisplayedCategories(categoriesList.recipes);
        break;
      case BENEFITS:
        setDisplayedCategories(categoriesList.benefits);
        break;
      case BRANDS:
        setDisplayedCategories(categoriesList.companiesSubs);
        break;
      case BLOGS:
          setDisplayedCategories(categoriesList.blogs);
        break; 
      case EMPLOYERS:
        setDisplayedCategories(categoriesList.employers);
        break;   
      default:
        setDisplayedCategories([]);
    }
  };

  const isTagsShowing = () => {
    if (
      selectedEntity.name == BRANCHES ||
      selectedEntity.name == RESTAURANTS ||
      selectedEntity.name == BENEFITS
    ) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      show={show}
      contentClassName={classes.modalContainer}
      onHide={onHide}
      size={"me"}
      centered
    >
      <>
        <img
          className={classes.exitButton}
          src={icons.closeXWhite}
          onClick={onHide}
          alt={iconsAlts.closeXWhite}
        />
        <div className={classes.mainContainer}>
          {!isLoading && displayedCategories.length > 0 && hasResults && (
            <div>
              <p className={classes.header}>{t(selectedEntity.name)}</p>
              <EntityCategories
                categories={displayedCategories}
                selectedCategories={selectedCategories}
                onCategorySelected={onCategorySelected}
                shouldDisableByCount={true}
                shouldHideUnpopular={false}
                className={classes.categoriesContainer}
              />
            </div>
          )}
          {!isLoading && isTagsShowing() && tagsList && hasResults && (
            <div>
              <p className={classes.header}>{t("filters")}</p>
              <div className={classes.tagsContainer}>
                {tagsList.map((tag) => (
                  <GreenTag
                    key={tag.name}
                    tag={tag}
                    tagName={t(tag.label) + (tag.count ? " (" + tag.count + ")" : "")}
                    isSelected={selectedTags.find((t) => t.name === tag.name) != undefined}
                    icon={tag.icon}
                    iconSelected={tag.iconSelected}
                    isDisabled={!tag.count}
                    onTagSelected={(tag) => {
                      onTagSelected(tag);
                    }}
                    isHollow={true}
                  />
                ))}
              </div>
            </div>
          )}
          <button type="button" className={classes.clearFilters} onClick={clearFilters}>
            {t("clear_all")}
          </button>
          <PrimaryButton
            className={classes.showResults}
            title={t("show") + " " + resultsNumber + " " + t("results")}
            onClick={onHide}
            isSlim={true}
          />
        </div>
      </>
    </Modal>
  );
};

export default SearchFiltersModal;
