import api from "../api";

export const SET_FULL_COMPANY = "SET_FULL_COMPANY";

export const getFullCompany = async (dispatch, id) => {
  dispatch({
    type: SET_FULL_COMPANY,
    payload: {},
  });

  let company = await api.getFullCompany(id);
  if(company.reviews) {
    company.reviews.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });
  }

  dispatch({
    type: SET_FULL_COMPANY,
    payload: company,
  });
};
