import classes from "./AppDownloadBanner.module.css";
import images, { imagesAlts } from "../../../resources/images";
import { t } from "i18next";

const AppDownloadBanner = ({ fromAboutUs = false }) => {
  return (
    <div className={classes.mainContainer}>
      <div className={classes.contentWrapper}>
        <img
          className={classes.imageContainer}
          src={images.cowWithPhone}
          alt={imagesAlts.cowWithPhone}
        />
        <div className={fromAboutUs ? classes.textContainerAboutUs : classes.textContainer}>
          <p className={classes.title}>{t("download_our_app_footer")}</p>
          <p className={classes.description}>{t("about_app_footer")}</p>

          <div className={classes.buttonsContainer}>
            <a
              className={classes.downloadButton}
              target="_blank"
              href={"https://apps.apple.com/il/app/vegan-friendly/id1446617122"}
            >
              <img
                className={classes.downloadButtonImage}
                src={images.downloadAppStore}
                alt={imagesAlts.downloadAppStore}
              />
            </a>
            <a
              className={classes.downloadButton}
              target="_blank"
              href={
                "https://play.google.com/store/apps/details?id=com.veganfriendly.mobileapp&hl=en&gl=US&pli=1"
              }
            >
              <img
                className={classes.downloadButtonImage}
                src={images.downloadGooglePlay}
                alt={imagesAlts.downloadGooglePlay}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppDownloadBanner;
