import classes from "./WebSearchResults.module.css";
import { useState } from "react";
import { sectionsRender } from "../../../pages/Search/SearchHelpers";
import MapView from "../../Map/MapView";
import { ALL, BENEFITS } from "../../../resources/config";
import { NoSearchResults, PageSelector } from "../index";
import { useNavigate } from "react-router-dom";
import { linkToSearch } from "../../../utils/links";
import { EntityCarousel } from "../../Entities/EntityContainers";
import AboutBanner from "../../Banners/AboutBanner/AboutBanner";
import config from "../../../config";

const WebSearchResults = ({
  sectionList,
  isLoading,
  userLocation,
  entitySelectHandler,
  searchParams,
  totalPages,
  preparedResults,
  hasResults,
  isMapShowing,
  news,
}) => {
  const navigate = useNavigate();

  const getEntities = () => {
    if (sectionList) {
      const entities = [];
      sectionList.map((section) =>
        entities.push(
          ...section.entities
            .filter((entity) => entity.location && entity.location.lon && entity.location.lat)
            .map((entity) => {
              entity.entityType = section.entityType;
              return entity;
            })
        )
      );
      return entities;
    }
    return [];
  };

  const [hoveredItem, setHoveredItem] = useState(null);

  const markerHoverHandler = (marker) => {
    setHoveredItem(marker);
  };

  const [hoveredEntity, setHoveredEntity] = useState(null);
  const entityHoverHandler = (entity) => {
    setHoveredEntity(entity);
  };

  const pageSelectHandler = (page) => {
    navigate(
      linkToSearch({
        entityType: searchParams.selectedEntity.name,
        input: searchParams.enteredInput,
        categoryNames: searchParams.selectedCategories.map((category) => category.category_name),
        tagNames: searchParams.selectedTags.map((tag) => tag.name),
        chainId: searchParams.selectedChainId,
        businessId: searchParams.selectedBusinessId,
        page,
        suggestOverride: true
      })
    );
    window.scrollTo(0, 0);
  };

  return hasResults ? (
    <div className={classes.mainContainer}>
      <div className={classes.resultsContainer}>
        <div className={classes.entitiesContainer}>
          {sectionList &&
            sectionList.length &&
            sectionsRender.renderSections(
              sectionList,
              isLoading,
              entitySelectHandler,
              hoveredItem,
              entityHoverHandler,
              searchParams.selectedChainId,
              news
            )}
          {searchParams.selectedEntity.name != ALL && hasResults && !isLoading && (
            <PageSelector
              currentPage={searchParams.currentPage}
              totalPages={totalPages}
              onPageSelected={pageSelectHandler}
            />
          )}
        </div>
        {isMapShowing && (
          <div className={classes.map}>
            <MapView
              center={userLocation && userLocation.coords ? userLocation.coords : null}
              zoom={14}
              markers={getEntities()}
              onMarkerHover={markerHoverHandler}
              hoveredEntity={hoveredEntity}
              isLoading={isLoading}
              showLocationRequest={true}
              showInfoWindow={true}
            />
          {config.currentCountry === "IL" && < AboutBanner />}
          </div>
        )}
      </div>
    </div>
  ) : (
    <NoSearchResults selectedEntity={searchParams.selectedEntity} searchResults={preparedResults} />
  );
};

export default WebSearchResults;
