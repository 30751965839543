import { t } from "i18next";
import Skeleton from "react-loading-skeleton";
import { BENEFITS } from "../../../../resources/config";
import { BenefitBanner, BusinessBanner } from "../../../Banners";
import EntityCarousel from "../EntityCarousel/EntityCarousel";
import { renderItemsByType } from "../entityRenderHelper";
import classes from "./EntityGrid.module.css";

const EntityGrid = ({ entityType, entities, isLoading, monthlyBenefits }) => {
  return (
    <div className={ classes.mainContainer }>
      { entityType === BENEFITS && process.env.REACT_APP_COUNTRY === "IL" && <BenefitBanner /> }
      { entityType === BENEFITS && monthlyBenefits && monthlyBenefits.length > 0 && (
        <EntityCarousel
          key={ "news" }
          title={ t("exclusive_monthly") }
          entities={ monthlyBenefits }
          entityType={ "news" }
          benefitType={ entityType }
          isLoading={ isLoading }
        />
      ) }

      { entityType === BENEFITS && isLoading && (
        <Skeleton height="2rem" width="20rem" borderRadius="20px" className={ classes.headerText } />
      ) }
      { entityType === BENEFITS && !isLoading && (
        <h4 className={ classes.headerText }>
          { monthlyBenefits && monthlyBenefits.length > 0 ? t("more_benefits") : t("benefits") }
        </h4>
      ) }

      <div className={ classes.gridContainer } data-testid='entityGridContainer' id='entityGridContainer'>
        { entities && renderItemsByType(entityType, entities, isLoading, classes.entityContainer) }
      </div>
    </div>
  );
};

export default EntityGrid;
