import classes from "./ContactUsModal.module.css";
import Modal from "react-bootstrap/Modal";
import ErrorableInput from "../../components/Common/Input/ErrorableInput";
import ErrorableDropDown from "../../components/Common/Input/ErrorableDropDown";
import { t } from "i18next";
import { PrimaryButton } from "../../components/Common/index";
import useInput from "../../hooks/use-input";
import useDropDown from "../../hooks/use-dropDown";
import ErrorableTextArea from "../../components/Common/Input/ErrorableTextArea";
import { useEffect } from "react";
import * as generalActions from "../../actions/generalActions";
import { getCurrentCountry, getCurrentLocale } from "../../utils/utilFunctions";
import icons, { iconsAlts } from "../../resources/icons";
import * as optionLists from "./contactOptionsConfig";

const ContactUsModal = ({ show, onHide, firstInfo, secondInfo, optionNumber, onSuccess }) => {
  const {
    value: enteredName,
    isValid: enteredNameIsValid,
    hasError: nameInputHasError,
    defaultValueHandler: nameDefaultHandler,
    valueChangeHandler: nameChangedHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetNameInput,
  } = useInput((value) => value.trim().length > 2);

  const validateEmail = (email) => {
    // Regular expression to check if string is email
    const regexExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    return regexExp.test(email);
  };

  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: emailInputHasError,
    defaultValueHandler: emailDefaultHandler,
    valueChangeHandler: emailChangedHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmailInput,
  } = useInput((value) => validateEmail(value));

  const {
    value: selectedOption,
    isValid: optionIsValid,
    hasError: optionHasError,
    optionSelectedHandler: optionSelectedHandler,
    inputBlurHandler: optionBlurHandler,
    reset: resetSelectedOption,
  } = useDropDown();

  const {
    value: enteredFirstInfo,
    isValid: enteredFirstInfoIsValid,
    hasError: firstInfoHasError,
    defaultValueHandler: firstInfoDefaultHandler,
    valueChangeHandler: firstInfoChangedHandler,
    inputBlurHandler: firstInfoBlurHandler,
    reset: resetFirstInfoInput,
  } = useInput((value) => value.trim().length > 2);

  const {
    value: enteredSecondInfo,
    isValid: enteredSecondInfoIsValid,
    hasError: secondInfoHasError,
    defaultValueHandler: secondInfoDefaultHandler,
    valueChangeHandler: secondInfoChangedHandler,
    inputBlurHandler: secondInfoBlurHandler,
    reset: resetSecondInfoInput,
  } = useInput(
    (value) => value.trim().length > 2 || (selectedOption && selectedOption.secondInfo === null)
  );

  const dropDownOptions = optionLists.getDropDownOptions();

  useEffect(() => {
    if (firstInfo) firstInfoDefaultHandler(firstInfo);
    if (secondInfo) secondInfoDefaultHandler(secondInfo);
    if (optionNumber) optionSelectedHandler(dropDownOptions[optionNumber - 1]);
  }, [optionNumber]);

  let formIsValid = false;

  if (
    enteredNameIsValid &&
    enteredEmailIsValid &&
    enteredFirstInfoIsValid &&
    (!selectedOption.secondInfo || enteredSecondInfoIsValid) &&
    optionIsValid
  ) {
    formIsValid = true;
  }

  const formSubmissionHandler = async (event) => {
    event.preventDefault();

    if (!formIsValid) {
      nameBlurHandler();
      emailBlurHandler();
      optionBlurHandler();
      firstInfoBlurHandler();
      secondInfoBlurHandler();

      return;
    }

    const result = await generalActions.sendContact(
      enteredName,
      enteredEmail,
      t(selectedOption.text),
      enteredFirstInfo,
      enteredSecondInfo
    );

    if (result.success) {
      onHideHandler();
      onSuccess(result.code);
    }
  };

  const onOptionSelectedHandler = (option) => {
    optionSelectedHandler(option);
    resetFirstInfoInput();
    resetSecondInfoInput();
  };

  const onHideHandler = () => {
    onHide();
    resetNameInput();
    resetEmailInput();
    resetFirstInfoInput();
    resetSecondInfoInput();
    resetSelectedOption();
  };

  return (
    <Modal
      show={show}
      contentClassName={classes.mainContainer}
      onHide={onHideHandler}
      size={"me"}
      centered
    >
      <div className={classes.contentContainer}>
        <img
          className={getCurrentLocale() === "he" ? classes.exitButtonIL : classes.exitButton}
          src={icons.closeXWhite}
          onClick={onHideHandler}
          icons={iconsAlts.closeXWhite}
        />
        <p className={classes.title}>{t("contact_us")}</p>
        <ErrorableDropDown
          id="contact-us-modal-dropdown"
          options={dropDownOptions}
          onOptionSelected={onOptionSelectedHandler}
          onBlur={optionBlurHandler}
          selectedOption={selectedOption}
          placeholder={t("subject")}
          hasError={optionHasError}
        />
        <ErrorableInput
          id="contact-us-modal-name"
          type="text"
          onChange={nameChangedHandler}
          onBlur={nameBlurHandler}
          value={enteredName}
          placeholder={t("full_name")}
          hasError={nameInputHasError}
          errorMessage={t("contact_us_name_error")}
        />
        <ErrorableInput
          id="contact-us-modal-email"
          type="text"
          onChange={emailChangedHandler}
          onBlur={emailBlurHandler}
          value={enteredEmail}
          placeholder={t("email_address")}
          hasError={emailInputHasError}
          errorMessage={t("contact_us_email_error")}
        />
        {selectedOption && selectedOption.firstInfo && (
          <ErrorableTextArea
            id="contact-us-modal-details"
            type="text"
            onChange={firstInfoChangedHandler}
            onBlur={firstInfoBlurHandler}
            value={enteredFirstInfo}
            placeholder={t(selectedOption.firstInfo)}
            hasError={firstInfoHasError}
            errorMessage={t("contact_us_info_error")}
          />
        )}
        {selectedOption && selectedOption.secondInfo && (
          <ErrorableTextArea
            id="contact-us-modal-additional-details"
            type="text"
            onChange={secondInfoChangedHandler}
            onBlur={secondInfoBlurHandler}
            value={enteredSecondInfo}
            placeholder={t(selectedOption.secondInfo)}
            hasError={secondInfoHasError}
            errorMessage={t("contact_us_info_error")}
          />
        )}
        <PrimaryButton
          id="contact-us-modal-submit"
          title={t("submit")}
          onClick={formSubmissionHandler}
        />
      </div>
    </Modal>
  );
};

export default ContactUsModal;
