import classes from "./EntityRow.module.css";
import { renderItemsByType } from "../entityRenderHelper";
import { t } from "i18next";
import PrimaryButton from "../../../../components/Common/PrimaryButton/PrimaryButton";
import { useNavigate } from "react-router-dom";
import * as links from "../../../../utils/links";

const EntityRow = ({
  entityType,
  entities,
  isLoading,
  onNavigateToEntity,
  hoveredItem,
  onEntityHover,
}) => {
  return (
    <div className={classes.mainContainer}>
      <div className={classes.background}></div>
      <div className={classes.headerContainer}>
        <h4 className={classes.headerText}>{t(entityType)}</h4>
      </div>
      <div className={`${classes.rowContainer} ${"hideScrollBars"}`}>
        {entities &&
          renderItemsByType(
            entityType,
            entities,
            isLoading,
            classes.entityContainer,
            hoveredItem,
            onEntityHover
          )}
      </div>
      {!isLoading && (
        <PrimaryButton
          title={t("see_all")}
          className={classes.seeAllBtn}
          onClick={() => onNavigateToEntity(entityType)}
          isLight={true}
          isSlim={true}
        />
      )}
    </div>
  );
};

export default EntityRow;
