import classes from "../Search.module.css";
import { EntityRow, EntityList, EntityGrid } from "../../../components/Entities/EntityContainers";

import { ALL, RESTAURANTS, RECIPES, BRANCHES, BENEFITS, BRANDS, BLOGS, EMPLOYERS } from "../../../resources/config";
import { isMobile } from "react-device-detect";

const ROW = "row";
const LIST = "list";
const GRID = "grid";

export const renderEmptySectionList = (selectedEntity) => {
  const restaurants = [{}, {}, {}, {}, {}, {}, {}, {}, {}];
  const recipes = [{}, {}, {}, {}, {}, {}, {}, {}, {}];
  const businesses = [{}, {}, {}, {}, {}, {}, {}, {}, {}];
  const benefits = [{}, {}, {}, {}, {}, {}, {}, {}, {}];
  const companies = [{}, {}, {}, {}, {}, {}, {}, {}, {}];
  const blogs = [{}, {}, {}, {}, {}, {}, {}, {}, {}];
  const employers = [{}, {}, {}, {}, {}, {}, {}, {}, {}];

  const emptyEntityList = {
    restaurants,
    recipes,
    businesses,
    benefits,
    companies,
    blogs,
    employers
  };
  const { sectionList } = preparePageSections(emptyEntityList, selectedEntity);
  return sectionList;
};

export const preparePageSections = (
  filteredResults,
  selectedEntity,
  currentPage = 1,
  pageSize = 9,
  isMobile
) => {
  switch (selectedEntity.name) {
    case ALL:
      return prepareAllTypeSections(filteredResults, selectedEntity);
    case BRANCHES:
    case RESTAURANTS:
      return prepareListTypeSection(filteredResults, selectedEntity, currentPage, pageSize);
    default:
      return prepareGridTypeSection(
        filteredResults,
        selectedEntity,
        currentPage,
        isMobile ? 999 : pageSize
      );
  }
};

const prepareGridTypeSection = (filteredResults, selectedEntity, currentPage, pageSize) => {
  const sectionList = [];
  let locationList = [];
  let entityList = [];
  let totalPages = 1;
  let totalResults = 0;
  let title = "";
  switch (selectedEntity.name) {
    case RECIPES:
      totalResults = filteredResults.recipes.length;
      totalPages = Math.ceil(filteredResults.recipes.length / pageSize);
      entityList = filteredResults.recipes.slice(
        (currentPage - 1) * pageSize,
        (currentPage - 1) * pageSize + pageSize
      );
      break;
    case BLOGS:
      totalResults = filteredResults.blogs.length;
      totalPages = Math.ceil(filteredResults.blogs.length / pageSize);
      entityList = filteredResults.blogs.slice(
        (currentPage - 1) * pageSize,
        (currentPage - 1) * pageSize + pageSize
      );
      break;
    case EMPLOYERS:
      totalResults = filteredResults.employers.length;
      totalPages = Math.ceil(filteredResults.employers.length / pageSize);
      entityList = filteredResults.employers.slice(
        (currentPage - 1) * pageSize,
        (currentPage - 1) * pageSize + pageSize
      );
      break;

    case BENEFITS:
      totalResults = filteredResults.benefits.length;
      totalPages = Math.ceil(filteredResults.benefits.length / pageSize);
      entityList = filteredResults.benefits.slice(
        (currentPage - 1) * pageSize,
        (currentPage - 1) * pageSize + pageSize
      );
      break;
    case BRANDS:
      totalResults = filteredResults.companies.length;
      totalPages = Math.ceil(filteredResults.companies.length / pageSize);
      entityList = filteredResults.companies.slice(
        (currentPage - 1) * pageSize,
        (currentPage - 1) * pageSize + pageSize
      );
      break;
    default:
      break;
  }
  sectionList.push({
    title: title,
    entityType: selectedEntity.name,
    entities: entityList,
    sectionType: GRID,
  });

  return { sectionList, locationList, totalResults, totalPages };
};

const prepareListTypeSection = (filteredResults, selectedEntity, currentPage, pageSize) => {
  const sectionList = [];
  let locationList = [];
  let entityList = [];
  let totalPages = 1;
  let totalResults = 0;

  switch (selectedEntity.name) {
    case BRANCHES:
      totalResults = filteredResults.businesses.length;
      totalPages = Math.ceil(filteredResults.businesses.length / pageSize);
      entityList = filteredResults.businesses.slice(
        (currentPage - 1) * pageSize,
        (currentPage - 1) * pageSize + pageSize
      );
      break;
    case RESTAURANTS:
      totalResults = filteredResults.restaurants.length;
      totalPages = Math.ceil(filteredResults.restaurants.length / pageSize);

      entityList = filteredResults.restaurants.slice(
        (currentPage - 1) * pageSize,
        (currentPage - 1) * pageSize + pageSize
      );
      break;
  }
  sectionList.push({
    title: selectedEntity.name,
    entityType: selectedEntity.name,
    entities: entityList,
    sectionType: LIST,
  });
  locationList = getLocationList(entityList);
  return { sectionList, locationList, totalResults, totalPages };
};

const prepareAllTypeSections = (filteredResults, selectedEntity) => {
  const sectionList = [];
  const dataList = [];
  let locationList = [];
  let restaurantIndex = 0;
  let totalResults = 0;

  dataList.push({
    listType: RESTAURANTS,
    entities: filteredResults.restaurants.slice(restaurantIndex, restaurantIndex + 3),
  });
  restaurantIndex += 3;
  const businesses = filteredResults.businesses.slice(0, 2);
  if (businesses.length > 0) {
    dataList.push({ listType: BRANCHES, entities: businesses });
    dataList.push({
      listType: RESTAURANTS,
      entities: filteredResults.restaurants.slice(restaurantIndex, restaurantIndex + 3),
    });
    restaurantIndex += 3;
  }

  const brands = filteredResults.companies.slice(0, 2);
  if (brands.length > 0) {
    dataList.push({ listType: BRANDS, entities: brands });
    dataList.push({
      listType: RESTAURANTS,
      entities: filteredResults.restaurants.slice(restaurantIndex, restaurantIndex + 3),
    });
    restaurantIndex += 3;
  }

  const employers = filteredResults.employers.slice(0, 2);
  if (employers.length > 0) {
    dataList.push({ listType: EMPLOYERS, entities: employers });
    dataList.push({
      listType: RESTAURANTS,
      entities: filteredResults.restaurants.slice(restaurantIndex, restaurantIndex + 3),
    });
    restaurantIndex += 3;
  }

  const recipes = filteredResults.recipes.slice(0, 2);
  if (recipes.length > 0) {
    dataList.push({ listType: RECIPES, entities: recipes });
    dataList.push({
      listType: RESTAURANTS,
      entities: filteredResults.restaurants.slice(restaurantIndex, restaurantIndex + 3),
    });
    restaurantIndex += 3;
  }
  const blogs = filteredResults.blogs.slice(0,2);
  if (blogs.length > 0) {
    dataList.push({ listType: BLOGS, entities: blogs });
    dataList.push({
      listType: RESTAURANTS,
      entities: filteredResults.restaurants.slice(restaurantIndex, restaurantIndex + 3),
    });
    restaurantIndex += 3;
  }
  if (dataList.length < 2) {
    dataList.push({
      listType: RESTAURANTS,
      entities: filteredResults.restaurants.slice(restaurantIndex, restaurantIndex + 9),
    });
  }
  dataList.map((list) => {
    locationList = locationList.concat(getLocationList(list.entities));
  });

  dataList.map((item) => {
    if (item.entities.length > 0 && item.listType === RESTAURANTS) {
      sectionList.push({
        title: "Restaurants",
        entityType: RESTAURANTS,
        entities: item.entities,
        sectionType: LIST,
      });
    } else if (
      item.entities.length > 0 &&
      (item.listType === EMPLOYERS || item.listType === BLOGS || item.listType === RECIPES || item.listType === BRANCHES || item.listType === BRANDS)
    ) {
      sectionList.push({
        title: item.listType,
        entityType: item.listType,
        entities: item.entities,
        sectionType: ROW,
      });
    }
  });

  sectionList.map((section) => {
    totalResults = totalResults + section.entities.length;
  });

  return { sectionList, locationList, totalResults };
};

const getLocationList = (entities) => {
  const list = entities
    .filter(
      (entity) =>
        entity.location != null && entity.location.lon != null && entity.location.lat != null
    )
    .map((entity) => entity.location);

  return list;
};

const getMonthlyBenefits = (news) => {
  return news
    ? news.filter(
      (news) =>
        news.categories && news.categories.some((category) => category.type === "monthly_benefit")
    )
    : [];
};

export const renderSections = (
  sectionList,
  isLoading,
  onNavigateToEntity,
  hoveredItem,
  onEntityHover,
  isChain,
  news
) => {
  return sectionList.map((section, index) => {
    switch (section.sectionType) {
      case LIST:
        return (
          <div className={classes.entityContainer} key={index}>
            {section.entities.length > 0 && (
              <EntityList
                entityType={section.entityType}
                entities={section.entities.slice(0, 100)}
                isLoading={isLoading}
                isNavigateToEntityEnabled={index != 0 && index == sectionList.length - 1}
                onNavigateToEntity={onNavigateToEntity}
                hoveredItem={hoveredItem}
                onEntityHover={onEntityHover}
                isChain={isChain}
              />
            )}
          </div>
        );
      case ROW:
        return (
          <div className={classes.entityContainer} key={index}>
            <EntityRow
              entityType={section.entityType}
              entities={section.entities}
              isLoading={isLoading}
              hoveredItem={hoveredItem}
              onNavigateToEntity={onNavigateToEntity}
              onEntityHover={onEntityHover}
            />
          </div>
        );
      case GRID:
        return (
          <div className={classes.entityContainer} key={index}>
            {section.entities.length > 0 && (
              <EntityGrid
                entityType={section.entityType}
                entities={section.entities}
                isLoading={isLoading}
                monthlyBenefits={getMonthlyBenefits(news)}
              />
            )}
          </div>
        );
    }
  });
};
