import classes from "./SearchInputMobile.module.css";
import GreenTag from "../../Common/Tags/GreenTag";
import EntityCategories from "../../Entities/EntityCategories/EntityCategories";
import { useEffect, useState } from "react";
import { ALL, RESTAURANTS, BRANCHES, RECIPES, BENEFITS } from "../../../resources/config";
import HomepageSearch from "../../HomePage/HomePageSearch/HomepageSearch";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import icons, { iconsAlts } from "../../../resources/icons";
import SearchFiltersModal from "../MobileSearch/SearchFiltersModal";
import { isMobile } from "react-device-detect";
import Tooltip from "../../Common/Tooltip/Tooltip";
import { checkEntityByCountry } from "../../../utils/utilFunctions";

const SearchInputMobile = ({
  suggestOverride,
  enteredInput,
  entitiesTypeList,
  selectedEntity,
  onEntitySelected,
  categoriesList,
  selectedCategories,
  onCategorySelected,
  tagsList,
  selectedTags,
  onTagSelected,
  userLocation,
  onLocationClick,
  isLoading,
  hasResults,
  clearFilters,
  className,
  isEntitiesShowing,
  onResultsOpen,
  openBottomSheet,
  resultsNumber,
}) => {
  const [isFiltersModalOpen, setFilterModalOpen] = useState(false);

  const isTagsShowing = () => {
    if (selectedEntity.name === ALL) {
      return false;
    }
    if (
      selectedEntity.name == BRANCHES ||
      selectedEntity.name == RESTAURANTS ||
      selectedEntity.name == BENEFITS ||
      isMobile
    ) {
      return true;
    }
    return false;
  };


  return (
    <div className={`${classes.mainContainer} ${className}`}>
      <div className={classes.searchBarContainer}>
        <HomepageSearch
          suggestOverride={suggestOverride}
          searchBarClassName={classes.searchBar}
          selectedEntity={selectedEntity}
          enteredInput={enteredInput}
          userLocation={userLocation}
          isFromSearchPage={true}
          placeholder={t("search")}
          onResultsOpen={onResultsOpen}
          onResultsClickExtraAction={openBottomSheet}
        />
      </div>
      {userLocation && (
        <div className={classes.locationContainer}>
          <button
            type="button"
            className={classes.locationButton}
            onClick={onLocationClick}
            id={"locationContainer"}
          >
            <img
              className={classes.locationEditBtn}
              src={icons.locationGreen}
              alt={iconsAlts.locationGreen}
            />
            <p className={classes.locationName}>
              {t("current_location")} {t(userLocation.address)}
            </p>
          </button>
          <Tooltip />
        </div>
      )}
      {isEntitiesShowing && (
        <div className={`${classes.entitiesContainer} ${"hideScrollBars"}`}>
          {entitiesTypeList &&
            entitiesTypeList.
            filter(e => checkEntityByCountry(e.name)).
            map((entity) => (
              <GreenTag
                key={entity.name}
                tag={entity}
                icon={entity.icon}
                className={classes.entityTags}
                tagName={t(entity.label) + (" (" + entity.count + ")")}
                isSelected={entity.name === selectedEntity.name}
                isDisabled={!entity.count}
                onTagSelected={(entity) => {
                  onEntitySelected(entity.name);
                }}
              />
            ))}
        </div>
      )}
      {!isLoading && isTagsShowing() && tagsList && hasResults && (
        <div className={`${classes.tagsContainer} ${"hideScrollBars"}`}>
          <button
            className={classes.filtersButton}
            type="button"
            onClick={() => setFilterModalOpen(true)}
          >
            <p className={classes.filtersText}>
              {t("apply_filters")}{" "}
              <img
                style={{ paddingInlineStart: "0.5rem" }}
                src={icons.greyArrowDown}
                alt={iconsAlts.greenArrowDown}
              />
            </p>
            {selectedCategories.length + selectedTags.length > 0 && (
              <p className={classes.filtersNumber}>
                {selectedCategories.length + selectedTags.length}
              </p>
            )}
          </button>

          <div className={classes.separator}>.</div>
          {tagsList.map((tag) => (
            <GreenTag
              key={tag.name}
              tag={tag}
              tagName={t(tag.label) + (" (" + (tag.count ? tag.count : 0) + ")")}
              isSelected={selectedTags.find((t) => t.name === tag.name) != undefined}
              icon={tag.icon}
              iconSelected={tag.iconSelected}
              isDisabled={!tag.count}
              onTagSelected={(tag) => {
                onTagSelected(tag);
              }}
              isHollow={true}
            />
          ))}
        </div>
      )}
      <SearchFiltersModal
        show={isFiltersModalOpen}
        onHide={() => setFilterModalOpen(false)}
        entitiesTypeList={entitiesTypeList}
        selectedEntity={selectedEntity}
        onEntitySelected={onEntitySelected}
        categoriesList={categoriesList}
        selectedCategories={selectedCategories}
        onCategorySelected={onCategorySelected}
        tagsList={tagsList}
        selectedTags={selectedTags}
        onTagSelected={onTagSelected}
        isLoading={isLoading}
        hasResults={hasResults}
        clearFilters={clearFilters}
        resultsNumber={resultsNumber}
      />
    </div>
  );
};

export default SearchInputMobile;
