import classes from "./EntityHeader.module.css";
import { t } from "i18next";
import { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import icons, { iconsAlts } from "../../../resources/icons";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import * as links from "../../../utils/links";
import { BLOGS, EMPLOYERS, BRANDS, RECIPES } from "../../../resources/config";
import { EntityPictures } from "../index";
import GalleryModal from "../../../modals/GalleryModal/GalleryModal";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import { RWebShare } from "react-web-share";
import { PrimaryButton } from "../../Common";
import { getIconByRating } from "../../../utils/entityUtils/reviewUtils";

const EntityHeader = ({
  entityType,
  title,
  thumbnail,
  rating,
  numOfReviews,
  categories,
  coverImages,
  className,
  isLoading,
  componentToPrint,
  author
}) => {
  const navigation = useNavigate();
  const [isGalleryOpen, setGalleryOpen] = useState(false);
  const [selectedImage, setSelectedImages] = useState(0);

  const categoryClickHandler = (category) => {
    navigation(
      links.linkToSearch({
        entityType: entityType,
        categoryNames: [category.category_name],
        suggestOverride: true
      })
    );
  };

  const imageClickHandler = (index) => {
    setGalleryOpen(true);
    setSelectedImages(index);
  };


  return (
    <div className={`${classes.mainContainer} ${className}`}>
      <section className={classes.topContainer}>
        {isLoading ? (
          <Skeleton className={classes.thumbnail} />
        ) : (
          thumbnail && <img className={classes.thumbnail} src={thumbnail} alt="logo" />
        )}
        <div className={classes.infoContainer}>
          <div className={classes.row}>

            <h1 className={classes.title}>
              {isLoading ? <Skeleton height="100%" width="15rem" borderRadius="20px" /> : title}
            </h1>
          </div>
          {isLoading ? (
            <Skeleton
              height="1.5rem"
              width="25rem"
              borderRadius="20px"
              style={{ marginBottom: "2rem" }}
            />
          ) : (
            <h2 className={classes.ratingRow}>
              {rating > 0 && (
                <div className={classes.row}>
                  <img
                    className={classes.smile}
                    src={getIconByRating(rating, true)}
                    alt="rating icon"
                  />
                  <p className={classes.rating}>{rating}</p>
                  <p className={classes.dot}>•</p>
                  <p className={classes.reviews}>
                    {numOfReviews} {t("reviews")}
                  </p>
                </div>
              )}
              {author &&
                <div className={classes.row}>
                  {author.link && author.name ? <a target="_blank" href={author.link} className={classes.rating}>{author.name}</a>
                    : <p className={classes.rating}>{author.name}</p>}
                  {author.date && author.name && <p className={classes.space}>{' | '}</p>}
                  {author.date && <p className={classes.rating}>{author.date}</p>}

                </div>
              }
              {categories && categories.length > 0 && author && <p className={classes.space}>{' | '}</p>}
              {categories && categories.length > 0 && (
                <div className={classes.row}>
                  {rating > 0 && <p className={classes.categoryDot}>•</p>}
                  <div className={classes.categoriesContainer}>
                    {categories.map((category, index) => (
                      <div className={classes.row} key={index}>
                        <a className={classes.tag} onClick={() => categoryClickHandler(category)}>
                          {category.category_name}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </h2>
          )}
          

        </div>
        {!isLoading && (
            <div className={classes.shareRow}>
              <ReactToPrint
                trigger={() => {
                  // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                  // to the root node of the returned component as it will be overwritten.
                  return (
                    <a href="#">
                      <img
                        className={classes.printButton}
                        src={icons.printButton}
                        alt={iconsAlts.printButton}
                      />
                    </a>
                  );
                }}
                content={componentToPrint}
              />
              <PrimaryButton
                title={t("share")}
                isLight={true}
                isSlim={true}
                iconLeft={icons.shareIcon}
                className={classes.shareButton}
                onClick={() =>
                  navigator.share({
                    url: window.location.href,
                    text: "Vegan Friendly",
                    title: "Vegan Friendly",
                  })
                }
              />
            </div>
          )}
      </section>

      {((entityType !== BLOGS && 
        entityType !== EMPLOYERS && 
          entityType !== BRANDS &&
          entityType !== RECIPES) || coverImages.length > 0) && <EntityPictures
        coverImages={coverImages}
        onImageClick={imageClickHandler}
        isLoading={isLoading}
      />}
      <GalleryModal
        show={isGalleryOpen}
        onHide={() => {
          setSelectedImages(0);
          setGalleryOpen(false);
        }}
        selectedIndex={selectedImage}
        coverImages={coverImages}
      />
    </div>
  );
};

export default EntityHeader;
