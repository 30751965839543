import React, { useEffect } from 'react';
import './AccessibilityStatement.module.css'; // Add your CSS file for styling

const AccessibilityStatement = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="accessibility-statement">
      <Introduction />
      <AccessibilityImplementation />
      <AccessibilityOptions />
      <ThirdPartyComponents />
      <Feedback />
      <LastUpdated date="27/03/2024" />
    </main>
  );
};

const Introduction = () => (
  <section>
    <h2>מבוא</h2>
    <p>האינטרנט מהווה כיום את המאגר הגדול ביותר לחופש המידע עבור כלל המשתמשים, ומשתמשים בעלי מוגבלויות בפרט. ככזה, אנו שמים חשיבות רבה במתן אפשרות שווה לאנשים עם מוגבלות לשימוש במידע המוצג באתר, ולאפשר חווית גלישה טובה יותר.</p>
    <p>אנו שואפים להבטיח כי השירותים הדיגיטליים יהיו נגישים לאנשים עם מוגבלויות, ועל כן הושקעו משאבים רבים להקל את השימוש באתר עבור אנשים עם מוגבלויות, ככל האפשר, מתוך אמונה כי לכל אדם מגיעה הזכות לחיות בשוויון, כבוד, נוחות ועצמאות.</p>
    <p>כדי לממש הבטחה זו, אנו שואפים לדבוק ככל האפשר בהמלצות התקן הישראלי (ת"י 5568) לנגישות תכנים באינטרנט ברמת AA ומסמך WCAG2.1 הבינלאומי.</p>
  </section>
);

const AccessibilityImplementation = () => (
  <section>
    <h2>כיצד עובדת ההנגשה באתר?</h2>
    <p>באתר מוצב תפריט הנגשה של חברת הנגשת אתרים - <a href="https://www.nagich.co.il/" target="_blank" rel="noopener noreferrer">נגיש בקליק</a>.  לחיצה על כפתור הנגישות מאפשרת את פתיחת התפריט המכיל כפתורי ההנגשה. לאחר בחירת נושא בתפריט יש להמתין לטעינת הדף.</p>
    <p>התוכנה פועלת בדפדפנים הפופולריים: Chrome, Firefox, Safari, Opera. אחריות השימוש והיישום באתר חלה על בעלי האתר ו/או מי מטעמו לרבות התוכן המוצג באתר בכפוף למגבלות ולתנאי השימוש בתוכנה.</p>
  </section>
);

const AccessibilityOptions = () => (
  <section>
    <h2>אפשרויות הנגישות בתפריט:</h2>
    <ul>
      <li>התאמה לניווט מקלדת - מתן אפשרות לניווט על ידי מקלדת</li>
      <li>התאמה לקורא מסך - התאמר האתר עבור טכנולוגיות מסייעות כגון NVDA, JAWS</li>
      <li>הגדלת פונט האתר ל־5 גדלים שונים</li>
      <li>חסימת הבהובים - עצירת אלמנטים נעים וחסימת הבהובים</li>
      <li>התאמת ניגודיות - שינוי ניגודיות צבעים על בסיס רקע כהה</li>
      <li>התאמת ניגודיות - שינוי ניגודיות צבעים על בסיס רקע בהיר</li>
      <li>התאמת אתר לעיוורי צבעים</li>
      <li>שינוי הפונט לקריא יותר</li>
      <li>הגדלת הסמן ושינוי צבעו לשחור או לבן</li>
      <li>הגדלת התצוגה לכ־200%</li>
      <li>הדגשת קישורים באתר</li>
      <li>הדגשת כותרות באתר</li>
      <li>הצגת תיאור אלטרנטיבי לתמונות</li>
      <li>הצהרת נגישות</li>
      <li>שליחת משוב נגישות</li>
    </ul>
  </section>
);

const ThirdPartyComponents = () => (
  <section>
    <h2>שימוש ברכיבים ואתרי צד שלישי:</h2>
    <p>כאשר נעשה שימוש ברכיבים או אתרי אינטרנט של צד שלישי באתר כגון פייסבוק, אינסטגרם, יוטיוב, טוויטר, צ`אטים חיצוניים ואחרים שאינם בשליטתנו, ייתכן והדבר יציב אתגרים בשימוש באתר בידי אנשים בעלי מוגבלויות שאין ביכולתנו לתקן.</p>
  </section>
);

const Feedback = () => (
  <section>
    <h2>הבהרה</h2>
    <p>חרף מאמצנו לאפשר גלישה באתר נגיש עבור כל דפי האתר, יתכן ויתגלו דפים באתר שטרם הונגשו, או שטרם נמצא הפתרון הטכנולוגי המתאים. אנו ממשיכים במאמצים לשפר את נגישות האתר, ככל האפשר, וזאת מתוך אמונה ומחויבות מוסרית לאפשר שימוש באתר לכלל האוכלוסייה לרבות אנשים עם מוגבלויות.</p>
    <p>אם במהלך הגלישה באתר נתקלתם בקושי בנושא נגישות, צוות הנגישות של החברה עומד לרשותכם במגוון ערוצים לפנייה בנושאי נגישות, נשמח לקבל מכם משוב.</p>
    <p>מייל: info@vegan-friendly.com</p>
    <p>טלפון: 03-947-4404</p>
  </section>
);

const LastUpdated = ({ date }) => (
  <section>
    <h2>תאריך עדכון אחרון</h2>
    <p>{ date }</p>
  </section>
);

export default AccessibilityStatement;
