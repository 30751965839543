import Lottie from "lottie-react";
import circleLoader from "../../assets/lottie/circle-loader.json";
import classes from "./Loader.module.css";

const Loader = () => {
  return (
    <>
      <Lottie className={classes.animation} animationData={circleLoader} loop={true} />
    </>
  );
};

export default Loader;
