import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { L_PERMISSIONS_DENIED, L_PERMISSIONS_REQUESTED } from "../../../actions/userActions";
import icons, { iconsAlts } from "../../../resources/icons";
import classes from "./Tooltip.module.css";

const Tooltip = ({}) => {
  const hasLocationPermission = useSelector((state) => state.user.hasLocationPermissions);
  const locationError = useSelector((state) => state.user.locationError);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isLocationRequested, setLocationRequested] = useState(false);

  useEffect(() => {
    if (hasLocationPermission === L_PERMISSIONS_REQUESTED) {
      setLocationRequested(true);
    }
    if (hasLocationPermission === L_PERMISSIONS_DENIED && isLocationRequested) {
      setLocationRequested(false);
      startToolTipTimer();
    }
  }, [hasLocationPermission, isLocationRequested]);

  const startToolTipTimer = () => {
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 4200);
  };

  return (
    <div
      className={showTooltip ? classes.tooltip : classes.tooltipHidden}
      onClick={() => setShowTooltip(false)}
    >
      <p className={classes.tooltip_text}>
        {locationError}
        <img className={classes.exit_icon} src={icons.closeXWhite} alt={iconsAlts.closeXWhite} />
      </p>
    </div>
  );
};

export default Tooltip;
