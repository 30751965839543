import classes from "./BenefitModal.module.css";
import Modal from "react-bootstrap/Modal";
import { PrimaryButton } from "../../components/Common/index";
import images from "../../resources/images";
import { BENEFITS, BRANCHES, RESTAURANTS, showVeganActive } from "../../resources/config";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import * as links from "../../utils/links";
import icons, { iconsAlts } from "../../resources/icons";
import { getCurrentLocale } from "../../utils/utilFunctions";
import { useEffect } from "react";
import { logEvents } from "../../eventsManager";

const BenefitModal = ({ show, onHide, benefit }) => {
  const navigate = useNavigate();
  const entity =
    benefit && benefit.entity_type
      ? benefit.entity_type === RESTAURANTS
        ? benefit.restaurant
        : benefit.branch
      : null;

  const getBenefitEventsProps = () => {
    return {
      business_id: benefit.id,
      business_type: BENEFITS,
      business_name: benefit.description,
      business_source_id: entity.id,
      business_source_name: entity.name,
      business_source_type: benefit.entity_type,
    };
  };

  useEffect(() => {
    if (entity && show) {
      const props = getBenefitEventsProps();
      logEvents("screen_business_open", props);
    }
  }, [show]);

  const benefitClickHandler = () => {
    if (entity) {
      switch (benefit.entity_type) {
        case RESTAURANTS:
          navigate(links.linkToRestaurant(entity.id, entity.name, entity.location));
          break;
        case BRANCHES:
          navigate(links.linkToBusiness(entity.id, entity.name, entity.location, entity.is_online));
          break;
      }
    }
  };

  return (
    <Modal
      show={show}
      contentClassName={classes.mainContainer}
      onHide={onHide}
      size={"me"}
      centered
    >
      {benefit && entity && (
        <div className={classes.contentContainer}>
          <img
            className={getCurrentLocale() === "he" ? classes.exitButtonIL : classes.exitButton}
            src={icons.closeXWhite}
            onClick={onHide}
            alt={iconsAlts.closeXWhite}
          />
          <p className={classes.mainTitle}>{t("benefit_modal_title")}</p>
          <div className={classes.benefitItemContainer} onClick={benefitClickHandler}>
            <img className={classes.benefitItemIcon} src={entity.logo} alt="benefit icon" />
            <div>
              <p className={classes.benefitItemTitle}>{entity.name}</p>
              <p className={classes.benefitItemDescription}>{benefit.description}</p>
            </div>
            <div className={classes.benefitItemMark}>{t("benefit")}</div>
          </div>
          <p className={classes.subTitle}>{t("benefit_modal_subtitle")}</p>
          {showVeganActive && (
            <PrimaryButton
              className={classes.buttonJoinVF}
              title={t("benefit_join_vegan_active")}
              isSlim={true}
              onClick={() => links.linkToExternalPage(links.URLS.vegan_active)}
            />
          )}
          <PrimaryButton
            className={classes.button}
            title={t("benefit_download_app")}
            isTranslucent={showVeganActive}
            isSlim={true}
            onClick={() => links.linkToExternalPage(links.URLS.download_app)}
          />
          <PrimaryButton
            className={classes.button}
            title={t("benefit_show_all")}
            removeBackground={true}
            isSlim={true}
            onClick={() => {
              navigate(links.linkToSearch({ entityType: BENEFITS }));
              onHide();
            }}
          />
        </div>
      )}
    </Modal>
  );
};

export default BenefitModal;
