import classes from "./AboutUsHeader.module.css";
import { t } from "i18next";

const AboutUsHeader = () => {
  return (
    <div className={classes.mainContainer}>
      <div className={classes.contentContainer}>
        <h1 className={classes.title}>{t("about_us_header_title")}</h1>
        <h2 className={classes.subtitle}>{t("about_us_header_subtitle")}</h2>
      </div>
    </div>
  );
};

export default AboutUsHeader;
