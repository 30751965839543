import icons from "../../../resources/icons";
import { entitiesUtils } from "../../../utils/entityUtils";
import { BLOGS, EMPLOYERS, BRANCHES, BRANDS, RECIPES, RESTAURANTS, s3 } from "../../../resources/config";
import { checkEntityByCountry } from "../../../utils/utilFunctions";
import { restaurantSearchNames } from "../../../resources/entities/entitySearchNames";

export const cleanSearchNames = (input) => {
  if(!input) return [];

  // Remove special characters, then split by comma or space
  var words = input.replace(/[^\w\s,א-ת]/gi, '').split(/[\s,]+/);
  // Filter out empty strings to avoid empty array elements
  return words.filter(word => word.length > 0);
}

export const prepareSearchEntities = (
  searchEntities,
  entityCategories,
  entityTags,
  userLocation
) => {

  return [
    ...prepareRestaurantResults(searchEntities, entityCategories, userLocation),
    ...prepareBusinessesResults(searchEntities, entityCategories, userLocation),
    ...prepareRecipeResults(searchEntities, entityCategories, userLocation),
    ...prepareCompaniesResults(searchEntities, entityCategories, userLocation),
    ...prepareBlogsResults(searchEntities, entityCategories, userLocation),
    ...prepareEmployersResults(searchEntities, entityCategories, userLocation),
    ...prepareTags(entityTags),
  ];
};

const prepareRestaurantResults = (searchEntities, entityCategories, userLocation) => {
  if (checkEntityByCountry(RESTAURANTS)) {
    return [
      ...searchEntities.chains.map((c) => ({
        id: c.id,
        chain_name: c.name,
        entity_type: "chain",
        search_names: cleanSearchNames(c.search_names),
        categories: c.rest_categories,
        logo: c.logo ? s3 + c.logo : icons.restaurantsEntity,
      })),
      ...searchEntities.restaurants.map((r) => ({
        id: r.id,
        restaurant_name: r.name,
        address: r.address,
        search_names: cleanSearchNames(r.search_names),
        entity_type: "restaurant",
        categories: r.rest_categories,
        logo: r.logo ? s3 + r.logo : icons.restaurantsEntity,
        distance: userLocation
          ? entitiesUtils.calculateDistance(r.location, userLocation.coords)
          : 0,
        location: r.location,
      })),
      ...entityCategories.restaurants.map((r) => ({
        id: r.id,
        restaurant_category: r.category_name,
        entity_type: "restaurant_category",
        logo: icons.restaurantsEntity,
      })),
    ];
  }
  return [];
};

const prepareBusinessesResults = (searchEntities, entityCategories, userLocation) => {
  if (checkEntityByCountry(BRANCHES)) {
    return [
      ...searchEntities.businesses.map((b) => ({
        id: b.id,
        business_name: b.name,
        entity_type: "business",
        categories: b.business_subcats.map((c) => c.name),
        categories_search_names: b.business_subcats.map((c) => c.search_names),
        additional_search_names: b.business_categories.map((c) => c.search_names),
        logo: b.logo ? s3 + b.logo : icons.businessesEntity,
      })),
      ...searchEntities.business_branches.map((b) => ({
        id: b.id,
        branch_name: b.name,
        address: b.address,
        entity_type: "branch",
        categories: b.business_subcats.map((c) => c.name),
        categories_search_names: b.business_subcats.map((c) => c.search_names),
        additional_search_names: b.business_categories.map((c) => c.search_names),
        logo: b.logo ? s3 + b.logo : icons.businessesEntity,
        location: b.location,
        is_online: b.is_online,
        distance: userLocation
          ? entitiesUtils.calculateDistance(b.location, userLocation.coords)
          : 0,
      })),
      ...entityCategories.businessesSubs.map((b) => ({
        id: b.id,
        business_category: b.category_name,
        entity_type: "business_category",
        logo: icons.businessesEntity,
      })),
    ];
  }
  return [];
};

const prepareRecipeResults = (searchEntities, entityCategories, userLocation) => {
  if (checkEntityByCountry(RECIPES)) {
    return [
      ...searchEntities.recipes.map((r) => ({
        id: r.id,
        recipe_name: r.name,
        entity_type: "recipe",
        logo: r.logo ? r.logo : icons.recipesEntity,
      })),
      ,
      ...entityCategories.recipes.map((r) => ({
        id: r.id,
        recipe_category: r.category_name,
        entity_type: "recipe_category",
        logo: icons.recipesEntity,
      })),
    ];
  }
  return [];
};

const prepareCompaniesResults = (searchEntities, entityCategories, userLocation) => {
  if (checkEntityByCountry(BRANDS)) {
    return [
      ...searchEntities.companies.map((c) => ({
        id: c.id,
        company_name: c.name,
        entity_type: "company",
        logo: c.logo ? s3 + c.logo : icons.productsEntity,
      })),
      ,
      ...entityCategories.companiesSubs.map((r) => ({
        id: r.id,
        company_category: r.category_name,
        entity_type: "company_category",
        logo: icons.productsEntity,
      })),
    ];
  }
  return [];
};

const prepareBlogsResults = (searchEntities, entityCategories, userLocation) => {
  if (checkEntityByCountry(BLOGS)) {
    return [
      ...searchEntities.blogs.map((c) => ({
        id: c.id,
        blog_name: c.name,
        entity_type: "blog",
        slug: c.slug,
        logo: c.logo ? s3 + c.logo : icons.blogsEntity
      })),
      ,
      ...entityCategories.blogs.map((r) => ({
        id: r.id,
        blog_category: r.category_name,
        entity_type: "blog_category",
        logo: icons.blogsEntity
      })),
    ];
  }
  return [];
};

const prepareEmployersResults = (searchEntities, entityCategories, userLocation) => {
  if (checkEntityByCountry(EMPLOYERS)) {
    return [
      ...searchEntities.employers.map((c) => ({
        id: c.id,
        employer_name: c.name,
        entity_type: "employer",
        logo: c.logo ? s3 + c.logo : icons.employersEntity
      })),
      ,
      ...entityCategories.employers.map((r) => ({
        id: r.id,
        employer_category: r.category_name,
        entity_type: "employer_category",
        logo: icons.employersEntity
      })),
    ];
  }
  return [];
};




const prepareTags = (entityTags) => {
  return entityTags.map((t) => ({
    id: t.id,
    tag_name: t.name,
    entity_type: "tag",
  }));
};
