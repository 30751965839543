import { SET_FULL_RECIPE } from "../actions/recipeActions";

const defaultState = {
  fullRecipe: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_FULL_RECIPE:
      return { ...state, fullRecipe: action.payload };
  }
  return state;
};
