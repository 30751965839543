import React, { useState } from "react";
import classes from "./DropDown.module.css";
import icons, { iconsAlts } from "../../../resources/icons";

const DropDown = ({ buttonComponent, innerComponent, className, header }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropDownClass = isOpen ? classes.dropdownContentOpen : classes.dropdownContent;

  return (
    <div
      className={classes.dropdown}
      onMouseOver={() => setIsOpen(true)}
      onMouseOut={() => setIsOpen(false)}
    >
      {buttonComponent}
      <div className={`${dropDownClass} ${className}`}>
        <div className={classes.headerContainer}>
          <h5 className={classes.title}>{header}</h5>
          <img src={icons.closeX} onClick={() => setIsOpen(false)} alt={iconsAlts.closeX} />
        </div>
        {innerComponent(() => setIsOpen(false))}
      </div>
    </div>
  );
};

export default DropDown;
