import { RESTAURANTS, BRANCHES } from "../../resources/config";
import * as entitiesUtils from "./entitiesUtils";

export function prepareBenefit(benefit, userLocation) {
  const preparedBenefit = JSON.parse(JSON.stringify(benefit));

  preparedBenefit.entity_type = benefit.restaurant ? RESTAURANTS : BRANCHES;

  if (preparedBenefit.entity_type === RESTAURANTS) {
    preparedBenefit.distance = entitiesUtils.calculateDistance(
      benefit.restaurant.location,
      userLocation
    );
  } else {
    preparedBenefit.distance = entitiesUtils.calculateDistance(
      benefit.branch.location,
      userLocation
    );
  }

  preparedBenefit.has_delivery =
    benefit.discount_type &&
    benefit.discount_type !== "regular" &&
    benefit.discount_type !== "sitdown";
  return preparedBenefit;
}

export const prepareBenefitsList = (restaurantsList = null, branchesList = null) => {
  const benefits = [];
  if (restaurantsList) {
    restaurantsList.map((restaurant) => {
      if (
        restaurant.is_certified &&
        restaurant.discount_list &&
        restaurant.discount_list.length > 0
      )
        restaurant.discount_list.map((discount) => {
          benefits.push({
            ...discount,
            distance: restaurant.distance,
            entity_type: RESTAURANTS,
            has_delivery:
              discount.discount_type &&
              discount.discount_type !== "regular" &&
              discount.discount_type !== "sitdown",
            restaurant: {
              id: restaurant.id,
              name: restaurant.name,
              address: restaurant.address,
              categories: restaurant.rest_categories.map((c) => ({
                ...c,
                id: "R" + c.id,
              })),
              tags: restaurant.tags,
              logo: restaurant.logo,
            },
          });
        });
    });
  }
  if (branchesList) {
    branchesList.map((branch) => {
      if (branch.is_certified && branch.discount_list && branch.discount_list.length > 0)
        branch.discount_list.map((discount, index) => {
          benefits.push({
            ...discount,
            distance: branch.distance,
            entity_type: BRANCHES,
            has_delivery:
              discount.discount_type &&
              discount.discount_type !== "regular" &&
              discount.discount_type !== "sitdown",
            branch: {
              id: branch.branch_id,
              name: branch.name,
              address: branch.address,
              categories: branch.business_subcats.map((c) => ({
                ...c,
                id: "B" + c.id,
              })),
              tags: branch.tags,
              distance: branch.distance,
              logo: branch.logo,
            },
          });
        });
    });
  }

  return benefits;
};
