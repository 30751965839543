import { Container } from "react-bootstrap";
import classes from "./PrimaryButton.module.css";

const PrimaryButton = ({
  id,
  title,
  iconLeft,
  onClick,
  className,
  fontSize,
  textPadding,
  isSlim = false,
  isLight = false,
  isTranslucent = false,
  removeBackground = false,
}) => {
  const mainContainerStyle = removeBackground
    ? classes.mainContainerNoBackground
    : isTranslucent
    ? classes.mainContainerTranslucent
    : isLight
    ? classes.mainContainerLight
    : classes.mainContainer;

  return (
    <button
      type="button"
      id={id}
      className={`${mainContainerStyle} ${className}`}
      onClick={onClick}
    >
      {iconLeft && <img className={classes.icon} src={iconLeft} />}
      <p
        className={`${classes.text} ${
          isLight || isTranslucent || removeBackground ? classes.textLight : ""
        } ${isSlim ? classes.textSlim : ""}`}
        style={{
          fontSize: fontSize + "rem",
          margin: textPadding + "rem",
        }}
      >
        {title}
      </p>
    </button>
  );
};

export default PrimaryButton;
