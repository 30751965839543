import classes from "./ErrorableInput.module.css";

const ErrorableInput = ({
  id,
  type,
  onChange,
  onBlur,
  value,
  placeholder,
  hasError,
  errorMessage,
  inputClass,
}) => {
  // className={
  //   hasError
  //     ? inputError
  //       ? inputError
  //       : classes.inputError
  //     : inputClass
  //     ? inputClass
  //     : classes.input
  // }
  return (
    <div className={classes.inputContainer}>
      <input
        id={id}
        onChange={onChange}
        onBlur={onBlur}
        value={value ? value : ""}
        placeholder={placeholder}
        className={`${inputClass ? inputClass : classes.input} ${
          hasError ? classes.inputError : ""
        }`}
      />
      {hasError && <p className={classes.errorMessage}>{errorMessage}</p>}
    </div>
  );
};

export default ErrorableInput;
