import { useState } from "react";

const Image = ({ className, src, alt }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoaded = (a) => {
    setIsLoaded(true);
  };

  return (
    <>
      <img className={className} src={src} alt={alt} onLoad={handleImageLoaded} />
      {!isLoaded && (
        <div
          className={className}
          style={{ position: "absolute", backgroundColor: "#f3f4fb", borderRadius: "5px" }}
        />
      )}
    </>
  );
};

export default Image;
