import { DropDown } from "../../Common";
import logo from "../../../assets/images/logo.png";
import classes from "../VfNavBar.module.css";
import { t } from "i18next";
import icons, { iconsAlts } from "../../../resources/icons";
import config from "../../../config";
import { RESTAURANTS } from "../../../resources/config";
import * as links from "../../../utils/links";
import { useNavigate } from "react-router";

const NavBarWeb = ({
  homepageHandler,
  getPrimaryButtonByCountry,
  getInvolvedContent,
  getEntitiesContent,
  navigateToAboutUs,
  setSearchModalOpen,
  getInvolvedStyle,
  logClickEvent,
}) => {
  const navigate = useNavigate();
  const isUS = config.currentCountry === "US";

  const navigateToRestaurants = () => {
    logClickEvent("restaurants");
    navigate(links.linkToSearch({ entityType: RESTAURANTS }));
  };

  return (
    <>
      <div className={classes.webContainer}>
        <div className={classes.leftContainer}>
          <>
            <img
              src={logo}
              className={classes.veganLogo}
              onClick={homepageHandler}
              alt={iconsAlts.veganLogo}
            />
          </>
          {
            <DropDown
              buttonComponent={
                <button type="button" className={classes.getInvolvedContainer}>
                  <p className={classes.getInvolved}>{t("entities")}</p>
                  <img
                    className={classes.getInvolvedIcon}
                    src={icons.greyArrowDown}
                    alt={iconsAlts.greenArrowDown}
                  />
                </button>
              }
              innerComponent={getEntitiesContent}
            />
          }
          <DropDown
            buttonComponent={
              <button type="button" className={classes.getInvolvedContainer}>
                <p className={classes.getInvolved}>{t("get_involved")}</p>
                <img
                  className={classes.getInvolvedIcon}
                  src={icons.greyArrowDown}
                  alt={iconsAlts.greenArrowDown}
                />
              </button>
            }
            innerComponent={getInvolvedContent}
          />

          <button type="button" className={classes.aboutUs} onClick={navigateToAboutUs}>
            {t("about_us")}
          </button>
        </div>
        <div className={classes.rightContainer}>
          {getPrimaryButtonByCountry()}
          <button className={classes.searchContainer} onClick={() => setSearchModalOpen(true)}>
            <img src={icons.searchButtonIcon} alt={iconsAlts.searchButtonIcon} />
          </button>
        </div>
      </div>
    </>
  );
};

export default NavBarWeb;
