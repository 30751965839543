import { useEffect, useState } from "react";
import GreenTag from "../../Common/Tags/GreenTag";
import classes from "./EntityCategories.module.css";
import icons, { iconsAlts } from "../../../resources/icons";
import { t } from "i18next";

const EntityCategories = ({
  categories,
  selectedCategories,
  onCategorySelected,
  shouldDisableByCount = false,
  shouldHideUnpopular = false,
  className,
}) => {
  const [showAll, setShowAll] = useState(!shouldHideUnpopular);

  const seeMoreHandler = () => {
    setShowAll((showAll) => !showAll);
  };


  return (
    <div className={`${classes.mainContainer}`}>
      <div
        className={`${
          showAll ? classes.categoriesContainerOpen : classes.categoriesContainer
        } ${className} `}
      >
        {categories.map((e, index) => (
          <GreenTag
            key={`${e.category_name}:${e.id}`}
            tag={e}
            tagName={e.category_name + (" (" + (e.count > 0 ? e.count : 0) + ")")}
            isSelected={
              selectedCategories && selectedCategories.find((c) => e.id === c.id) != undefined
            }
            onTagSelected={() => onCategorySelected(e)}
            isDisabled={shouldDisableByCount && !e.count}
            style={{
              marginInline: 2,
              marginBlock: 3,
            }}
          />
        ))}
      </div>
      {shouldHideUnpopular && (
        <button type="button" className={classes.moreButtonContainer} onClick={seeMoreHandler}>
          <p className={classes.moreButtonText}>{showAll ? t("less") : t("more")}</p>
          <img
            className={showAll ? classes.moreButtonIconSelected : classes.moreButtonIcon}
            src={icons.greenArrowDown}
            alt={iconsAlts.greenArrowDown}
          />
        </button>
      )}
    </div>
  );
};
export default EntityCategories;
