import { useEffect, useState } from "react";
import classes from "./LocationSearch.module.css";
import * as userActions from "../../actions/userActions";
import { useDispatch } from "react-redux";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { t } from "i18next";
import icons, { iconsAlts } from "../../resources/icons";
import { getCurrentCountry } from "../../utils/utilFunctions";
import { json } from "react-router-dom";
import { logEvents } from "../../eventsManager";

const LocationSearch = ({ onLocationChosen }) => {
  const dispatch = useDispatch();
  const [address, setAddress] = useState("");
  const [additionalSuggestions, setAdditionalSuggestions] = useState([]);

  useEffect(() => {
    getAdditionalSuggestions();
  }, []);

  const getAdditionalSuggestions = (input) => {
    const currentCountry = getCurrentCountry();
    let countryPlaces;
    if (currentCountry === "IL") {
      countryPlaces = centerPlaces.IL;
    } else if (currentCountry === "GB") {
      countryPlaces = centerPlaces.GB;
    } else {
      countryPlaces = centerPlaces.US;
    }

    let recentSearches = JSON.parse(localStorage.getItem("recent_locations_" + currentCountry));
    recentSearches = recentSearches ? recentSearches : [];
    if (input && input.length > 0) {
      countryPlaces = countryPlaces.filter((i) =>
        i.address.toLowerCase().includes(input.toLowerCase())
      );
      recentSearches = recentSearches.filter((i) =>
        i.address.toLowerCase().includes(input.toLowerCase())
      );
    }

    setAdditionalSuggestions([...countryPlaces, ...recentSearches]);
  };

  const additionalHandler = (suggestion) => {
    setAdditionalSuggestions([]);
    userActions.setSelectedLocation(
      dispatch,
      suggestion.country,
      suggestion.state,
      suggestion.address,
      suggestion.latLng
    );

    logEvents("location_click", { address: suggestion.address, is_recent: true });
    onLocationChosen(suggestion.address);
  };

  const nearbyHandler = async () => {
    const result = await userActions.getPreciseLocation(dispatch);
    onLocationChosen();
  };

  const handleChange = (address) => {
    getAdditionalSuggestions(address);
    setAddress(address);
  };

  const handleSelect = async (address) => {
    try {
      setAdditionalSuggestions([]);
      const results = await geocodeByAddress(address);
      const currentAddress = results[0];
      //getting country
      const countryComponent = currentAddress.address_components.find(
        (c) => c.types && c.types[0] === "country"
      );
      const currentCountry = countryComponent ? countryComponent.short_name : null;

      //getting state
      let currentState = null;
      if (currentCountry === "US") {
        const stateComponent = currentAddress.address_components.find(
          (c) => c.types && c.types[0] === "administrative_area_level_1"
        );
        currentState = stateComponent ? stateComponent.short_name : null;
      }

      //getting lat lan
      const latLng = await getLatLng(currentAddress);

      saveToRecentLocations({
        country: currentCountry,
        state: currentState,
        address,
        latLng: latLng,
      });
      userActions.setSelectedLocation(dispatch, currentCountry, currentState, address, latLng);

      logEvents("location_click", { address });
      onLocationChosen();
    } catch (ex) {
      console.log("ERROR IN LOCATION !!!! " + ex);
    }
  };

  const saveToRecentLocations = (newItem) => {
    const currentCountry = getCurrentCountry();
    let recentLocations = JSON.parse(localStorage.getItem("recent_locations_" + currentCountry));
    recentLocations = recentLocations ? recentLocations : [];

    if (recentLocations.length == 10) {
      recentLocations.pop();
    }

    recentLocations = recentLocations.filter(
      (item) => JSON.stringify(item) != JSON.stringify(newItem)
    );

    recentLocations.unshift(newItem);

    localStorage.setItem("recent_locations_" + currentCountry, JSON.stringify(recentLocations));
  };

  const centerPlaces = {
    IL: [
      {
        country: "IL",
        state: null,
        address: "Tel Aviv, Israel",
        latLng: { lat: 32.0852999, lng: 34.78176759999999 },
      },
      {
        country: "IL",
        state: null,
        address: "Haifa, Israel",
        latLng: { lat: 32.7940463, lng: 34.989571 },
      },
    ],
    GB: [
      {
        country: "GB",
        state: null,
        address: "London, UK",
        latLng: { lat: 51.5072178, lng: -0.1275862 },
      },
    ],
    US: [
      {
        country: "US",
        state: "NY",
        address: "NYC, NY, USA",
        latLng: { lat: 40.7127753, lng: -74.0059728 },
      },
    ],
  };

  return (
    <div className={classes.mainContainer}>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        onError={() => console.log("no results")}
        searchOptions={{
          componentRestrictions: { country: getCurrentCountry() },
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={classes.inputContainer}>
            <input
              {...getInputProps({
                placeholder: t("search_placeholder"),
                className: classes.input,
              })}
              onClick={getAdditionalSuggestions}
            />
            <div className={classes.resultsContainer}>
              <div className={classes.result} onClick={nearbyHandler}>
                <img src={icons.locationGrey} alt={iconsAlts.locationGrey} />
                <p className={classes.resultName}>{t("search_nearby")}</p>
              </div>
              {additionalSuggestions &&
                additionalSuggestions.map((suggestion) => (
                  <div key={suggestion.address} className={classes.result} onClick={() => additionalHandler(suggestion)}>
                    <img src={icons.clockGrey} al={iconsAlts.clockGrey} />
                    <p className={classes.resultName}>
                      {suggestion.address ? suggestion.address : t("search_nearby")}
                    </p>
                  </div>
                ))}
              {suggestions.map((suggestion) => {
                const className = suggestion.active ? classes.resultActive : classes.result;
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <img src={icons.locationGrey} alt={iconsAlts.locationGrey} />
                    <p className={classes.resultName}>{suggestion.description}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default LocationSearch;
