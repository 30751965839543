import classes from "./ProductCatalog.module.css";
import { CustomText } from "../../Common";
import { useState }  from "react";
import React from "react";
import { t } from "i18next";

const ProductItem = ({ product }) => {

    const [ingredientsExpanded, setIngredientsExpanded] = useState(false);
    const isLtrLocale = process.env.country !== "IL";

    const toggleIngredients = () => {
        setIngredientsExpanded(!ingredientsExpanded);
    };

    return (
        <div className={classes.dishContainer} onClick={toggleIngredients}>
            <div className={classes.dishHeaderContainer}>
                <h5 className={classes.dishName}>{product.name}</h5>
            </div>
            <div className={classes.dishDescriptionContainer}>
                <p className={classes.dishDescription}>{product.description}</p>
                <div style={{
                    transform: ingredientsExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                    display: 'inline-block',
                    marginLeft: '5px'
                }}>▼</div><p>{t("ingredients")}</p>
            </div>
            {ingredientsExpanded && (
                <div style={{marginTop: "2rem" }}>
                    {product?.ingredient_list?.length > 0 && (
                            product.ingredient_list.map((ingredient, ingredientIndex) => (
                                <React.Fragment key={ingredientIndex}>
                                    <span className={ingredient.subingredients && ingredient.subingredients.length > 0 ? classes.ingredientTextBold : classes.ingredientTextRegular}>
                                        {ingredient.name}
                                    {ingredient.subingredients && ingredient.subingredients.length > 0 && (
                                        <span className={classes.subingredientText}>
                                            {' ('}
                                            {ingredient.subingredients.map((sub, subIndex) => (
                                                <span key={subIndex}>
                                                    {(subIndex > 0 ? ', ' : '') + sub.name}
                                                    {sub.sub_subingredients && (
                                                        <span className={"subsubingredient-text"}>
                                                            {' (' + sub.sub_subingredients + ')'}
                                                        </span>
                                                    )}
                                                </span>
                                            ))}
                                            {')'}
                                        </span>
                                    )}
                                    {ingredientIndex < product.ingredient_list.length - 1 ? ', ' : '.'}
                                    </span>
                                </React.Fragment>
                            ))
                    )}
                </div>
            )}
        </div>
    );
}
 export default ProductItem