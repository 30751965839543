import classes from "./BlogsItem.module.css";
import { useNavigate } from "react-router-dom";
import * as navigationLinks from "../../../utils/links";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import images from "../../../resources/images";

const BlogsItem = ({ data, style, isLoading = true }) => {
  const blog = data;
  const navigate = useNavigate();

  const onBlogClick = () => {
    navigate(navigationLinks.linkToBlog(data.slug));
  };

  return (
    <div className={`${style} ${classes.mainContainer} `} onClick={onBlogClick}>
      <div className={classes.imageContainer}>
        {isLoading ? (
          <Skeleton
            height="100%"
            style={{
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
            }}
          />
        ) : (
          <img
            className={classes.image}
            src={blog.logo ? blog.logo : images.defaultEntityLogo}
            alt="blog picture"
          />
        )}
      </div>
      <div className={classes.contentContainer}>
        <p className={classes.blogTitle}>
          {isLoading ? <Skeleton borderRadius="20px" width="80%" /> : blog.name}
        </p>
        <p className={classes.blogDescription}>{blog.description ? blog.description : blog.content ? blog.content.slice(0, 100) : ''}</p>
        {blog.blog_categories && (
          <div className={classes.tagContainer}>
            {blog.blog_categories.map((category, index, row) => (
              <p className={classes.tag} key={category.category_name}>
                {category.category_name}
                {index !== row.length - 1 ? "," : ""}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogsItem;
