import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { L_PERMISSIONS_DENIED } from "../../../actions/userActions";
import config from "../../../config";
import { ALL, BENEFITS, BLOGS, BRANCHES, BRANDS, EMPLOYERS, RECIPES, RESTAURANTS } from "../../../resources/config";
import icons, { iconsAlts } from "../../../resources/icons";
import { checkEntityByCountry } from "../../../utils/utilFunctions";
import GreenTag from "../../Common/Tags/GreenTag";
import Tooltip from "../../Common/Tooltip/Tooltip";
import EntityCategories from "../../Entities/EntityCategories/EntityCategories";
import HomepageSearch from "../../HomePage/HomePageSearch/HomepageSearch";
import classes from "./SearchInputWeb.module.css";

const SearchInputWeb = ({
  suggestOverride,
  enteredInput,
  entitiesTypeList,
  selectedEntity,
  onEntitySelected,
  categoriesList,
  selectedCategories,
  onCategorySelected,
  tagsList,
  selectedTags,
  onTagSelected,
  userLocation,
  onLocationClick,
  isLoading,
  hasResults,
  clearFilters,
  isEntitiesShowing,
  resultsNumber,
}) => {
  const navigate = useNavigate();
  const [displayedCategories, setDisplayedCategories] = useState([]);

  useEffect(() => {
    if (selectedEntity && categoriesList) {
      updateDisplayedCategories(selectedEntity.name);
    }
  }, [selectedEntity, categoriesList]);

  const updateDisplayedCategories = (type) => {
    switch (type) {
      case BRANCHES:
        setDisplayedCategories(categoriesList.businessesSubs.filter((c) => c.show));
        break;
      case RESTAURANTS:
        setDisplayedCategories(categoriesList.restaurants.filter((c) => c.show));
        break;
      case RECIPES:
        setDisplayedCategories(categoriesList.recipes);
        break;
      case BENEFITS:
        setDisplayedCategories(categoriesList.benefits.filter((c) => c.show));
        break;
      case BRANDS:
        setDisplayedCategories(categoriesList.companiesSubs);
        break;
      case BLOGS:
        setDisplayedCategories(categoriesList.blogs);
        break;
      case EMPLOYERS:
        setDisplayedCategories(categoriesList.employers);
        break;

      default:
        setDisplayedCategories([]);
    }
  };

  const isTagsShowing = () => {
    if (
      selectedEntity.name == BRANCHES ||
      selectedEntity.name == RESTAURANTS ||
      selectedEntity.name == BENEFITS
    ) {
      return true;
    }
    return false;
  };

  const showResultsNumber = config.currentCountry === "US";

  return (
    <div className={ classes.mainContainer }>
      <div className={ classes.searchBarContainer }>
        <HomepageSearch
          suggestOverride={ suggestOverride }
          searchBarClassName={ classes.searchBar }
          selectedEntity={ selectedEntity }
          enteredInput={ enteredInput }
          userLocation={ userLocation }
          isFromSearchPage={ true }
          placeholder={ t("search") }
        />
      </div>
      { userLocation && (
        <div className={ classes.locationContainer }>
          <button
            type="button"
            className={ classes.locationButton }
            onClick={ onLocationClick }
            id={ "locationContainer" }
          >
            <img
              className={ classes.locationEditBtn }
              src={ icons.locationGreen }
              alt={ iconsAlts.locationGreen }
            />
            <p className={ classes.locationName }>
              { t("current_location") } { t(userLocation.address) }
            </p>
          </button>
          <Tooltip />
        </div>
      ) }
      { isEntitiesShowing && (
        <div className={ classes.entitiesContainer }>
          { entitiesTypeList &&
            entitiesTypeList.filter(e => checkEntityByCountry(e.name)).map((entity) => (
              <GreenTag
                key={ entity.name }
                tag={ entity }
                icon={ entity.icon }
                className={ classes.entityTags }
                tagName={ t(entity.label) + (" (" + entity.count + ")") }
                isSelected={ entity.name === selectedEntity.name }
                isDisabled={ false }
                // isDisabled={!entity.count}
                onTagSelected={ (entity) => {
                  onEntitySelected(entity.name);
                } }
              />
            )) }
        </div>
      ) }
      { !isLoading && isTagsShowing() && tagsList && hasResults && (
        <>
          <p className={ classes.tagHeader }>{ t("filters") }</p>
          <div className={ classes.tagsContainer } id="searchInputWeb_tagsContainer">
            { tagsList.map((tag) => (
              <div className={ classes.entityContainer } >
                <GreenTag
                  key={ tag.name }
                  tag={ tag }
                  tagName={ t(tag.label) + (" (" + (tag.count ? tag.count : 0) + ")") }
                  isSelected={ selectedTags.find((t) => t.name === tag.name) != undefined }
                  icon={ tag.icon }
                  iconSelected={ tag.iconSelected }
                  isDisabled={ !tag.count }
                  onTagSelected={ (tag) => {
                    onTagSelected(tag);
                  } }
                  isHollow={ true }
                />
                {/* <div className={classes.entityToolTip}>{t(tag.info)}</div> */ }
              </div>
            )) }
          </div>
        </>
      ) }
      { !isLoading && displayedCategories.length > 0 && hasResults && (
        <div id="searchInputWeb_entityCategories">
          <p className={ classes.tagHeader }>{ t("categories") }</p>
          <EntityCategories
            categories={ displayedCategories }
            selectedCategories={ selectedCategories }
            onCategorySelected={ onCategorySelected }
            shouldDisableByCount={ true }
            shouldHideUnpopular={ true }
            className={ classes.categoriesContainer }
          />
        </div>
      ) }

      { !isLoading && (selectedCategories.length > 0 || selectedTags.length > 0) && hasResults && (
        <div className={ classes.selectedCategoriesContainer }>
          <button type="button" className={ classes.clearAllButton } onClick={ clearFilters }>
            { t("clear_all") }
          </button>
          { selectedCategories.map((e) => (
            <GreenTag
              key={ `${e.category_name}:${e.id}` }
              tag={ e }
              isSelected={ true }
              tagName={ e.category_name }
              iconEnd={ icons.closeXWhite }
              onTagSelected={ () => onCategorySelected(e) }
              style={ {
                marginInline: 2,
                marginBlock: 3,
              } }
            />
          )) }
          { selectedTags.map((tag) => (
            <GreenTag
              key={ tag.name }
              tag={ tag }
              tagName={ t(tag.label) }
              isSelected={ true }
              iconEnd={ icons.closeXWhite }
              icon={ tag.icon }
              iconSelected={ tag.iconSelected }
              onTagSelected={ (tag) => {
                onTagSelected(tag);
              } }
              style={ {
                marginInline: 2,
                marginBlock: 3,
              } }
            />
          )) }
        </div>
      ) }

    </div>
  );
};

export default SearchInputWeb;
