import { t } from "i18next";
import { BrowserView, MobileView } from "react-device-detect";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { NEWS, RESTAURANTS } from "../../../../resources/config";
import icons, { iconsAlts } from "../../../../resources/icons";
import * as links from "../../../../utils/links";
import { getCurrentLocale } from "../../../../utils/utilFunctions";
import SeeMoreItem from "../../EntityGridItems/SeeMoreItem";
import { renderItemsByType } from "../entityRenderHelper";
import classes from "./EntityCarousel.module.css";

const responsive = {
  largeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 5000, min: 1800 },
    items: 4,
    slidesToSlide: 4,
  },
  desktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 1800, min: 1200 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1200, min: 600 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const EntityCarousel = ({
  title,
  entityType = RESTAURANTS,
  benefitType,
  entities,
  isLoading,
  enableShowMore = true,
}) => {

  const navigation = useNavigate();

  const CustomRightArrow = ({ onClick }) => {
    return (
      <button className={ classes.arrowButtonRight } onClick={ () => onClick() }>
        <img
          className={ classes.arrowIcon }
          src={ icons.whiteArrowRight }
          alt={ iconsAlts.whiteArrowRight }
        />
      </button>
    );
  };

  const CustomLeftArrow = ({ onClick }) => {
    return (
      <button className={ classes.arrowButtonLeft } onClick={ () => onClick() }>
        <img
          className={ classes.arrowIcon }
          src={ icons.whiteArrowLeft }
          alt={ iconsAlts.whiteArrowDown }
        />
      </button>
    );
  };

  const getCounselStyle = () => {
    const locale = getCurrentLocale();
    return locale === "he" ? classes.carouselContainerHe : classes.carouselContainerEn;
  };

  const getContainerStyle = () => {
    const locale = getCurrentLocale();
    return locale === "he" ? classes.entityContainerHe : classes.entityContainerEn;
  };

  const seeAllClickHandler = () => {
    if (benefitType) {
      navigation(links.linkToSearch({ entityType, tagNames: [benefitType] }));
    } else {
      navigation(links.linkToSearch({ entityType }));
    }
  };

  const addSeeMoreItem = enableShowMore && entityType !== NEWS;

  const getCarouselItems = (isMobile) => {
    if (addSeeMoreItem) {
      return [
        ...renderItemsByType(
          entityType,
          isLoading ? [{}, {}, {}, {}] : entities,
          isLoading,
          isMobile ? classes.entityContainerEn : getContainerStyle()
        ),
        <SeeMoreItem
          key="see_more"
          entityType={ entityType }
          style={ isMobile ? classes.carouselContainerEn : getContainerStyle() }
          onItemClick={ seeAllClickHandler }
        />,
      ];
    } else {
      return [
        ...renderItemsByType(
          entityType,
          isLoading ? [{}, {}, {}, {}] : entities,
          isLoading,
          isMobile ? classes.entityContainerEn : getContainerStyle()
        ),
      ];
    }
  };

  return (
    <div className={ classes.mainContainer }>
      { (entities || isLoading) && (
        <div>
          <div className={ classes.headerContainer }>
            { isLoading && <Skeleton height="2rem" width="20rem" borderRadius="20px" /> }
            { !isLoading && <h2 className={ classes.headerText }>{ title }</h2> }
            { enableShowMore && !isLoading && entityType !== NEWS && (
              <button type="button" className={ classes.headerButton } onClick={ seeAllClickHandler }>
                { t("view_all") }
                { t(entityType).toLowerCase() }
              </button>
            ) }
          </div>
          <BrowserView>
            <div className={ classes.webContainer } id='webContainer_EntityCarousel'>
              <Carousel
                customRightArrow={ <CustomRightArrow /> }
                customLeftArrow={ <CustomLeftArrow /> }
                responsive={ responsive }
                infinite={ false }
                className={ getCounselStyle() }
              >
                { getCarouselItems(false) }
              </Carousel>
            </div>
          </BrowserView>
          <MobileView>
            <div className={ `${classes.mobileContainer} ${"hideScrollBars"}` }>
              { getCarouselItems(true) }
            </div>
          </MobileView>
        </div>
      ) }
    </div>
  );
};

export default EntityCarousel;
