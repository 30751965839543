import icons from "../../resources/icons";
import * as entitiesUtils from "./entitiesUtils";
import { t } from "i18next";

export function prepareReview(review, reviewTags) {
  //Restaurant categories
  review.reviewer_name = decodeURI(review.reviewer_name);
  review.description = decodeURI(review.description);
  if (reviewTags && reviewTags.length > 0) {
    review.tags = entitiesUtils.prepareFullTags(review.hash_tags, reviewTags);
  }
}

export const getTextByRating = (rating) => {
  const ratingNum = Math.ceil(rating);
  switch (ratingNum) {
    case 1:
      return t("poor");
    case 2:
      return t("mah");
    case 3:
      return t("good");
    case 4:
      return t("very_good");
    case 5:
      return t("excellent");
  }
  return null;
};

export const getIconByRating = (rating, isGreen = false) => {
  const ratingNum = Math.ceil(rating);
  switch (ratingNum) {
    case 1:
      return isGreen ? icons.facePoorGreen : icons.facePoor;
    case 2:
      return isGreen ? icons.faceMahGreen : icons.faceMah;
    case 3:
      return isGreen ? icons.faceGoodGreen : icons.faceGood;
    case 4:
      return isGreen ? icons.faceVeryGoodGreen : icons.faceVeryGood;
    case 5:
      return isGreen ? icons.faceExcellentGreen : icons.faceExcellent;
  }
  return null;
};
