import { getCurrentLocale } from "./utilFunctions";

export const formatDateMonthLong = (date) => {
  const locale = getCurrentLocale();

  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleString(locale === "he" ? "he-IL" : "en-GB", options);
};

export const formatDateNumeric = (date) => {
  const locale = getCurrentLocale();

  const d = new Date(date);
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  return d.toLocaleString(locale === "he" ? "he-IL" : "en-GB", options);
};
