import { t } from "i18next";
import PropTypes from "prop-types";
import MediaButtons from "../../../Media/MediaButtons/MediaButtons";
import classes from "./BusinessSocial.module.css";

const BusinessSocial = ({
  className,
  websiteLink,
  restaurantName,
  instagramLink,
  facebookLink,
  linkedinLink,
}) => {
  const getClickableLink = (link) => {
    if (link) {
      return link.startsWith("http://") || link.startsWith("https://") || link.startsWith("//")
        ? link
        : `http://${link}`;
    }
    return link;
  };

  return websiteLink || instagramLink || facebookLink || linkedinLink ? (
    <div className={ `${classes.mainContainer} ${className} ` } id='social_container'>
      <div className={ classes.topDivider } />
      { websiteLink && (
        <a className={ classes.websiteLink } href={ getClickableLink(websiteLink) } target="_blank" rel="noreferrer">
          { restaurantName } { t("website") }
        </a>
      ) }
      { (instagramLink || facebookLink || linkedinLink) && (
        <MediaButtons
          className={ classes.mediaButtons }
          text={ t("socials_of") + " " + restaurantName }
          textColor={ `var(--color-text)` }
          instagramLink={ getClickableLink(instagramLink) }
          facebookLink={ getClickableLink(facebookLink) }
          linkedinLink={ getClickableLink(linkedinLink) }
        />
      ) }
    </div>
  ) : (
    <></>
  );
};

BusinessSocial.propTypes = {
  restaurantName: PropTypes.string,
  websiteLink: PropTypes.string,
  instagramLink: PropTypes.string,
  facebookLink: PropTypes.string,
  linkedinLink: PropTypes.string,
};

export default BusinessSocial;
