import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as entitiesActions from "../../actions/entitiesActions";
import * as userActions from "../../actions/userActions";
import classes from "./Search.module.css";

import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SearchInputMobile, SearchInputWeb } from "../../components/Search";
import { ENTITY_TAGS } from "../../resources/entities/entityTags";
import { resultsFilter, resultsPreparation, resultsSort, sectionsRender } from "./SearchHelpers";

import { useRef } from "react";
import { isBrowser, isMobile } from "react-device-detect";
import "react-loading-skeleton/dist/skeleton.css";
import { SearchPageTypes } from "../../App";
import MobileSearchResults from "../../components/Search/MobileSearch/MobileSearchResults";
import WebSearchResults from "../../components/Search/WebSearchResults/WebSearchResults";
import config from "../../config";
import { logEvents } from "../../eventsManager";
import LocationSearchModal from "../../modals/LocationSearch/LocationSearchModal";
import {
  ALL,
  BENEFITS,
  BLOGS,
  BRANCHES,
  BRANDS,
  EMPLOYERS,
  RECIPES,
  RESTAURANTS,
  VF_ENTITIES_TYPE,
  getVFEntityByName,
  searchTermKeys,
} from "../../resources/config";
import icons from "../../resources/icons";
import { linkToSearch } from "../../utils/links";

const Search = ({ type, entity, region }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { filterName, city, chainId, busId, suggestOverride } = useParams();
  let [urlParams] = useSearchParams();
  const [isLoading, setLoading] = useState(true);

  const isSearchResultRequested = useSelector((state) => state.entities.isSearchResultRequested);
  const searchResults = useSelector((state) => state.entities.searchResults);
  const userLocation = useSelector((state) => state.user.userLocation);
  const entityCategories = useSelector((state) => state.entities.entityCategories);
  const news = useSelector((state) => state.entities.newsEntities);
  const entityTags = useSelector((state) => state.entities.entityTags);

  const isEntitiesShowing = () => {
    return true;
    // const currentCountry = process.env.REACT_APP_COUNTRY;
    // return currentCountry !== "US" ;
  };

  const getEmptySearchParams = () => {
    return {
      selectedEntity: isEntitiesShowing() ? VF_ENTITIES_TYPE[0] : VF_ENTITIES_TYPE[1],
      enteredInput: null,
      selectedTags: [],
      selectedCategories: [],
      selectedChainId: null,
      selectedBusinessId: null,
      currentPage: 1,
      suggestOverride: false
    };
  };

  const [searchParams, setSearchParams] = useState(getEmptySearchParams());

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [urlParams]);

  useEffect(() => {
    setSectionList(sectionsRender.renderEmptySectionList(searchParams.selectedEntity));
    if (!isSearchResultRequested && !searchResults && userLocation) {
      entitiesActions.getSearchResults(dispatch, userLocation);
    }
  }, [userLocation]);

  const [preparedResults, setPreparedResults] = useState(null);
  const [entityFuses, setEntityFuses] = useState(null);
  const [sectionList, setSectionList] = useState(null);

  const pageSize = isBrowser ? 24 : 25;
  const [totalPages, setTotalPages] = useState(1);
  const [hasResults, setHasResults] = useState(true);

  const [unhandledCategoryParams, setUnhandledCategoryParams] = useState(null);

  const [entityLocations, setEntityLocations] = useState(null);
  const [counterEntities, setCountedEntities] = useState(null);
  const [countedCategories, setCountedCategories] = useState(null);
  const [countedTags, setCountedTags] = useState(null);

  const isEntitiesLoading = useSelector((state) => state.entities.isLoading);

  useEffect(() => {
    if (isEntitiesLoading) {

      setSectionList(sectionsRender.renderEmptySectionList(searchParams.selectedEntity));
      setPreparedResults(null);
      setLoading(true);
    }
  }, [isEntitiesLoading]);

  //_____________________________________________________________________________//
  /// 1 . Handling URL params

  useEffect(() => {
    var newSearchParams = getEmptySearchParams();
    switch (type) {
      case SearchPageTypes.SEARCH:
        handlerRegularSearchParams(newSearchParams);
        break;
      case SearchPageTypes.ALIAS_ENTITY:
        newSearchParams.selectedEntity = getVFEntityByName(entity);
        if (filterName) {
          // first search for category, if not found then search for tag
          const entityObject = getVFEntityByName(entity);
          newSearchParams.selectedEntity = entityObject;
          const categories = handleSelectedCategoryByParams(
            [filterName],
            entityCategories,
            entityObject
          );
          if (categories && categories.length > 0) {
            newSearchParams.selectedCategories = categories;
          } else {
            const tags = handleSelectedTagByParams([filterName], entityObject);
            newSearchParams.selectedTags = tags;
          }
        }
        break;
      case SearchPageTypes.ALIAS_BUSINESS:
        newSearchParams.selectedEntity = getVFEntityByName(BRANCHES);
        newSearchParams.selectedBusinessId = busId;
        break;
      case SearchPageTypes.ALIAS_CHAIN:
        newSearchParams.selectedEntity = getVFEntityByName(RESTAURANTS);
        newSearchParams.selectedChainId = chainId;
        break;
      default:
        newSearchParams.suggestOverride = suggestOverride
        break;
    }
    setSearchParams(newSearchParams);
    logSelectedFilters(newSearchParams);
    setSectionList(sectionsRender.renderEmptySectionList(newSearchParams.selectedEntity));
  }, [urlParams]);


  const handlerRegularSearchParams = (newSearchParams) => {
    //Getting params from page url
    const entityParams = getVFEntityByName(urlParams.get("entity"));
    newSearchParams.selectedEntity = entityParams;
    const categoryParams = urlParams.get("categories");
    const categoriesArray = categoryParams ? categoryParams.split("-") : [];
    const categories = handleSelectedCategoryByParams(
      categoriesArray,
      entityCategories,
      entityParams
    );
    newSearchParams.selectedCategories = categories;

    let tagsParams = urlParams.get("tags");
    const tagsArray = tagsParams ? tagsParams.split("-") : [];
    const tags = handleSelectedTagByParams(tagsArray, entityParams);


    newSearchParams.selectedTags = tags;

    const inputParam = urlParams.get("input");

    newSearchParams.enteredInput = inputParam ? inputParam : "";

    const chainId = urlParams.get("chainId");
    newSearchParams.selectedChainId = chainId;

    const businessId = urlParams.get("businessId");
    newSearchParams.selectedBusinessId = businessId;

    const page = urlParams.get("page");
    newSearchParams.currentPage = page ? page : 1;

    const suggestOverride = urlParams.get("suggestOverride");
    newSearchParams.suggestOverride = suggestOverride;
  };

  const logSelectedFilters = (searchParams) => {
    const params = {
      entity: searchParams.selectedEntity.name,
      query_text: searchParams.enteredInput,
      tags: searchParams.selectedTags.map((t) => t.name),
      categories: searchParams.selectedCategories.map((c) => c.category_name),
    };
    logEvents("search_results_displayed", params);
  };

  //_____________________________________________________________________________//
  /// All data is received from the server
  /// Preparing Search Result + Preparing Entities Fuses + call for search results

  useEffect(() => {
    // All data is received from the server !!
    if (
      userLocation &&
      userLocation.coords &&
      searchResults &&
      entityCategories &&
      entityTags &&
      !isEntitiesLoading
    ) {
      if (unhandledCategoryParams) {
        const entityParams = entity
          ? getVFEntityByName(entity)
          : getVFEntityByName(urlParams.get("entity"));
        const categories = handleSelectedCategoryByParams(
          unhandledCategoryParams,
          entityCategories,
          entityParams
        );
        searchParams.selectedCategories = categories;
      }
      prepareSearchResults();
    }
  }, [searchResults, entityCategories, entityTags, userLocation, isEntitiesLoading]);

  const prepareSearchResults = () => {
    let preparedResults = JSON.parse(JSON.stringify(searchResults));
    resultsPreparation.prepareSearchResults(
      preparedResults,
      entityCategories,
      entityTags,
      userLocation.coords
    );
    setPreparedResults(preparedResults);
    const fuses = resultsSort.prepareEntitiesFuses(preparedResults);

    setEntityFuses(fuses);
  };

  //_____________________________________________________________________________//
  /// Searching by Params

  useEffect(() => {
    if (preparedResults && entityFuses && !isEntitiesLoading) {
      search();
    }
  }, [preparedResults, entityFuses, searchParams, isEntitiesLoading]);

  const search = () => {
    const sortedResults = { ...preparedResults };
    let cleanEnteredInput = searchParams.enteredInput;
    for (let key of Object.keys(searchTermKeys)) {
      if (cleanEnteredInput.includes(key)) {
        cleanEnteredInput = cleanEnteredInput.replace(key, "");
      }
    };
    const suggestedEntityName = resultsSort.sortResults(entityFuses, sortedResults, cleanEnteredInput);

    let filteredResults = { ...sortedResults };
    const { categoriesCount, tagsCount } = resultsFilter.filterSearchResults(
      filteredResults,
      searchParams.selectedEntity,
      entityCategories,
      searchParams.selectedCategories,
      searchParams.selectedTags,
      searchParams.selectedChainId,
      searchParams.selectedBusinessId

    );
    setCountedEntities(getEntitiesTypeList(filteredResults));
    setCountedCategories(categoriesCount);
    setCountedTags(tagsCount);


    const { sectionList, locationList, totalResults, totalPages } =
      sectionsRender.preparePageSections(
        filteredResults,
        searchParams.selectedEntity,
        searchParams.currentPage,
        pageSize,
        isMobile
      );

    if (suggestedEntityName && !searchParams.suggestOverride) {
      navigate(
        linkToSearch({
          entityType: suggestedEntityName,
          input: searchParams.enteredInput,
          page: 1,
        }))
    }

    setSectionList(sectionList);
    setEntityLocations(locationList);
    setHasResults(totalResults > 0);
    setTotalPages(totalPages);
    setLoading(false);
  };

  //_____________________________________________________________________________//

  const handleSelectedCategoryByParams = (categoryNames, entityCategories, selectedEntity) => {
    const categories = [];
    if (entityCategories && categoryNames) {
      categoryNames.map((categoryName) => {
        let category;
        switch (selectedEntity.name) {
          case RESTAURANTS:
            category = entityCategories.restaurants.find(
              (category) => category.category_name == categoryName
            );
            if (category) categories.push(category);
            break;
          case BRANCHES:
            category = entityCategories.businessesSubs.find(
              (category) => category.category_name == categoryName
            );
            if (category) categories.push(category);
            break;
          case RECIPES:
            category = entityCategories.recipes.find(
              (category) => category.category_name == categoryName
            );
            if (category) categories.push(category);
            break;
          case BENEFITS:
            category = entityCategories.benefits.find(
              (category) => category.category_name == categoryName
            );
            if (category) categories.push(category);
            break;
          case BRANDS:
            category = entityCategories.companiesSubs.find(
              (category) => category.category_name == categoryName
            );
            if (category) categories.push(category);
            break;
          case BLOGS:
            category = entityCategories.blogs.find(
              (category) => category.category_name == categoryName
            );
            if (category) categories.push(category);
            break;
          case EMPLOYERS:
            category = entityCategories.employers.find(
              (category) => category.category_name == categoryName
            );
            if (category) categories.push(category);
            break;
        }
      });
    } else if (categoryNames) {
      setUnhandledCategoryParams(categoryNames);
    }
    return categories;
  };

  const handleSelectedTagByParams = (tagNames, selectedEntity) => {
    const tags = [];
    tagNames.map((tagName) => {
      const tag = ENTITY_TAGS[tagName];
      if (tag) tags.push(ENTITY_TAGS[tagName]);
    });
    return tags;
  };

  const entitySelectHandler = (entity, page = searchParams.currentPage) => {
    const entityName = entity === "all" ? null : entity;
    navigate(
      linkToSearch({
        entityType: entityName,
        input: searchParams.enteredInput,
        page: 1,
        suggestOverride: true
      })
    );
    window.scrollTo(0, 0);
  };

  const categorySelectHandler = (category) => {
    let categories;
    if (searchParams.selectedCategories && searchParams.selectedCategories.length > 0) {
      const categoryAlreadySelected = searchParams.selectedCategories.find(
        (c) => c.category_name == category.category_name
      );
      if (categoryAlreadySelected) {
        categories = searchParams.selectedCategories.filter(
          (c) => c.category_name != category.category_name
        );
      } else {
        categories = [category, ...searchParams.selectedCategories];
      }
    } else {
      categories = [category];
    }

    navigate(
      linkToSearch({
        entityType: searchParams.selectedEntity.name,
        input: searchParams.enteredInput,
        categoryNames: categories.map((category) => category.category_name),
        tagNames: searchParams.selectedTags.map((tag) => tag.name),
        chainId: searchParams.selectedChainId,
        businessId: searchParams.selectedBusinessId,
        page: 1,
        suggestOverride: true
      })
    );
  };

  const tagsSelectHandler = (tag) => {
    const tagAlreadySelected = searchParams.selectedTags.find((t) => t.name == tag.name);
    let selectedTags = [];
    if (tagAlreadySelected) {
      selectedTags = searchParams.selectedTags.filter((t) => t.name != tag.name);
    } else {
      selectedTags = [tag, ...searchParams.selectedTags];
    }
    navigate(
      linkToSearch({
        entityType: searchParams.selectedEntity.name,
        input: searchParams.enteredInput,
        categoryNames: searchParams.selectedCategories.map((category) => category.category_name),
        tagNames: selectedTags.map((tag) => tag.name),
        chainId: searchParams.selectedChainId,
        businessId: searchParams.selectedBusinessId,
        page: 1,
        suggestOverride: true
      })
    );
  };

  const getEntitiesTypeList = (results) => {

    const typeList = [
      {
        name: ALL,
        label: "all",
        icon: icons.allIcon,
        count: 0,
      },
      {
        name: RESTAURANTS,
        label: "restaurants",
        icon: icons.restaurantsEntity,
        count: results && results.restaurants ? results.restaurants.length : 0,
      },
      {
        name: BENEFITS,
        label: "benefits",
        icon: icons.benefitsEntity,
        count: results && results.benefits ? results.benefits.length : 0,
      },
      {
        name: RECIPES,
        label: "recipes",
        icon: icons.recipesEntity,
        count: results && results.recipes ? results.recipes.length : 0,
      },
      {
        name: BRANCHES,
        label: "branches",
        icon: icons.businessesEntity,
        count: results && results.businesses ? results.businesses.length : 0,
      },
      {
        name: BRANDS,
        label: "companies",
        icon: icons.productsEntity,
        count: results && results.companies ? results.companies.length : 0,
      },
      {
        name: BLOGS,
        label: "blogs",
        icon: icons.blogsEntity,
        count: results && results.blogs ? results.blogs.length : 0,
      },
      {
        name: EMPLOYERS,
        label: "employers",
        icon: icons.employersEntity,
        count: results && results.employers ? results.employers.length : 0,
      }
    ];
    typeList[0].count =
      typeList[1].count +
      typeList[2].count +
      typeList[3].count +
      typeList[4].count +
      typeList[5].count +
      typeList[6].count +
      typeList[7].count;
    return typeList;
  };

  const isMapShowing = () => {
    if (
      searchParams.selectedEntity.name !== RECIPES &&
      searchParams.selectedEntity.name !== BRANDS &&
      searchParams.selectedEntity.name !== EMPLOYERS &&
      searchParams.selectedEntity.name !== BLOGS &&
      searchParams.selectedEntity.name !== BENEFITS
    ) {
      if (isLoading || (userLocation && entityLocations)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const [isResultsOpen, setResultsOpen] = useState(false);
  const resultsOpenHandler = (isOpen) => {
    setResultsOpen(isOpen);
  };

  const [isLocationModalVisible, setLocationModalVisible] = useState(false);
  const [isInputVisible, setInputVisible] = useState(true);

  const resultsPageResizeHandler = (event) => {
    setInputVisible(event !== "SNAP_UP");
  };

  const clearFiltersHandler = () => {
    navigate(
      linkToSearch({
        entityType: searchParams.selectedEntity.name,
        input: searchParams.enteredInput,
        categoryNames: null,
        tagNames: null,
        chainId: searchParams.selectedChainId,
        businessId: searchParams.selectedBusinessId,
        page: 1,
      })
    );
  };

  const bottomSheetRef = useRef();

  const openBottomSheet = () => {
    bottomSheetRef.current.snapTo(({ maxHeight }) => maxHeight * 0.55);
  };

  const openBottomSheetTimer = () => {
    setTimeout(() => {
      if (isMobile) {
        openBottomSheet();
      }
    }, 600);
  };

  useEffect(() => {
    if (isMobile) {
      setTimeout(() => {
        window.scrollTo(0, 0);
        if (isMobile) {
          openBottomSheet();
        }
      }, 600);
    }
  }, []);

  const getResultsNumber = () => {
    if (counterEntities) {
      const entity = counterEntities.find(
        (entity) => entity.name === searchParams.selectedEntity.name
      );
      return entity.count;
    }
    return 0;
  };

  const openLocationHandler = () => {
    setLocationModalVisible(true);
  };

  return (
    <div className={ classes.paddedContent }>
      { isBrowser ? (
        <SearchInputWeb
          sectionList={ sectionList }
          enteredInput={ searchParams.enteredInput }
          entitiesTypeList={ counterEntities }
          selectedEntity={ searchParams.selectedEntity }
          onEntitySelected={ entitySelectHandler }
          categoriesList={ countedCategories }
          selectedCategories={ searchParams.selectedCategories }
          onCategorySelected={ categorySelectHandler }
          tagsList={ countedTags }
          selectedTags={ searchParams.selectedTags }
          onTagSelected={ tagsSelectHandler }
          isLoading={ isLoading }
          userLocation={ userLocation }
          onLocationClick={ openLocationHandler }
          hasResults={ hasResults }
          clearFilters={ clearFiltersHandler }
          isEntitiesShowing={ isEntitiesShowing() }
          resultsNumber={ getResultsNumber() }
          suggestOverride={ searchParams.suggestOverride }
        />
      ) : (
        <SearchInputMobile
          enteredInput={ searchParams.enteredInput }
          entitiesTypeList={ counterEntities }
          selectedEntity={ searchParams.selectedEntity }
          onEntitySelected={ entitySelectHandler }
          categoriesList={ countedCategories }
          selectedCategories={ searchParams.selectedCategories }
          onCategorySelected={ categorySelectHandler }
          tagsList={ countedTags }
          selectedTags={ searchParams.selectedTags }
          onTagSelected={ tagsSelectHandler }
          isLoading={ isLoading }
          userLocation={ userLocation }
          onLocationClick={ openLocationHandler }
          hasResults={ hasResults }
          className={
            isInputVisible
              ? searchParams.selectedEntity.name === ALL || config.currentCountry === "US"
                ? classes.visibleHideTags
                : classes.visible
              : classes.hidden
          }
          clearFilters={ clearFiltersHandler }
          isEntitiesShowing={ isEntitiesShowing() }
          onResultsOpen={ resultsOpenHandler }
          bottomSheetRef={ bottomSheetRef }
          openBottomSheet={ openBottomSheetTimer }
          resultsNumber={ getResultsNumber() }
          suggestOverride={ searchParams.suggestOverride }
        />
      ) }

      { isBrowser ? (
        <WebSearchResults
          sectionList={ sectionList }
          isLoading={ isLoading }
          entitySelectHandler={ entitySelectHandler }
          searchParams={ searchParams }
          userLocation={ userLocation }
          totalPages={ totalPages }
          preparedResults={ preparedResults }
          hasResults={ hasResults }
          isMapShowing={ isMapShowing() }
          news={ news }
        />
      ) : (
        <MobileSearchResults
          sectionList={ sectionList }
          isLoading={ isLoading }
          entitySelectHandler={ entitySelectHandler }
          searchParams={ searchParams }
          userLocation={ userLocation }
          totalPages={ totalPages }
          preparedResults={ preparedResults }
          hasResults={ hasResults }
          isMapShowing={ isMapShowing() }
          onResultsPageResize={ resultsPageResizeHandler }
          news={ news }
          isSearchOpen={ isResultsOpen }
          bottomSheetRef={ bottomSheetRef }
          resultsNumber={ getResultsNumber() }
        />
      ) }

      <LocationSearchModal
        show={ isLocationModalVisible }
        onHide={ () => setLocationModalVisible(false) }
      />
    </div>
  );
};

export default Search;
