import { useEffect } from "react";
import { AboutUsHeader, AboutUsVideo, AboutVa, AboutLabel } from "../../components/AboutUs";
import AppDownload from "../../components/Media/AppDownload/AppDownload";
import config from "../../config";
import { logEvents } from "../../eventsManager";
import { t } from "i18next";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    logEvents("screen_about_us_open");
  }, []);

  const isUS = config.currentCountry === "US";

  return (
    <div>
        <AboutUsHeader />
        <AboutUsVideo />
        {isUS ? (
          <>
            <AboutLabel />
            <AboutVa />
          </>
        ) : (
          <>
            <AboutVa />
            <AboutLabel />
          </>
        )}

        {!isUS && <AppDownload fromAboutUs={true} />}
    </div>
  );
};

export default About;
