import { SET_FULL_COMPANY } from "../actions/companyActions";

const defaultState = {
  fullCompany: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_FULL_COMPANY:
      return { ...state, fullCompany: action.payload };
  }
  return state;
};
