import { t } from "i18next";
import classes from "./EntitiesMenu.module.css";
import icons, { iconsAlts } from "../../../resources/icons";
import { Link, useNavigate } from "react-router-dom";
import * as links from "../../../utils/links";
import { BENEFITS, BLOGS, EMPLOYERS, BRANCHES, BRANDS, RECIPES, RESTAURANTS } from "../../../resources/config";
import React from "react";
import { logEvents } from "../../../eventsManager";
import { getCurrentCountry } from "../../../utils/utilFunctions";

const EntitiesMenu = ({ onClose }) => {
  const navigate = useNavigate();
  const country = getCurrentCountry();

  const logClickEvent = (buttonName) => {
    onClose();
    const params = { button_name: buttonName };
    logEvents("header_and_footer_click", params);
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.itemsContainer}>
        <Link
          className={classes.menuItem}
          to={links.linkToSearch({ entityType: RESTAURANTS })}
          onClick={() => logClickEvent("restaurants")}
        >
          <img
            className={classes.icon}
            src={icons.restaurantsButton}
            alt={iconsAlts.restaurantsButton}
          />
          <p className={classes.itemTitle}>{t("restaurants")}</p>
        </Link>
        <Link
          className={classes.menuItem}
          to={links.linkToSearch({ entityType: BRANCHES })}
          onClick={() => logClickEvent("businesses")}
        >
          <img
            className={classes.icon}
            src={icons.businessesButton}
            alt={iconsAlts.businessesButton}
          />
          <p className={classes.itemTitle}>{t("businesses")}</p>
        </Link>
        {country !== "US" && <Link
          className={classes.menuItem}
          to={links.linkToSearch({ entityType: RECIPES })}
          onClick={() => logClickEvent("recipes")}
        >
          <img
            className={classes.icon}
            src={icons.recipesButton}
            alt={iconsAlts.recipesButton}
          />
          <p className={classes.itemTitle}>{t("recipes")}</p>
        </Link>}
        {country !== "US" &&<Link
          className={classes.menuItem}
          to={links.linkToSearch({ entityType: BENEFITS })}
          onClick={() => logClickEvent("benefits")}
        >
          <img
            className={classes.icon}
            src={icons.benefitsButton}
            alt={iconsAlts.benefitsButton}
          />
          <p className={classes.itemTitle}>{t("benefits")}</p>
        </Link>}
        <Link
          className={classes.menuItem}
          to={links.linkToSearch({ entityType: BRANDS })}
          onClick={() => logClickEvent("companies")}
        >
          <img
            className={classes.icon}
            src={icons.companiesButton}
            alt={iconsAlts.companiesButton}
          />
          <p className={classes.itemTitle}>{t("companies")}</p>
        </Link>
        {country === 'IL' && <Link
          className={classes.menuItem}
          to={links.linkToSearch({ entityType: BLOGS })}
          onClick={() => logClickEvent("blogs")}
        >
          <img
            className={classes.icon}
            src={icons.blogsButton}
            alt={iconsAlts.blogsButton}
          />
          <p className={classes.itemTitle}>{t("blogs")}</p>
        </Link>}
        {country === 'IL' && <Link
          className={classes.menuItem}
          to={links.linkToSearch({ entityType: EMPLOYERS })}
          onClick={() => logClickEvent("employers")}
        >
          <img
            className={classes.icon}
            src={icons.employersButton}
            alt={iconsAlts.employersButton}
          />
          <p className={classes.itemTitle}>{t("employers")}</p>
        </Link>}
      </div>
    </div>
  );
};

export default EntitiesMenu;
