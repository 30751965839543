import { t } from "i18next";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as userActions from "../../../actions/userActions";
import { logEvents } from "../../../eventsManager";
import { BRANCHES, RECIPES, RESTAURANTS, getSuggestedEntityBySearchTerm } from "../../../resources/config";
import icons from "../../../resources/icons";
import * as navigationLinks from "../../../utils/links";
import { homepageKeys, sortResultsByLocationWithScore } from "../../../utils/searchUtils/fuseUtils";
import AutoCompleteSearch from "../../Search/AutoCompleteSearch/AutoCompleteSearch";
import classes from "./HomePageSearch.module.css";
import SearchResult from "./SearchResult";
import * as searchResultsPreparation from "./searchResultsPreparation";

function HomepageSearch({
  suggestOverride,
  enteredInput,
  onFocus,
  selectedEntity = null,
  userLocation,
  isFromSearchPage,
  placeholder,
  searchBarClassName,
  isResultsAlwaysOpen = false,
  onEnter = () => { },
  onResultsOpen = () => { },
  onResultsClickExtraAction = () => { },
}) {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const navigate = (link) => {
    navigation(link, true);
  };

  const searchEntities = useSelector((state) => state.entities.homePageSearchResults);
  const entityCategories = useSelector((state) => state.entities.entityCategories);
  const entityTags = useSelector((state) => state.entities.entityTags);
  const [searchItems, setSearchItems] = useState([]);
  const [additionalResults, setAdditionalResults] = useState([]);

  React.useEffect(() => {
    if (searchEntities && entityCategories) {
      const preparedSearchItems = searchResultsPreparation.prepareSearchEntities(
        searchEntities,
        entityCategories,
        entityTags,
        userLocation
      );
      setSearchItems(preparedSearchItems);
    }
    if (isResultsAlwaysOpen) {
      onFocusHandler();
    }
  }, [searchEntities, entityCategories, userLocation]);

  const formatResult = (item) => {
    return <SearchResult item={ item } onItemClick={ () => handleOnItemClick(item) } />;
  };

  const onFocusHandler = () => {
    if (onFocus) {
      onFocus();
    }
    addEmptyTermSearchResults();
  };

  const addEmptyTermSearchResults = () => {
    let recentSearches = JSON.parse(localStorage.getItem("recent_searches"));
    if (recentSearches) {
      setAdditionalResults([
        {
          entity_type: "around_me",
          name: t("everything_around_me"),
          searchTerm: "",
          logo: icons.allIcon,
          isGreen: true,
        },
        ...recentSearches.slice(0, 5),
      ]);
    } else {
      setAdditionalResults([
        {
          entity_type: "around_me",
          name: t("everything_around_me"),
          searchTerm: "",
          logo: icons.allIcon,
          isGreen: true,
        },
      ]);
    }
  };

  const onSearch = (results, searchTerm) => {
    logEvents("search_query", { query: searchTerm });
    sortResultsByLocationWithScore(results);
    let recentSearches = JSON.parse(localStorage.getItem("recent_searches"));
    recentSearches = recentSearches ? recentSearches : [];

    if (searchTerm && recentSearches) {
      recentSearches = recentSearches.filter((item) => {
        const values = Object.values(item).join(" ").toLowerCase();
        return values.includes(searchTerm.toLowerCase());
      });
    }

    if (searchTerm) {
      setAdditionalResults([
        {
          entity_type: "all",
          name: t("all_results") + " " + searchTerm,
          searchTerm: searchTerm,
          logo: icons.searchBarIcon,
        },
        // ...recentSearches.slice(0, 3),
      ]);
    } else {
      addEmptyTermSearchResults();
    }
  };

  const onCloseResults = () => {
    setAdditionalResults([]);
  };

  const handleOnEnter = (searchTerm) => {
    if (searchTerm || isFromSearchPage) {
      const suggestedEntity = getSuggestedEntityBySearchTerm(searchTerm);
      const entityType = suggestedEntity ? suggestedEntity :
        selectedEntity ? selectedEntity.name : null;
      console.log('entityType', entityType)
      navigate(
        navigationLinks.linkToSearch({
          entityType,
          input: searchTerm,
          suggestOverride: suggestOverride
        })
      );
      if (searchTerm.replace(/\s/g, "") != "") {
        saveToRecentSearches({
          entity_type: "all",
          name: searchTerm,
          searchTerm: searchTerm,
          logo: icons.recentSearch,
        });
      }
    } else {
      navigate(navigationLinks.linkToSearch());
    }

    onEnter();
  };

  const logSearchEvent = (searchTerm) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "search_occurred",
      search_term: searchTerm,
    });
  };

  const getUserLocation = async (searchTerm) => {
    await userActions.getPreciseLocation(dispatch);
    handleOnEnter(searchTerm);
  };

  const handleOnItemClick = (item) => {
    switch (item.entity_type) {
      case "restaurant":
        navigate(
          navigationLinks.linkToRestaurant(item.id, item.restaurant_name, item.location),
          true
        );
        break;
      case "chain":
        navigate(
          navigationLinks.linkToSearch({
            entityType: RESTAURANTS,
            chainId: item.id,
            suggestOverride: true
          })
        );
        break;
      case "business":
        navigate(
          navigationLinks.linkToSearch({
            entityType: BRANCHES,
            businessId: item.id,
            suggestOverride: true
          })
        );
        break;
      case "branch":
        navigate(navigationLinks.linkToBusiness(item.id, item.branch_name, item.is_online));
        break;
      case "recipe":
        navigate(navigationLinks.linkToRecipe(item.id, item.recipe_name));
        break;
      case "blog":
        navigate(navigationLinks.linkToBlog(item.slug));
        break;
      case "employer":
        navigate(navigationLinks.linkToEmployer(item.id, item.employer_name));
        break;
      case "company":
        navigate(navigationLinks.linkToCompany(item.id, item.company_name));
        break;
      case "restaurant_category":
        navigate(
          navigationLinks.linkToSearch({
            entityType: RESTAURANTS,
            categoryNames: [item.restaurant_category],
            suggestOverride: true
          })
        );
        break;
      case "business_category":
        navigate(
          navigationLinks.linkToSearch({
            entityType: BRANCHES,
            categoryNames: [item.business_category],
            suggestOverride: true
          })
        );
        break;
      case "recipe_category":
        navigate(
          navigationLinks.linkToSearch({
            entityType: RECIPES,
            categoryNames: [item.recipe_category],
            suggestOverride: true
          })
        );
        break;
      case "tag":
        /// ???
        break;
      case "around_me":
        getUserLocation(item.searchTerm);
      case "all":
        handleOnEnter(item.searchTerm);
        break;
    }
    if (item.entity_type != "all" && item.entity_type != "around_me") {
      item.logo = icons.recentSearch;
      saveToRecentSearches(item);
    }
    onResultsClickExtraAction();
  };

  const saveToRecentSearches = (newItem) => {
    let recentSearches = JSON.parse(localStorage.getItem("recent_searches"));
    recentSearches = recentSearches ? recentSearches : [];

    if (recentSearches.length == 10) {
      recentSearches.pop();
    }

    recentSearches = recentSearches.filter(
      (item) => JSON.stringify(item) != JSON.stringify(newItem)
    );

    recentSearches.unshift(newItem);

    localStorage.setItem("recent_searches", JSON.stringify(recentSearches));
  };

  return (
    <div className={ classes.mainContainer }>
      <AutoCompleteSearch
        enteredInput={ enteredInput }
        searchItems={ searchItems }
        onFocus={ onFocusHandler }
        placeholder={ placeholder }
        onSearch={ onSearch }
        formatResult={ formatResult }
        additionalResults={ additionalResults }
        onEnter={ handleOnEnter }
        onCloseResults={ onCloseResults }
        searchBarClassName={ searchBarClassName }
        onResultsOpen={ onResultsOpen }
        isResultsAlwaysOpen={ isResultsAlwaysOpen }
        keys={ homepageKeys }
      />
    </div>
  );
}

export default HomepageSearch;
