import classes from "./Hero.module.css";
import { t } from "i18next";
import { useSelector, useDispatch } from "react-redux";
import * as entitiesActions from "../../../actions/entitiesActions";
import HomepageSearch from "../HomePageSearch/HomepageSearch";
import icons, { iconsAlts } from "../../../resources/icons";
import { isMobile } from "react-device-detect";
import Tooltip from "../../Common/Tooltip/Tooltip";
import Skeleton from "react-loading-skeleton";

const Hero = ({ onLocationClick }) => {
  const dispatch = useDispatch();
  const userLocation = useSelector((state) => state.user.userLocation);
  const isSearchResultRequested = useSelector((state) => state.entities.isSearchResultRequested);
  const searchResults = useSelector((state) => state.entities.searchResults);

  const handleOnFocus = () => {
    if (!isSearchResultRequested && !searchResults && userLocation) {
      entitiesActions.getSearchResults(dispatch, userLocation);
    }
  };

  const getLocationString = (address) => {
    if (address === "nearby" || address === "nearby_approximate") {
      return t(address);
    } else {
      return t("current_location") + t(address);
    }
  };

  return (
    <div className={classes.backgroundContainer} alt="vegan dishes and foods spread out on a table">
      <h1 className={classes.mainText}>{t("our_goal")}</h1>
      <div className={classes.searchBar}>
        <HomepageSearch
          onFocus={handleOnFocus}
          userLocation={userLocation}
          placeholder={
            isMobile ? t("search_bar_place_holder_mobile") : t("search_bar_place_holder")
          }
        />
      </div>
      {userLocation ? (
        <div className={classes.locationContainer}>
          <button type="button" className={classes.locationButton} onClick={onLocationClick}>
            <img
              className={classes.locationIcon}
              src={icons.locationGrey}
              alt={iconsAlts.locationGrey}
            />
            <p className={classes.secondaryText} id={"locationContainer"}>
              {getLocationString(userLocation.address)}
            </p>
          </button>
          <Tooltip />
        </div>
      ) : (
        <Skeleton width="25rem" borderRadius="20px" className={classes.locationSkeleton} />
      )}
    </div>
  );
};

export default Hero;
