import { useEffect, useState } from "react";
import classes from "./SearchResult.module.css";
import { t } from "i18next";
import { iconsAlts } from "../../../resources/icons";
import { Image } from "../../Common";

const SearchResult = ({ item, onItemClick }) => {
  useEffect(() => {
    formatResult(item);
  }, [item]);

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [logo, setLogo] = useState("");
  const [isIconGrey, setIconGrey] = useState(false);

  const formatResult = (item) => {
    switch (item.entity_type) {
      case "restaurant":
        setTitle(item.restaurant_name);
        setSubtitle(item.address);
        break;
      case "recipe":
        setTitle(item.recipe_name);
        setSubtitle("");
        break;
      case "blog":
          setTitle(item.blog_name);
          setSubtitle("");
          break;
      case "employer":
            setTitle(item.employer_name);
            setSubtitle("");
            break;
      case "company":
        setTitle(item.company_name);
        setSubtitle("");
        break;
      case "chain":
        setTitle(item.chain_name);
        setSubtitle(t("all_branches") + " " + t("in") + t("restaurants"));
        break;
      case "business":
        setTitle(item.business_name);
        setSubtitle(t("all_branches") + " " + t("in") + t("businesses"));
        break;
      case "branch":
        setTitle(item.branch_name);
        setSubtitle(item.address);
        break;
      case "restaurant_category":
        setTitle(item.restaurant_category);
        setSubtitle(item.restaurant_category + " " + t("in") + t("restaurants"));
        setIconGrey(true);
        break;
      case "business_category":
        setTitle(item.business_category);
        setSubtitle(item.business_category + " " + t("in") + t("businesses"));
        setIconGrey(true);
        break;
      case "recipe_category":
        setTitle(item.recipe_category);
        setSubtitle(item.recipe_category + " " + t("in") + t("recipes"));
        setIconGrey(true);
        break;
      case "company_category":
        setTitle(item.company_category);
        setSubtitle(item.company_category + " " + t("in") + t("companies"));
        setIconGrey(true);
        break;
      case "blog_category":
          setTitle(item.blog_category);
          setSubtitle(item.blog_category + " " + t("in") + t("blogs"));
          setIconGrey(true);
          break;
      case "employer_category":
          setTitle(item.employer_category);
          setSubtitle(item.employer_category + " " + t("in") + t("employers"));
          setIconGrey(true);
          break;
      case "tag":
        setTitle(item.tag_name);
        setSubtitle("");
        setIconGrey(true);
        break;
      case "around_me":
      case "all":
        setTitle(item.name);
        setSubtitle("");
        break;
      default:
        setTitle(JSON.stringify(item));
        setSubtitle("error");
    }
    setLogo(item.logo);
  };

  return (
    <div className={classes.mainContainer} onClick={onItemClick}>
      <Image
        className={isIconGrey ? classes.iconGrey : classes.icon}
        src={logo}
        alt="entity icon"
      />
      {/* <img className={isIconGrey ? classes.iconGrey : classes.icon} src={logo} alt="entity icon" /> */}
      <div className={classes.textContainer}>
        {title && (
          <p className={item && item.isGreen ? classes.titleGreen : classes.title}>{title}</p>
        )}
        {subtitle && <p className={classes.subtitle}>{subtitle}</p>}
      </div>
    </div>
  );
};

export default SearchResult;
