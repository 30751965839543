import classes from "./FileDragArea.module.css";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import images from "../../resources/images";

const FileDragArea = ({ className, dropAreaClassName, onPhotoSelected, image, text }) => {
  const fileTypes = ["JPG", "JPEG", "PNG", "GIF", "TIF", "TIFF", "HEIC", "HEIF", "HEIF"];

  const handleChange = (file) => {
    onPhotoSelected(file);
  };

  return (
    <FileUploader
      classes={`${className} ${classes.fileUploader} `}
      handleChange={handleChange}
      name="file"
      types={fileTypes}
      children={
        <div className={`${classes.dropArea} ${dropAreaClassName}`}>
          {image && <img className={classes.cameraIcon} src={image} alt="uploaded image" />}
          <p className={classes.addText}>{text}</p>
        </div>
      }
    />
  );
};

export default FileDragArea;
