import api from "../api";

export const SET_FULL_BUSINESS = "SET_FULL_BUSINESS";

export const getFullBusiness = async (dispatch, id) => {
  dispatch({
    type: SET_FULL_BUSINESS,
    payload: {},
  });

  const response = await api.getFullBusiness(id);

  response.reviews.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  dispatch({
    type: SET_FULL_BUSINESS,
    payload: response,
  });
};
