import classes from "./AboutUsVideo.module.css";
import { t } from "i18next";
import config from "../../../config";

const AboutUsVideo = () => {
  const getLinkIdByCountry = () => {
    const currentCountry = config.currentCountry;
    switch (currentCountry) {
      case "US":
        return "PkQL_0zrG6c";
      case "GB":
        return "kwsof8LbMgo";
      case "IL":
        return "DKE-jU-LWRU";
    }
  };

  return (
    <div className={classes.mainContainer}>
      <h3 className={classes.title}>{t("about_us_we_believe")}</h3>
      {/* <p className={classes.greenTitle}> {t("about_us_our_vision")}</p> */}
      <div className={classes.videoContainer}>
        <iframe
          className={classes.video}
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${getLinkIdByCountry()}?autoplay=1&mute=1`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          poster={`https://img.youtube.com/vi/${getLinkIdByCountry()}/maxresdefault.jpg`}
        />
        <h4 className={classes.subtitle}>{t("who_are_we")}</h4>
        <p className={classes.description}>{t("about_us_description")}</p>
      </div>
    </div>
  );
};

export default AboutUsVideo;
