import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate, useParams } from "react-router-dom";
import * as companyActions from "../../actions/companyActions";

import { EntityHeader, ProductCatalog } from "../../components/EntityPages";
import {
  BusinessDescription,
  BusinessSocial,
} from "../../components/EntityPages/BusinessAndRestaurant";
import { logEvents } from "../../eventsManager";

import { BRANDS } from "../../resources/config";
import config from "../../config";

import { companyUtils, reviewUtils } from "../../utils/entityUtils";
import classes from "./Company.module.css";

const Company = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isUS = config.currentCountry === "US";

  const { companyId } = useParams();
  const company = useSelector((state) => state.company.fullCompany);
  const entityCategories = useSelector((state) => state.entities.entityCategories);
  const reviewHashTags = useSelector((state) => state.entities.reviewHashTags);
  const pendingReviews = useSelector((state) => state.user.userPendingReviews);

  const [preparedCompany, setPreparedCompany] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [companyEventProps, setCompanyEventProps] = useState({});

  useEffect(() => {
    companyActions.getFullCompany(dispatch, companyId).catch(() => {
      navigate("/not-found"); 
    });

    window.scrollTo(0, 0);
  }, []);

  const getCompanyEventsProps = (preparedCompany) => {
    return {
      business_id: preparedCompany.id,
      business_type: BRANDS,
      business_name: preparedCompany.name,
    };
  };

  useEffect(() => {
    if (
      company &&
      entityCategories &&
      entityCategories.companiesSubs &&
      reviewHashTags &&
      reviewHashTags.companyTags &&
      pendingReviews
    ) {
      if (company.id && company.id == companyId) {
        const prepared = companyUtils.prepareCompany(company, entityCategories.companiesSubs);
        if (prepared.reviews) {
          prepared.reviews.map((review) =>
            reviewUtils.prepareReview(review, reviewHashTags.companyTags)
          );
        }
        prepared.userPendingReviews = pendingReviews.filter(
          (review) => review.entity_type == "recipe" && review.entity_id == companyId
        );

        prepared.userPendingReviews.map((review) =>
          reviewUtils.prepareReview(review, reviewHashTags.companyTags)
        );

        setPreparedCompany(prepared);
        const props = getCompanyEventsProps(prepared);

        logEvents("screen_business_open", props);
        setCompanyEventProps(props);
        setIsLoading(false);
      }
    }
  }, [company, entityCategories, reviewHashTags, pendingReviews]);

  const componentToPrint = useRef();

  const reactToPrintContent = useCallback(() => {
    return componentToPrint.current;
  }, [componentToPrint.current]);

  return (
    <div className={classes.mainContainer} ref={componentToPrint}>
      <div>
        <EntityHeader
          entityType={BRANDS}
          title={preparedCompany.name}
          rating={preparedCompany.rating}
          numOfReviews={preparedCompany.num_of_reviews}
          categories={preparedCompany.subCategories}
          coverImages={preparedCompany.pics ? preparedCompany.pics.map((p) => p.url) : []}
          isLoading={isLoading}
          componentToPrint={reactToPrintContent}
        />
        {!isLoading && (
          <BusinessDescription
            description={preparedCompany.description}
            entityType={BRANDS}
            phone={preparedCompany.phone_number}
            showTopDivider={false}
            hideLocation={true}
            hideSchedule={true}
          />
        )}
        {!isLoading && isUS && (
            <ProductCatalog
              className={classes.menuContainer}
              catalog={preparedCompany.catalog}
              entityName={preparedCompany.name}
            />
          )}
        {!isLoading && (
          <BusinessSocial
            className={classes.socialsContainer}
            restaurantName={preparedCompany.name}
            websiteLink={preparedCompany.website_url}
            instagramLink={preparedCompany.instagram_url}
            facebookLink={preparedCompany.facebook_url}
            linkedinLink={preparedCompany.linkedin_url}
          />
        )}

      </div>
    </div>
  );
};

export default Company;
