import { useState, useEffect } from "react";
import icons, { iconsAlts } from "../../../resources/icons";
import classes from "./ErrorableDropDown.module.css";
import { t } from "i18next";

const ErrorableDropDown = ({
  id,
  options,
  onOptionSelected,
  onBlur,
  selectedOption,
  placeholder,
  hasError = true,
}) => {
  const [isOptionsOpen, setOptionsOpen] = useState(false);

  useEffect(() => {
    try {
      window.addEventListener("click", function (e) {
        const element = document.getElementById(id);
        if (element && !element.contains(e.target)) {
          setOptionsOpen(false);
        }
      });
    } catch (ex) {}
  }, []);

  const optionSelectedHandler = (option) => {
    setOptionsOpen(false);
    onOptionSelected(option);
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.wrapper}>
        <div
          id={id}
          className={
            hasError
              ? classes.inputRowError
              : selectedOption
              ? classes.inputRowSelected
              : classes.inputRow
          }
          onClick={() => setOptionsOpen((open) => !open)}
        >
          <p className={selectedOption ? classes.inputSelected : classes.input}>
            {selectedOption ? t(selectedOption.text) : t(placeholder)}{" "}
            {selectedOption && <img src={selectedOption.icon} />}
          </p>
          <img src={icons.greenArrowDown} alt={iconsAlts.greenArrowDown} />
        </div>
        {isOptionsOpen && options && (
          <div className={classes.resultsContainer}>
            {options.map((option, index) => (
              <p
                id={`${id}-option-${index + 1}`}
                className={classes.result}
                onClick={() => optionSelectedHandler(option)}
              >
                {t(option.text)}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ErrorableDropDown;
