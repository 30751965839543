import api from "../api";
import { getCurrentCountry } from "../utils/utilFunctions";
import { entitiesUtils } from "../utils/entityUtils";

import usCities from "../utils/usCities.json";

export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_CURRENT_STATE = "SET_CURRENT_STATE";

//homepage
export const RESET_ENTITIES_STATE = "RESET_ENTITIES_STATE";
export const SET_WEBSITE_ENTITIES = "SET_WEBSITE_ENTITIES";
export const SET_HOME_PAGE_SEARCH_RESULTS = "SET_SEARCH_ENTITIES";
export const ADD_NEW_REVIEW_RESTAURANT = "ADD_NEW_REVIEW_RESTAURANT";
export const ADD_NEW_REVIEW_BUSINESS = "ADD_NEW_REVIEW_BUSINESS";
export const ADD_NEW_REVIEW_RECIPE = "ADD_NEW_REVIEW_RECIPE";

//search
export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";
export const SET_SEARCH_RESULT_REQUESTED = "SET_SEARCH_RESULT_REQUESTED";
export const SET_FULL_BLOG = "SET_FULL_BLOG";
export const SET_FULL_EMPLOYER = "SET_FULL_EMPLOYER";

const getZoneByLocation = location => {
  let minDistance = 9999;
  
  let zone = 0;

  if(location.country !== 'US') return null;
  
  for(let index in usCities) {
    const candidateLocation = { lon: usCities[index].lon, lat: usCities[index].lat }
    
    let tempDistance = entitiesUtils.calculateDistance(location.coords, candidateLocation);
    if(minDistance > tempDistance) {
      zone = index;
      minDistance = tempDistance;
    }
  }

  return zone;
}

export const initData = async (dispatch, reloadSearch, location) => {
  dispatch({
    type: RESET_ENTITIES_STATE,
  });
  await getWebsiteHomepageEntities(dispatch, location);
  if (reloadSearch) {
    await getSearchResults(dispatch, location);
  }
  dispatch({ type: SET_IS_LOADING, payload: false });
  dispatch({ type: SET_CURRENT_STATE, payload: location.state });
};

export const getWebsiteHomepageEntities = async (dispatch, location) => {
  const zone = getZoneByLocation(location)
  const response = await api.getWebsiteHomepageEntities(zone);
  const { website_entities, instagram_website_posts, tags, review_hash_tags } = response;

  website_entities.map((entity) => {
    if (entity.entity_name === "businesses") {
      entity.entity_name = "branches";
    }
    return entity;
  });

  const sortFunc = (first, second) => {
    if (first.category_name < second.category_name) {
      return -1;
    }
    if (first.category_name > second.category_name) {
      return 1;
    }
    return 0;
  };

  const entityCategories = {
    restaurants: response.rest_categories.sort(sortFunc),
    businessesSubs: response.business_subcats.sort(sortFunc),
    businesses: response.business_categories,
    companiesSubs: response.companies_subcats.sort(sortFunc),
    recipes: response.recipe_categories.sort(sortFunc),
    blogs: response.blog_categories.sort(sortFunc),
    employers: response.employer_categories.sort(sortFunc),
    benefits: [
      ...response.rest_categories.map((c) => ({ ...c, id: "R" + c.id })),
      ...response.business_subcats.map((c) => ({ ...c, id: "B" + c.id })),
    ].sort(sortFunc),
  };

  const newsEntity = website_entities.find((entity) => entity.entity_name === "news");

  const newsList = newsEntity && newsEntity.entity_list ? newsEntity.entity_list : [];
  newsList.sort(sortFunc);

  const reviewHashTags = {
    restaurantTags: review_hash_tags.filter((tags) => tags.entity_type === "restaurant"),
    businessTags: review_hash_tags.filter((tags) => tags.entity_type === "business"),
    recipeTags: review_hash_tags.filter((tags) => tags.entity_type === "recipe"),
    companyTags: review_hash_tags.filter((tags) => tags.entity_type === "company"),
  };

  dispatch({
    type: SET_WEBSITE_ENTITIES,
    payload: {
      homePageEntities: website_entities,
      instagramWebsitePosts: instagram_website_posts,
      entityCategories,
      entityTags: tags,
      reviewHashTags: reviewHashTags,
      news: newsList,
    },
  });

  getSearchEntities(dispatch, location);
};

export const getSearchEntities = async (dispatch, location) => {
  const zone = getZoneByLocation(location);
  const response = await api.getHomePageSearchResults(zone);
  const { restaurants, chains, businesses, business_branches, recipes, companies, blogs, employers } = response;
  dispatch({
    type: SET_HOME_PAGE_SEARCH_RESULTS,
    payload: { restaurants, chains, businesses, business_branches, recipes, companies, blogs, employers },
  });
};

export const getSearchResults = async (dispatch, location) => {
  dispatch({
    type: SET_SEARCH_RESULT_REQUESTED,
    payload: true,
  });

  const zone = getZoneByLocation(location);
  const response = await api.getSearchResults(zone);

  dispatch({
    type: SET_SEARCH_RESULTS,
    payload: response,
  });
  dispatch({
    type: SET_SEARCH_RESULT_REQUESTED,
    payload: false,
  });
};

export const getFullBlog = async (dispatch, slug) => {
  dispatch({
    type: SET_FULL_BLOG,
    payload: {}
  });
  
  const response = await api.getFullBlog(slug);
  if (response && response.blog && response.success) {
    dispatch({
      type: SET_FULL_BLOG,
      payload: response.blog,
    });
  }
};

export const getFullEmployer = async (dispatch, id) => {
  dispatch({
    type: SET_FULL_EMPLOYER,
    payload: {}
  });
  
  const response = await api.getFullEmployer(id);

  if (response) {
    dispatch({
      type: SET_FULL_EMPLOYER,
      payload: response,
    });
  }
};
