import classes from "./FooterList.module.css";
import { t } from "i18next";
import { getCurrentFlag } from "../../resources/config";
import { useState, useEffect } from "react";
import { countries } from "../../resources/config";
import icons, { iconsAlts } from "../../resources/icons";
import { DropDown } from "../Common";
import { getCurrentCountry } from "../../utils/utilFunctions";
import { logEvents } from "../../eventsManager";
import { linkToExternalPage } from "../../utils/links";
import globalIcon from "../../assets/images/global_icon.svg";

const FooterButtons = ({ className, contactUsHandler }) => {
  const [isLocationMenuOpen, setLocationMenu] = useState(false);
  const [country, setCountry] = useState(null);

  useEffect(() => {
    const country = getCurrentCountry();
    setCountry(country);
  }, []);

  const changeSelectedCountry = (selectedCountry) => {
    if (selectedCountry == "IL") {
      linkToExternalPage("https://vegan-friendly.co.il");
    } else if (selectedCountry == "GB") {
      linkToExternalPage("https://vegan-friendly.co.uk");
    } else {
      linkToExternalPage("https://vegan-friendly.com");
    }
    logEvents("header_and_footer_click", { button_name: "select_country" });
  };

  return (
    <div className={className}>
      <div className={classes.buttonContainer} onClick={contactUsHandler}>
        <img src={icons.form} alt={iconsAlts.form} />
        <p className={classes.itemText}>{t("contact_us")}</p>
      </div>
      {country === "IL" && false && <DropDown
        header={t("chose_country")}
        className={classes.dropDown}
        buttonComponent={
          <button
            type="button"
            className={classes.buttonContainer}
            onClick={() => setLocationMenu((state) => !state)}
          >
            <img className={classes.flagLogo} src={globalIcon} alt={"flag icon"} />
            <p className={classes.itemText}>{t(country)}</p>
            <img src={icons.whiteArrowDown} alt={iconsAlts.whiteArrowDown} />
          </button>
        }
        innerComponent={() => (
          <div className={classes.locationSelection}>
            {countries.map((c) => (
              <button
                type="button"
                key={c.code}
                className={classes.countryItem}
                onClick={() => changeSelectedCountry(c.code)}
              >
                <p className={classes.countryName}>{t(c.name)}</p>
              </button>
            ))}
          </div>
        )}
      />}
    </div>
  );
};

export default FooterButtons;
