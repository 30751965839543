import images, { imagesAlts } from "../../../../resources/images";
import classes from "./RecipeInstructions.module.css";
import { t } from "i18next";
import Skeleton from "react-loading-skeleton";

const RecipeInstructions = ({
  instructions,
  isTivoniTaim,
  credit,
  creditLink,
  className,
  isLoading,
}) => {
  const prepareInstructionsList = (instructions) => {
    if (instructions && instructions.length) {
      const instructionsArr = [];
      instructions.map((instruction, index) => {
        if (index === 0) {
          if (instruction.is_title) {
            instructionsArr.push({
              id: instruction.id,
              title: instruction.step,
              steps: [],
            });
          } else {
            instructionsArr.push({ id: instruction.id, title: "", steps: [] });
            instructionsArr[instructionsArr.length - 1].steps.push({
              id: instruction.id,
              details: instruction.step,
            });
          }
        } else {
          if (instruction.is_title) {
            instructionsArr.push({
              id: instruction.id,
              title: instruction.step,
              steps: [],
            });
          } else {
            instructionsArr[instructionsArr.length - 1].steps.push({
              id: instruction.id,
              details: instruction.step.trim(),
            });
          }
        }
      });
      return instructionsArr;
    }
    return [];
  };

  return isLoading ? (
    <Skeleton height="24rem" width="100%" borderRadius="20px" style={{ marginTop: "2rem" }} />
  ) : (
    <div className={`${classes.mainContainer} ${className}`}>
      <div className={classes.headerContainer}>
        <img
          className={classes.chefImage}
          src={isTivoniTaim ? images.tivoniTaim : images.recipeChef}
          alt={isTivoniTaim ? imagesAlts.tivoniTaim : imagesAlts.recipeChef}
        />
        <div className={classes.titleContainer}>
          <h2 className={classes.mainHeader}>{t("step_by_step")}</h2>
          <div className={classes.row}>
            <h3 className={classes.mainHeader}>{t("recipe_by")}</h3>
            <h3 className={classes.mainHeader}>{t("tivoni_taim")} </h3>
          </div>
        </div>
      </div>
      {prepareInstructionsList(instructions).map((instruction) => (
        <div key={instruction.id} className={classes.sectionContainer}>
          <p className={classes.sectionHeader}>{instruction.title}</p>
          {instruction.steps.map((step, index) => (
            <div className={classes.stepContainer}>
              <div className={classes.stepNumberContainer}>
                <p className={classes.stepNumber}>{index + 1}</p>
              </div>
              <p className={classes.stepDetails} key={step.id}>
                {step.details}
              </p>
            </div>
          ))}
        </div>
      ))}
      <h4 className={classes.creditHeader}>
        {t("credit")}:{" "}
        <a className={classes.creditLink} href={creditLink} target="_blank">
          {credit}
        </a>
      </h4>
    </div>
  );
};

export default RecipeInstructions;
