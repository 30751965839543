import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import * as generalActions from "../../../actions/generalActions";
import classes from "./AboutVa.module.css";
import { BenefitItem } from "../../Entities/EntityGridItems";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { BENEFITS } from "../../../resources/config";
import * as links from "../../../utils/links";
import { imagesAlts } from "../../../resources/images";
import config from "../../../config";

const AboutVa = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const aboutUsEntities = useSelector((state) => state.general.aboutUsEntities);

  useEffect(() => {
    generalActions.getAboutUsEntities(dispatch);
  }, []);

  const seeAllBenefitsClickHandler = () => {
    navigation(links.linkToSearch({ entityType: BENEFITS }));
  };

  const isUS = config.currentCountry === "US";

  return (
    <div className={classes.mainContainer}>
      <h3 className={classes.mainTitle}>{t("about_va_title")}</h3>
      <p className={classes.mainSubTitle}>{t("about_va_decription")}</p>
      <section className={classes.projectsContainer}>
        <h4 className={classes.projectsTitle}>{t("our_projects")}</h4>
        <div className={classes.campaignsRow}>
          {aboutUsEntities &&
            aboutUsEntities.campaignItems &&
            aboutUsEntities.campaignItems.map((campaign, index) => (
              <div className={classes.campaignContainer} key={index}>
                <img
                  className={classes.campaignImg}
                  src={campaign.image_url}
                  alt={"campaign image"}
                />
                <div className={classes.campaignContent}>
                  {campaign.num_of_supporters && (
                    <div className={classes.campaignSupportersContainer}>
                      <p className={classes.campaignSupportersText}>
                        {t("supporters")} {campaign.num_of_supporters}
                      </p>
                    </div>
                  )}
                  <h6 className={classes.campaignTitle}>{campaign.title}</h6>
                  <p className={classes.campaignDescription}>{campaign.description}</p>
                  <a className={classes.campaignLink} href={campaign.link}></a>
                </div>
              </div>
            ))}
        </div>
        <h5 className={classes.projectsSubtitle}>{t("projects_subtitle")}</h5>
        {isUS && (
          <a className={classes.projectsLink} href={links.URLS.donate} target="_blank">
            {t("projects_link")}
          </a>
        )}
      </section>
      {aboutUsEntities &&
        aboutUsEntities.benefitItems &&
        aboutUsEntities.benefitItems.length > 0 && (
          <section className={classes.benefitsContainer}>
            <h4 className={classes.benefitsTitle}>{t("our_benefits")}</h4>
            <p className={classes.benefitsSubtitle}>{t("our_benefits_description")}</p>
            <div className={classes.benefitsRow}>
              {aboutUsEntities.benefitItems.map((benefit) => (
                <BenefitItem benefit={benefit} style={classes.benefitContainer} isLoading={false} />
              ))}
            </div>
            <button
              type="button"
              className={classes.seeAllBenefits}
              onClick={seeAllBenefitsClickHandler}
            >
              {t("all_benefits")}
            </button>
          </section>
        )}
    </div>
  );
};

export default AboutVa;
