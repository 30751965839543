import { t } from "i18next";
import { useEffect, useState } from "react";
import { logEvents } from "../../../eventsManager";
import { AddReviewModal } from "../../../modals";
import ReviewSuccess from "../../../modals/ReviewSuccess/ReviewSuccess";
import icons, { iconsAlts } from "../../../resources/icons";
import images, { imagesAlts } from "../../../resources/images";
import { reviewUtils, scheduleUtils } from "../../../utils/entityUtils";
import { getIconByRating } from "../../../utils/entityUtils/reviewUtils";
import { GreenTag, PrimaryButton } from "../../Common";
import { NoSearchResults, SearchBar } from "../../Search";
import ReviewItem from "../ReviewItem/ReviewItem";
import classes from "./EntityReviews.module.css";
import { RECIPES } from "../../../resources/config";

const EntityReviews = ({
  entityType,
  entityId,
  entityName,
  rating,
  reviews,
  pendingReviews,
  numOfReviews,
  className,
  eventProps,
}) => {
  const [displayedReviews, setDisplayedReviews] = useState(reviews);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [isAddReviewOpen, setAddReviewOpen] = useState(false);
  const [isSuccessOpen, setSuccessOpen] = useState(false);

  useEffect(() => {
    filterReviews(searchTerm, selectedFilter);
  }, [searchTerm, selectedFilter, reviews]);

  const filterReviews = (input, filter) => {
    if (reviews) {
      if (input || filter) {
        const searchInput = input ? input.toLowerCase() : "";
        const newReviews = reviews.filter((review) => {
          const tagsString = review.tags
            ? review.tags
              .map((tag) => tag.name)
              .join(" ")
              .toLowerCase()
            : "";
          return (
            ((review.description && review.description.toLowerCase().includes(searchInput)) ||
              tagsString.includes(searchInput)) &&
            (!filter || review.score === filter)
          );
        });
        setDisplayedReviews(newReviews);
      } else {
        setDisplayedReviews(reviews);
      }
    }
  };

  const filterSelectHandler = (newFilter) => {
    setSelectedFilter((filter) => {
      if (newFilter === filter) {
        return null;
      } else {
        return newFilter;
      }
    });
  };

  const addReviewHandler = (source) => {
    logEvents("add_review_click", { ...eventProps, source: source });
    setAddReviewOpen(true);
  };

  const hasResults =
    (pendingReviews && pendingReviews.length > 0) ||
    (displayedReviews && displayedReviews.length > 0);

  return (
    <div className={ `${classes.mainContainer} ${className}` } id='reviews_container'>
      <div className={ classes.topDivider } />
      <div className={ classes.headerContainer }>
        <h3 className={ classes.mainHeader }>{ t("reviews") }</h3>
        <img className={ classes.reviewIcon } src={ icons.reviewIcon } alt={ iconsAlts.reviewIcon } />
        { rating > 0 && (
          <img className={ classes.smile } src={ getIconByRating(rating, true) } alt="rating icon" />
        ) }
        { rating > 0 && <p className={ classes.rating }>{ rating }</p> }
      </div>
      { rating > 0 || (pendingReviews && pendingReviews.length > 0) ? (
        <>
          <div className={ classes.addReviewContainer }>
            <img className={ classes.reviewerImage } src={ images.avatar1 } alt={ imagesAlts.avatar1 } />
            <PrimaryButton
              title={ t("add_review") }
              onClick={ () => addReviewHandler("add_review_button") }
              isSlim={ true }
            />
          </div>
          <div className={ `${classes.filtersContainer}` }>
            <SearchBar
              onSearch={ setSearchTerm }
              placeholder={ t("search_reviews") }
              onEnter={ setSearchTerm }
              onClear={ () => {
                setSearchTerm("");
              } }
              searchBarClassName={ classes.searchBar }
            />
            <h5 className={ classes.filtersTitle }>{ t("filters") } :</h5>
            <div className={ classes.filterButtonsContainer }>
              { [5, 4, 3, 2, 1].map((review, index) => (
                <GreenTag
                  key={ index }
                  className={ classes.filterButtonContainer }
                  tag={ review }
                  tagName={ review }
                  icon={ reviewUtils.getIconByRating(review, true) }
                  iconSelected={ reviewUtils.getIconByRating(review) }
                  isHollow={ true }
                  onTagSelected={ filterSelectHandler }
                  isSelected={ selectedFilter === review }
                />
              )) }
            </div>
          </div>
          <h4 className={ classes.reviewsCountLine }>{ `${t("to")}${entityName} ${t(
            "has"
          )} ${numOfReviews} ${t("reviews")}` }</h4>
          { hasResults ? (
            <div className={ classes.reviewsContainer }>
              { pendingReviews &&
                pendingReviews.map((review, index) => (
                  <ReviewItem
                    key={ review.id ? review.id : index }
                    review={ review }
                    isPending={ true }
                  />
                )) }

              { displayedReviews &&
                displayedReviews.map((review, index) => (
                  <ReviewItem key={ review.id ? review.id : index } review={ review } />
                )) }
            </div>
          ) : (
            <div className={ classes.reviewsContainerNoResults }>
              <img src={ images.noResults } alt={ imagesAlts.noResults } />
              <p className={ classes.noResultsTitle }>{ t("no_results_reviews") }</p>
            </div>
          ) }
        </>
      ) : (
        <div className={ classes.emptyReviewsContainer }>
          <img className={ classes.emptyReviewsImage } src={ images.reviewsEmptyPlaceholder } />
          <PrimaryButton
            title={entityType === RECIPES ? t("reviews_recipe_title") : t("reviews_place_title") }
            isSlim={ true }
            fontSize={ 1.8 }
            onClick={ () => addReviewHandler("be_the_first_to_write_review") }
          />
        </div>
      ) }

      <AddReviewModal
        show={ isAddReviewOpen }
        onHide={ () => {
          setAddReviewOpen(false);
        } }
        onSuccess={ () => {
          setAddReviewOpen(false);
          setSuccessOpen(true);
        } }
        entityType={ entityType }
        entityId={ entityId }
        entityName={ entityName }
        entityRating={ rating }
        eventProps={ eventProps }
      />
      <ReviewSuccess
        show={ isSuccessOpen }
        onHide={ () => {
          setSuccessOpen(false);
        } }
        entityType={ entityType }
      />
    </div>
  );
};

export default EntityReviews;
