import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getCurrentCountry } from "../utils/utilFunctions";

i18n.use(initReactI18next).init({
  fallbackLng: "he",
  lng: "he",
  resources: {
    en_uk: {
      translations: require("./locales/en_uk/en_uk.json"),
    },
    en: {
      translations: require("./locales/en/en.json"),
    },
    he: {
      translations: require("./locales/he/he.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "he", "en_uk"];

const selectedCountry = getCurrentCountry();
const language = selectedCountry == "IL" ? "he" : selectedCountry == "GB" ? "en_uk" : "en";
i18n.changeLanguage(language);
document.getElementsByTagName("html")[0].setAttribute("dir", language === "he" ? "rtl" : "ltr");

export default i18n;
