import { useState, useEffect, useRef } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import { sectionsRender } from "../../../pages/Search/SearchHelpers";
import classes from "./SearchResultsBottomSheet.module.css";

// if setting up the CSS is tricky, you can add this to your page somewhere:
// <link rel="stylesheet" href="https://unpkg.com/react-spring-bottom-sheet/dist/style.css" crossorigin="anonymous">
import "react-spring-bottom-sheet/dist/style.css";
import PageSelector from "../PageSelector/PageSelector";
import { ALL } from "../../../resources/config";
import { t } from "i18next";

const SearchResultsBottomSheet = ({
  sheetRef,
  onPageResize,
  sectionList,
  isLoading,
  entitySelectHandler,
  searchParams,
  news,
  hasResults,
  isSearchOpen,
  currentPage,
  totalPages,
  onPageSelected,
  resultsNumber,
}) => {
  const listRef = useRef();

  const onSpringEnd = (event) => {
    requestAnimationFrame(() => {
      const defaultSnap = window.innerHeight * 0.55;
      if (event.type === "SNAP") {
        if (sheetRef.current.height > defaultSnap + 10) {
          onPageResize("SNAP_UP");
        } else if (sheetRef.current.height < defaultSnap - 10) {
          onPageResize("SNAP_DOWN");
        } else {
          onPageResize("SNAP_MIDDLE");
        }
      }
    });
  };

  const pageSelectHandler = (page) => {
    listRef.current.scrollIntoView({
      behavior: "auto",
      block: "start",
    });
    onPageSelected(page);
  };

  return !isSearchOpen ? (
    <BottomSheet
      ref={sheetRef}
      open={true}
      blocking={false}
      defaultSnap={({ maxHeight }) => maxHeight * 0.5}
      snapPoints={({ maxHeight }) => [
        maxHeight - maxHeight / 7.5,
        maxHeight * 0.55,
        maxHeight * 0.07,
      ]}
      onSpringStart={onSpringEnd}
      scrollLocking={false}
      header={
        <p className={classes.header}>{resultsNumber ? resultsNumber + " " + t("places") : ""}</p>
      }
    >
      <div className={classes.entitiesContainer} ref={listRef}>
        {sectionList &&
          sectionList.length &&
          sectionsRender.renderSections(
            sectionList,
            isLoading,
            entitySelectHandler,
            null,
            null,
            searchParams.selectedChainId,
            news
          )}
        {searchParams.selectedEntity.name != ALL && hasResults && !isLoading && (
          <PageSelector
            currentPage={currentPage}
            totalPages={totalPages}
            onPageSelected={pageSelectHandler}
          />
        )}
      </div>
    </BottomSheet>
  ) : (
    <></>
  );
};

export default SearchResultsBottomSheet;
