import { getCurrentCountry } from "../../utils/utilFunctions";
import icons from "../../resources/icons";

const dropDownOptionsIL = [
  {
    text: "contact_us_option_1",
    firstInfo: "contact_us_option_1_info_1",
    secondInfo: "contact_us_option_1_info_2",
    icon: icons.contactVolunteer,
  },
  {
    text: "contact_us_option_2",
    firstInfo: "contact_us_option_2_info_1",
    secondInfo: "contact_us_option_2_info_2",
    icon: icons.contactNewBusiness,
  },
  {
    text: "contact_us_option_3",
    firstInfo: "contact_us_option_3_info_1",
    secondInfo: "contact_us_option_3_info_2",
    icon: icons.contactUpdateBusiness,
  },
  // {
  //   text: "contact_us_option_4",
  //   firstInfo: "contact_us_option_4_info_1",
  //   secondInfo: "contact_us_option_4_info_2",
  // },
  // {
  //   text: "contact_us_option_5",
  //   firstInfo: "contact_us_option_5_info_1",
  //   secondInfo: "contact_us_option_5_info_2",
  // },
  {
    text: "contact_us_option_6",
    firstInfo: "contact_us_option_6_info_1",
    icon: icons.contactVactive,
  },
  {
    text: "contact_us_option_7",
    firstInfo: "contact_us_option_7_info_1",
    secondInfo: "contact_us_option_7_info_2",
    icon: icons.contactBug,
  },
  {
    text: "contact_us_option_8",
    firstInfo: "contact_us_option_8_info_1",
    icon: icons.contactOther,
  },
];

const dropDownOptions = [
  {
    text: "contact_us_option_1",
    firstInfo: "contact_us_option_1_info_1",
    secondInfo: "contact_us_option_1_info_2",
    icon: icons.contactVolunteer,
  },
  {
    text: "contact_us_option_2",
    firstInfo: "contact_us_option_2_info_1",
    secondInfo: "contact_us_option_2_info_2",
    icon: icons.contactNewBusiness,
  },
  {
    text: "contact_us_option_3",
    firstInfo: "contact_us_option_3_info_1",
    secondInfo: "contact_us_option_3_info_2",
    icon: icons.contactUpdateBusiness,
  },
  // {
  //   text: "contact_us_option_4",
  //   firstInfo: "contact_us_option_4_info_1",
  //   secondInfo: "contact_us_option_4_info_2",
  // },
  // {
  //   text: "contact_us_option_5",
  //   firstInfo: "contact_us_option_5_info_1",
  //   secondInfo: "contact_us_option_5_info_2",
  // },
  {
    text: "contact_us_option_7",
    firstInfo: "contact_us_option_7_info_1",
    secondInfo: "contact_us_option_7_info_2",
    icon: icons.contactBug,
  },
  {
    text: "contact_us_option_8",
    firstInfo: "contact_us_option_8_info_1",
    icon: icons.contactOther,
  },
];

export const getDropDownOptions = () => {
  if (getCurrentCountry() != "IL") {
    return dropDownOptions;
  }
  return dropDownOptionsIL;
};
