import classes from "./InstagramReferral.module.css";
import * as generalActions from "../../../actions/generalActions";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import icons, { iconsAlts } from "../../../resources/icons";
import { PrimaryButton } from "../../Common";
import * as links from "../../../utils/links";
import MediaButtons from "../MediaButtons/MediaButtons";
import { t } from "i18next";
import { isMobile } from "react-device-detect";

const InstagramReferral = () => {
  const dispatch = useDispatch();
  const instagramPosts = useSelector((state) => state.entities.instagramPosts);
  const instagramPostsFull = useSelector((state) => state.general.featuredInstagramPosts);

  const postClickHandler = (url) => {
    links.linkToExternalPage(url);
  };

  const instagramLinkHandler = () => {
    links.linkToExternalPage(links.URLS.instagram);
  };

  const sortPosts = (posts) => {
    const sortFunc = (first, second) => first.appearance_order - second.appearance_order;

    posts.sort(sortFunc);
    return posts;
  };

  useEffect(() => {
    generalActions.getInstagramPost(dispatch, instagramPosts);
  }, [instagramPosts]);

  return instagramPosts.length > 0 ? (
    <div className={classes.mainContainer}>
      <div className={classes.headerContainer}>
        <div className={classes.row}>
          <h4 className={classes.headerTitle}>{t("from_our_instagram")}</h4>
          <PrimaryButton
            title={t("follow_us")}
            iconLeft={icons.instagramIcon}
            onClick={instagramLinkHandler}
            isSlim={true}
          />
        </div>

        <MediaButtons
          className={classes.mediaButtonsTop}
          text={t("follow_on_other_media")}
          facebookLink={links.URLS.facebook}
          linkedinLink={links.URLS.linkedin}
          tiktokLink={links.URLS.tiktok}
        />
      </div>
      <div className={classes.postsGrid}>
        {sortPosts(instagramPostsFull).map((post) => (
          <div
            key={post.url}
            className={classes.postContainer}
            onClick={() => postClickHandler(post.url)}
          >
            <img className={classes.post} src={post.thumbnail_url} alt={"instagram post image"} />
            <img
              className={classes.instagramIcon}
              src={icons.instagramIcon}
              alt={iconsAlts.instagramIcon}
            />
          </div>
        ))}
      </div>

      <MediaButtons
        className={classes.mediaButtonsBottom}
        text={t("follow_on_other_media")}
        facebookLink={links.URLS.facebook}
        linkedinLink={links.URLS.linkedin}
        tiktokLink={links.URLS.tiktok}
      />
    </div>
  ) : (
    <></>
  );
};

export default InstagramReferral;
