import api from "../api";
import config from "../config";
import { BRANCHES, RECIPES, RESTAURANTS } from "../resources/config";
import { getCurrentCountry } from "../utils/utilFunctions";

export const SET_USER = "SET_USER";
export const SET_PENDING_REVIEWS = "SET_PENDING_REVIEWS";
export const ADD_NEW_PENDING_REVIEW = "ADD_NEW_PENDING_REVIEW";
export const SET_USER_LOCATION = "SET_USER_LOCATION";
export const SET_LOCATION_PERMISSIONS = "SET_LOCATION_PERMISSIONS";

export const L_PERMISSIONS_REQUESTED = "L_PERMISSIONS_REQUESTED";
export const L_PERMISSIONS_GRANTED = "L_PERMISSIONS_GRANTED";
export const L_PERMISSIONS_DENIED = "L_PERMISSIONS_DENIED";
export const L_PERMISSIONS_NOT_ASKED = "L_PERMISSIONS_NOT_ASKED";

export const LOCATION_ERROR = "LOCATION_ERROR";

export const login = async (hash) => {
  const isHashValid = await api.testHash(hash);

  try {
    if (!isHashValid) {
      const response = await api.postUserAuth();
      return { success: response };
    } else {
      return { success: true };
    }
  } catch (e) {
    console.log("login: " + "ERROR!!!!!");
    return { success: false, error: "hash" };
  }
};

export const setCurrentUser = async (dispatch, user) => {
  dispatch({
    type: SET_USER,
    payload: user,
  });
};

export const getUserPendingReviews = async (dispatch) => {
  const response = await api.getUserPendingReviews();
  dispatch({ type: SET_PENDING_REVIEWS, payload: response });
};

export const postNewReview = async (
  dispatch,
  entityType,
  entityId,
  rating,
  details,
  name,
  avatar,
  images,
  addedHashTags
) => {
  const created = new Date();
  const review = {
    score: rating,
    description: encodeURI(details),
    reviewer_name: encodeURI(name),
    created,
    avatar: 0,
    pics: images,
    hash_tags: addedHashTags,
  };
  switch (entityType) {
    case RESTAURANTS:
      review.restaurant = { id: entityId };
      review.entity_type = "restaurant";
      break;
    case BRANCHES:
      review.business_branch = { id: entityId };
      review.entity_type = "business-branch";
      break;
    case RECIPES:
      review.recipe = { id: entityId };
      review.entity_type = "recipe";
      break;
  }
  const response = await api.postNewReview(review);
  review.pics = review.pics ? review.pics.map((pic) => pic.url) : [];
  review.entity_id = entityId;
  review.user_status = "anonymous";

  if (response.success) {
    dispatch({
      type: ADD_NEW_PENDING_REVIEW,
      payload: review,
    });
  }
  return response;
};

//Called only on first launch because we don't want to ask for permission yet
export const getUserLocation = async (dispatch) => {
  let lastLocation = null;
  try {
    lastLocation = JSON.parse(localStorage.getItem("userLocation"));
  } catch {}

  if (
    lastLocation &&
    lastLocation.country == config.currentCountry &&
    lastLocation.address != "nearby" &&
    lastLocation.address != "nearby_approximate"
  ) {
    setSelectedLocation(
      dispatch,
      lastLocation.country,
      lastLocation.state,
      lastLocation.address,
      lastLocation.latLng
    );
  } else {
    const hasPermission = localStorage.getItem("location_permission");
    if (hasPermission) {
      getPreciseLocation(dispatch);
    } else {
      getEstimatedLocation(dispatch);
      dispatch({
        type: SET_LOCATION_PERMISSIONS,
        payload: L_PERMISSIONS_NOT_ASKED,
      });
    }
  }
};

//Called any other time, asks for permission if user didn't gave them already
export const getPreciseLocation = async (dispatch) => {
  dispatch({
    type: SET_LOCATION_PERMISSIONS,
    payload: L_PERMISSIONS_REQUESTED,
  });
  navigator.geolocation.getCurrentPosition(
    function (position) {
      const latLng = {
        lat: parseFloat(position.coords.latitude),
        lng: parseFloat(position.coords.longitude),
      };
      getFullLocationFromLatLan(latLng, dispatch, "nearby");
      localStorage.setItem("location_permission", true);

      dispatch({
        type: SET_LOCATION_PERMISSIONS,
        payload: L_PERMISSIONS_GRANTED,
      });
    },
    (ex) => {
      getEstimatedLocation(dispatch);
      localStorage.setItem("location_permission", false);

      dispatch({
        type: SET_LOCATION_PERMISSIONS,
        payload: L_PERMISSIONS_DENIED,
      });

      dispatch({
        type: LOCATION_ERROR,
        payload: ex.message,
      });
    },
    { maximumAge: 60000, timeout: 4000, enableHighAccuracy: true }
  );
};

const getEstimatedLocation = async (dispatch) => {
  try {
    const response = await api.getLocationByIP();

    const latLng = {
      lat: parseFloat(response.location.lat),
      lng: parseFloat(response.location.lng),
    };
    getFullLocationFromLatLan(latLng, dispatch, "nearby_approximate");
  } catch (ex) {
    console.log("ERROR!! getEstimatedLocation: " + JSON.stringify(ex));
    setDefaultLocationForCountry(dispatch, getCurrentCountry());
  }
};

const getFullLocationFromLatLan = async (latLng, dispatch, addressName) => {
  try {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ latLng }, function (results, status) {
      if (status == window.google.maps.GeocoderStatus.OK) {
        const currentAddress = results[0];
        //getting country
        const countryComponent = currentAddress.address_components.find(
          (c) => c.types && c.types[0] === "country"
        );
        const currentCountry = countryComponent ? countryComponent.short_name : null;

        //getting state
        let currentState = null;
        if (currentCountry === "US") {
          const stateComponent = currentAddress.address_components.find(
            (c) => c.types && c.types[0] === "administrative_area_level_1"
          );
          currentState = stateComponent ? stateComponent.short_name : null;
        }
        if (getCurrentCountry() != currentCountry) {
          setDefaultLocationForCountry(dispatch, getCurrentCountry());
        } else {
          setSelectedLocation(dispatch, currentCountry, currentState, addressName, latLng);
        }
      } else {
        console.log("ERROR IN LOCATION !!!! " + status);
      }
    });
  } catch (ex) {
    console.log("ERROR IN LOCATION !!!! " + ex);
  }
};

const setDefaultLocationForCountry = async (dispatch, country) => {
  switch (country) {
    case "IL":
      setSelectedLocation(dispatch, "IL", null, "Tel Aviv, Israel", {
        lat: 32.0852999,
        lng: 34.78176759999999,
      });
      break;
    case "GB":
      setSelectedLocation(dispatch, "GB", null, "London, UK", {
        lat: 51.5072178,
        lng: -0.1275862,
      });
      break;
    case "US":
      setSelectedLocation(dispatch, "US", "NY", "NYC, NY, USA", {
        lat: 40.7127753,
        lng: -74.0059728,
      });
      break;
  }
};

export const setSelectedLocation = async (dispatch, country, state, address, latLng) => {
  let location = null;
  if (address && latLng) {
    location = {
      country,
      state,
      address,
      coords: {
        lat: latLng.lat,
        lon: latLng.lng,
      },
    };
    localStorage.setItem(
      "userLocation",
      JSON.stringify({
        country,
        state,
        address,
        latLng,
      })
    );
  }
  dispatch({
    type: SET_USER_LOCATION,
    payload: location,
  });
};

const hasLocationPermissions = async () => {
  if (navigator.permissions && navigator.permissions.query) {
    const result = await navigator.permissions.query({ name: "geolocation" });
    const hasPermission = result.state == "granted";

    return hasPermission;
  } else {
    return false;
  }
};
