import Fuse from "fuse.js";
import config from "../../../config";
import { prepareBenefit } from "../../../utils/entityUtils/benefitUtils";
import { BRANDS, BRANCHES, RECIPES, RESTAURANTS, BLOGS, EMPLOYERS } from "../../../resources/config";

import {
  sortResultsByLocationWithScore,
  getFuseOptions,
  restaurantKeys,
  businessKeys,
  recipeKeys,
  benefitKeys,
  companiesKeys,
  blogsKeys, 
  employersKeys
} from "../../../utils/searchUtils/fuseUtils";
import { resultsPreparation } from ".";

export const sortResults = (fuses, results, searchTerm) => {
  if (results) {
    if (searchTerm) {
      // sort results by input and location
      let restaurantsResults = fuses.restaurantsFuse.search(searchTerm);
      
      sortResultsByLocationWithScore(restaurantsResults);
      results.restaurants = restaurantsResults.map((result) => result.item);

      const businessResults = fuses.businessesFuse.search(searchTerm);
      sortResultsByLocationWithScore(businessResults);
      results.businesses = businessResults.map((result) => result.item);

      const recipesResults = fuses.recipesFuse.search(searchTerm);
      sortResultsByLocationWithScore(recipesResults);
      results.recipes = recipesResults.map((result) => result.item);

      const benefitsResults = fuses.benefitsFuse.search(searchTerm);
      sortResultsByLocationWithScore(benefitsResults);
      results.benefits = benefitsResults.map((result) => result.item);

      const companiesResults = fuses.companiesFuse.search(searchTerm);
      sortResultsByLocationWithScore(companiesResults);
      results.companies = companiesResults.map((result) => result.item);

      const blogsResults = fuses.blogsFuse.search(searchTerm);
      sortResultsByLocationWithScore(blogsResults);
      results.blogs = blogsResults.map(result => result.item);
 
      const employersResults = fuses.employersFuse.search(searchTerm);
      sortResultsByLocationWithScore(employersResults);
      results.employers = employersResults.map(result => result.item);

      const suggestedEntity = getOutstandingResultType([
        {entityType: RESTAURANTS, results: restaurantsResults},
        {entityType: BRANCHES, results: businessResults}, 
        {entityType: RECIPES, results:  recipesResults},
        {entityType: BRANDS, results: companiesResults},
        {entityType: BLOGS, results: blogsResults},
        {entityType: EMPLOYERS, results: employersResults}
      ])
      
      return suggestedEntity;

    } else {
      //sort results only by location
      results.restaurants = sortSearchResultsByLocation([...results.restaurants]);
      results.businesses = sortSearchResultsByLocation([...results.businesses]);
      results.recipes = sortSearchResultsByLocation([...results.recipes]);
      results.benefits = sortSearchResultsByLocation([...results.benefits]);
      results.companies = sortSearchResultsByLocation([...results.companies]);
      results.blogs = sortSearchResultsByLocation([...results.blogs]);
      results.employers = sortSearchResultsByLocation([...results.employers]);

    }
  }
};

const getOutstandingResultType = (resultsObject) => {
  const bestResult = resultsObject.sort((a, b) => {
    a.rank = a.results && a.results[0] ? a.results[0].score : 99999;
    b.rank = b.results && b.results[0] ? b.results[0].score : 99999;

    return a.rank - b.rank; 
  })[0].entityType;

  return bestResult;
}

const sortSearchResultsByLocation = (searchResults) => {
  return searchResults.sort((a, b) => {
    return (
      (b.distance != null) - (a.distance != null) ||
      addScoreForCertified(a) - addScoreForCertified(b)
    );
  });
};

export const addScoreForCertified = (item) => {
  if (item.is_certified) {
    const present = config.currentCountry === "US" ? 0.15 : 0.9;
    return item.distance * present;
  }
  return item.distance;
};

export const prepareEntitiesFuses = (entities) => {
  const restaurantsFuse = prepareRestaurantsFuse(entities.restaurants);
  const businessesFuse = prepareBusinessFuse(entities.businesses);
  const recipesFuse = prepareRecipesFuse(entities.recipes);
  const benefitsFuse = prepareBenefitsFuse(entities.benefits);
  const companiesFuse = prepareCompaniesFuse(entities.companies);
  const blogsFuse = prepareBlogsFuse(entities.blogs);
  const employersFuse = prepareEmployersFuse(entities.employers);
  return { restaurantsFuse, businessesFuse, recipesFuse, benefitsFuse, companiesFuse, blogsFuse, employersFuse };
};

const prepareRestaurantsFuse = (restaurants) => {

  const keys = restaurantKeys;
  const options = getFuseOptions(keys);
  return new Fuse(restaurants, options);
};

const prepareBusinessFuse = (businesses) => {
  const keys = businessKeys;
  const options = getFuseOptions(keys);
  return new Fuse(businesses, options);
};

const prepareRecipesFuse = (recipes) => {
  const keys = recipeKeys;
  const options = getFuseOptions(keys);
  return new Fuse(recipes, options);
};

const prepareBenefitsFuse = (restaurants) => {
  const keys = benefitKeys;
  const options = getFuseOptions(keys);
  return new Fuse(restaurants, options);
};

const prepareCompaniesFuse = (restaurants) => {
  const keys = companiesKeys;
  const options = getFuseOptions(keys);
  return new Fuse(restaurants, options);
};

const prepareBlogsFuse = (blogs) => {
  const keys = blogsKeys;
  const options = getFuseOptions(keys);
  return new Fuse(blogs, options);
}

const prepareEmployersFuse = (employers) => {
  const keys = employersKeys;
  const options = getFuseOptions(keys);
  return new Fuse(employers, options);
}
