import classes from "./BusinessesItem.module.css";
import icons from "../../../resources/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as navigationLinks from "../../../utils/links";
import GreyIconTags from "../IconTags/GreyIconTags/GreyIconTags";
import { BRANCHES, RESTAURANTS } from "../../../resources/config";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import EntityRating from "../EntityRating/EntityRating";

const BusinessesItem = ({
  data,
  type,
  isLoading = true,
  style,
  isHovered = false,
  onEntityHover,
}) => {
  const business = data;
  const navigate = useNavigate();

  const getEntityClickHandler = () => {
    switch (type) {
      case RESTAURANTS:
        return () =>
          navigate(navigationLinks.linkToRestaurant(business.id, business.name, business.location));
      case BRANCHES:
        return () => {
          navigate(
            navigationLinks.linkToBusiness(
              business.branch_id,
              business.name,
              business.location,
              business.is_online
            )
          );
        };
    }
  };

  const entityHoverHandler = (entity) => {
    if (onEntityHover) {
      onEntityHover(entity);
    } else {
      return;
    }
  };

  return (
    <div
      className={`${style} ${
        !onEntityHover || isLoading
          ? classes.mainContainer
          : isHovered
          ? classes.mainContainerHovered
          : classes.mainContainerHoverable
      } `}
      onClick={getEntityClickHandler()}
      onMouseOver={() => entityHoverHandler(business)}
      onMouseOut={() => entityHoverHandler(null)}
    >
      <div className={classes.imageContainer}>
        {isLoading ? (
          <Skeleton
            height="100%"
            style={{
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
            }}
          />
        ) : (
          <img
            id="image"
            className={classes.image}
            src={business.logo ? (business.logo.url ? business.logo.url : business.logo) : ""}
            alt={"business logo"}
          />
        )}
      </div>
      <div className={classes.contentContainer}>
        <p className={classes.businessesName}>
          {isLoading ? <Skeleton height="100%" width="80%" borderRadius="20px" /> : business.name}
        </p>
        <p className={classes.businessesDescription}>
          {isLoading ? (
            <Skeleton height="50%" width="100%" borderRadius="20px" style={{ marginTop: "1rem" }} />
          ) : (
            business.description
          )}
        </p>
        <div className={classes.tagsContainer}>
          {isLoading ? (
            <Skeleton height="2rem" width="10rem" borderRadius="20px" />
          ) : (
            <GreyIconTags entity={business} />
          )}
          {!isLoading && business.rating > 0 && <EntityRating rating={business.rating} />}
        </div>
      </div>
    </div>
  );
};

export default BusinessesItem;
