import { t } from "i18next";
import classes from "./RecipeIngredients.module.css";
import icons, { iconsAlts } from "../../../../resources/icons";
import { EntityWrongInfo } from "../../";
import Skeleton from "react-loading-skeleton";

const RecipeIngredients = ({ recipeName, ingredients, className, isLoading }) => {
  return isLoading ? (
    <div className={className}>
      <Skeleton height="30rem" width="90%" borderRadius="20px" />
    </div>
  ) : (
    <div className={`${classes.mainContainer} ${className}`}>
      <div className={classes.headerContainer}>
        <h3 className={classes.headerText}>{t("ingredients")}</h3>
      </div>
      <div className={classes.ingredientsContainer}>
        {ingredients.map((section) => (
          <div key={section.id} className={classes.sectionContainer}>
            <p className={classes.sectionHeader}>{section.title}</p>
            {section.ingredients.map((ingredient) => (
              <div className={classes.ingredientContainer}>
                <img className={classes.leafIcon} src={icons.iconLeaf} alt={iconsAlts.iconLeaf} />
                <p className={classes.ingredientName}>
                  {ingredient.amount} {ingredient.name}
                </p>
              </div>
            ))}
          </div>
        ))}
      </div>
      {!isLoading && (
        <EntityWrongInfo recipeName={recipeName} className={classes.wrongInfoContainer} />
      )}
    </div>
  );
};

export default RecipeIngredients;
