import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import classes from "./LocationSearchModal.module.css";
import Modal from "react-bootstrap/Modal";
import LocationSearch from "../../components/Map/LocationSearch";
import { useEffect } from "react";
import { getCurrentLocale } from "../../utils/utilFunctions";
import icons, { iconsAlts } from "../../resources/icons";

const LocationSearchModal = ({ show, onHide, onLocationChosen = () => {} }) => {
  const getTopPosition = () => {
    const element = document.getElementById("locationContainer");
    if (element) {
      var rect = element.getBoundingClientRect();
      return rect.top + "px";
    }
    return 0;
  };

  const locationChosenHandler = (address) => {
    onLocationChosen(address);
    onHide();
  };

  return (
    <Modal
      show={show}
      contentClassName={classes.mainContainer}
      backdropClassName={classes.backdrop}
      onHide={onHide}
      size={"lg"}
    >
      <div style={{ marginTop: "5rem" }}>
        <img
          className={getCurrentLocale() === "he" ? classes.exitButtonIL : classes.exitButton}
          src={icons.closeXWhite}
          onClick={onHide}
          alt={iconsAlts.closeXWhite}
        />
        <LocationSearch onLocationChosen={locationChosenHandler} />
      </div>
    </Modal>
  );
};

export default LocationSearchModal;
