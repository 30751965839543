import { ALL, BUSINESSES,  RESTAURANTS, RECIPES, BRANCHES, BENEFITS, BRANDS, BLOGS, EMPLOYERS } from "../../../resources/config";

import { BUSINESSES_TAGS, BENEFITS_TAGS } from "../../../resources/entities/entityTags";

export const filterSearchResults = (
  results,
  selectedEntity,
  entityCategories,
  selectedCategories,
  selectedTags,
  selectedChainId,
  selectedBusinessId
) => {
  const categoriesCount = JSON.parse(JSON.stringify(entityCategories));
  let tagsCount = [];

  switch (selectedEntity.name) {
    case ALL:
    case RESTAURANTS:
      tagsCount = JSON.parse(JSON.stringify(BUSINESSES_TAGS));

      results.restaurants = filterRestaurants(
        results.restaurants,
        selectedCategories,
        selectedTags,
        categoriesCount.restaurants,
        tagsCount,
        selectedChainId
      );
      break;
    case ALL:
    case BRANCHES:
      tagsCount = JSON.parse(JSON.stringify(BUSINESSES_TAGS));

      results.businesses = filterBusinesses(
        results.businesses,
        selectedCategories,
        selectedTags,
        categoriesCount.businessesSubs,
        tagsCount,
        selectedBusinessId
      );
      break;
    case ALL:
    case RECIPES:
      results.recipes = filterRecipes(results.recipes, selectedCategories, categoriesCount.recipes);
      break;
    case ALL:
    case BENEFITS:
      tagsCount = JSON.parse(JSON.stringify(BENEFITS_TAGS));
      results.benefits = filterBenefits(
        results.benefits,
        selectedCategories,
        selectedTags,
        categoriesCount.benefits,
        tagsCount
      );
      break;
    case ALL:
    case BRANDS:
      results.companies = filterCompanies(
        results.companies,
        selectedCategories,
        selectedTags,
        categoriesCount.companiesSubs,
        tagsCount
      );
      break;
    case ALL:
    case BLOGS: 
        results.blogs = filterBlogs(
          results.blogs,
          selectedCategories,
          categoriesCount.blogs
        )
        break;
    case ALL:
      case EMPLOYERS: 
          results.employers = filterEmployers(
            results.employers,
            selectedCategories,
            categoriesCount.employers
          )
          break;
  }
  if (selectedChainId) {
    results.businesses = [];
    results.recipes = [];
    results.blogs = [];

  } else if (selectedBusinessId) {
    results.restaurants = [];
    results.recipes = [];
    results.blogs = [];

  }

  return { categoriesCount, tagsCount };
};

const filterRestaurants = (
  restaurants,
  categories,
  tags,
  categoriesCount,
  tagsCount,
  selectedChainId
) => {
  if (restaurants) {
    return restaurants.filter((restaurant) => {
      const checkCategory = checkEntityByCategory(restaurant.rest_categories, categories);
      const checkTags = checkEntityByTags(restaurant, tags);
      let checkChain = true;
      if (selectedChainId && selectedChainId != restaurant.chainId) {
        checkChain = false;
      }
      countCategoriesAndTags(
        restaurant,
        restaurant.rest_categories,
        checkCategory,
        checkTags,
        categoriesCount,
        tagsCount,
        checkChain
      );

      return checkCategory && checkTags && checkChain;
    });
  }

  return [];
};

const filterBusinesses = (
  businesses,
  categories,
  tags,
  categoriesCount,
  tagsCount,
  selectedChainId
) => {
  if (businesses) {
    return businesses.filter((business) => {
      const checkCategory = checkEntityByCategory(business.business_subcats, categories);
      const checkTags = checkEntityByTags(business, tags);
      let checkChain = true;
      if (selectedChainId && selectedChainId != business.id) {
        checkChain = false;
      }
      countCategoriesAndTags(
        business,
        business.business_subcats,
        checkCategory,
        checkTags,
        categoriesCount,
        tagsCount,
        checkChain
      );
      return checkCategory && checkTags && checkChain;
    });
  }
  return [];
};

const filterBenefits = (benefits, categories, tags, categoriesCount, tagsCount) => {
  if (benefits) {
    return benefits.filter((benefit) => {
      let benefitCategory = [];
      if (benefit.entity_type === RESTAURANTS) {
        benefitCategory = benefit.restaurant.categories;
      } else {
        benefitCategory = benefit.branch.categories;
      }

      const checkCategory = checkEntityByCategory(benefitCategory, categories);
      const checkTags = checkEntityByTags(benefit, tags);

      countCategoriesAndTags(
        benefit,
        benefitCategory,
        checkCategory,
        checkTags,
        categoriesCount,
        tagsCount
      );

      return checkCategory && checkTags;
    });
  }
  return [];
};

const filterCompanies = (companies, categories, tags, categoriesCount, tagsCount) => {
  if (companies) {
    return companies.filter((company) => {
      const checkCategory = checkEntityByCategory(company.subCategories, categories);
      if (checkCategory) {
        countCategoriesAppearances(company.subCategories, categoriesCount);
      }
      return checkCategory;
    });
  }
  return companies;
};

const filterBlogs = (blogs, categories,  categoriesCount) => {
  if (blogs) {
    return blogs.filter((blog) => {
      const checkCategory = checkEntityByCategory(blog.blog_categories, categories);
      if (checkCategory) {
        countCategoriesAppearances(blog.blog_categories, categoriesCount);
      }
      return checkCategory;
    });
  }  
  return blogs;
};

const filterEmployers = (employers, categories,  categoriesCount) => {
  if (employers) {
    return employers.filter((employers) => {
      const checkCategory = checkEntityByCategory(employers.employer_categories, categories);
      if (checkCategory) {
        countCategoriesAppearances(employers.employer_categories, categoriesCount);
      }
      return checkCategory;
    });
  }  

  return employers;
};



const filterRecipes = (recipes, categories, categoriesCount) => {
  if (recipes) {
    return recipes.filter((recipe) => {
      const checkCategory = checkEntityByCategory(recipe.recipe_categories, categories);

      if (checkCategory) {
        countCategoriesAppearances(recipe.recipe_categories, categoriesCount);
      }

      return checkCategory;
    });
  }
  return [];
};

const checkEntityByCategory = (entityCategories, selectedCategories) => {
  if (entityCategories && selectedCategories && selectedCategories.length > 0) {
    let categoryCheck = true;
    selectedCategories.map((selectedCategory) => {
      const hasCategory = entityCategories.some((category) => category.id === selectedCategory.id);
      if (!hasCategory) {
        categoryCheck = false;
      }
    });
    return categoryCheck;
  }
  return true;
};

const countCategoriesAndTags = (
  entity,
  entityCategories,
  checkCategory,
  checkTags,
  categoriesCount,
  tagsCount,
  checkChain = true
) => {
  if (checkChain && checkTags && checkCategory) {
    countTagsAppearances(entity, tagsCount);
    countCategoriesAppearances(entityCategories, categoriesCount);
  }
  // if (checkChain && checkCategory) {
  //   countCategoriesAppearances(entityCategories, categoriesCount);
  // }
};

const countCategoriesAppearances = (entityCategories, categoriesCount) => {
  if (entityCategories) {
    entityCategories.map((category) => {
      const countedCategory = categoriesCount.find((c) => c.id == category.id);
      if (countedCategory) {
        countedCategory.count = countedCategory.count ? countedCategory.count + 1 : 1;
      }
    });
  }
};

const countTagsAppearances = (entity, tagsCount) => {
  tagsCount.map((tag) => {
    switch (tag.name) {
      case "benefit":
        if (entity.has_discount) tag.count = tag.count ? tag.count + 1 : 1;
        break;
      case "openNow":
        if (entity.is_open) tag.count = tag.count ? tag.count + 1 : 1;
        break;
      case "vegan":
        if (entity.is_vegan) tag.count = tag.count ? tag.count + 1 : 1;
        break;
      case "delivery":
        if (entity.has_delivery) tag.count = tag.count ? tag.count + 1 : 1;
        break;
      case "kosher":
        if (entity.is_kosher) tag.count = tag.count ? tag.count + 1 : 1;
        break;
      case "certified":
        if (entity.is_certified) tag.count = tag.count ? tag.count + 1 : 1;
        break;
      case "glutenFree":
        if (entity.is_gluten_free) tag.count = tag.count ? tag.count + 1 : 1;
        break;
      case "veganOptions":
        if (entity.vegan_options) tag.count = tag.count ? tag.count + 1 : 1;
        break;
      case "restaurantBenefit":
        if (entity.entity_type === RESTAURANTS) tag.count = tag.count ? tag.count + 1 : 1;
        break;
      case "businessBenefit":
        if (entity.entity_type === BRANCHES) tag.count = tag.count ? tag.count + 1 : 1;
        break;
    }
  });
};

const checkEntityByTags = (entity, selectedTags) => {
  if (selectedTags) {
    for (let i = 0; i < selectedTags.length; i++) {
      switch (selectedTags[i].name) {
        case "benefit":
          if (!entity.has_discount) return false;
          break;
        case "openNow":
          if (!entity.is_open) return false;
          break;
        case "vegan":
          if (!entity.is_vegan) return false;
          break;
        case "delivery":
          if (!entity.has_delivery) return false;
          break;
        case "kosher":
          if (!entity.is_kosher) return false;
          break;
        case "certified":
          if (!entity.is_certified) return false;
          break;
        case "glutenFree":
          if (!entity.is_gluten_free) return false;
          break;
        case "veganOptions":
          if (!entity.vegan_options) return false;
          break;
        case "restaurantBenefit":
          if (entity.entity_type !== RESTAURANTS) return false;
          break;
        case "businessBenefit":
          if (entity.entity_type !== BRANCHES) return false;
          break;
      }
    }
  }
  return true;
};
