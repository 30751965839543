import classes from "./ReviewSuccess.module.css";
import Modal from "react-bootstrap/Modal";
import { t } from "i18next";
import PrimaryButton from "../../components/Common/PrimaryButton/PrimaryButton";
import images, { imagesAlts } from "../../resources/images";
import { linkToMainPage } from "../../utils/links";
import { useNavigate } from "react-router-dom";
import { RESTAURANTS } from "../../resources/config";

const ReviewSuccess = ({ show, onHide, entityType }) => {
  const navigate = useNavigate();

  return (
    <Modal
      show={show}
      contentClassName={classes.mainContainer}
      onHide={onHide}
      size={"xl"}
      centered
    >
      <div className={classes.contentContainer}>
        <img
          className={classes.cowImg}
          src={images.cowWithComputer}
          alt={imagesAlts.cowWithComputer}
        />
        <p className={classes.title}>{t("review__suc_modal_title")}</p>
        <p className={classes.subTitle}>{t("")}</p>
        <PrimaryButton
          className={classes.button}
          title={t("back_to") + t("a_" + entityType)}
          onClick={onHide}
          isBig={true}
        />
        <a
          className={classes.backToHome}
          onClick={() => {
            onHide();
            navigate(linkToMainPage());
          }}
        >
          {t("back_to_home")}
        </a>
      </div>
    </Modal>
  );
};

export default ReviewSuccess;
