import classes from "./VaProjects.module.css";

const VaProjects = () => {
  return (
    <div className={classes.main}>
      <div className={classes.top}></div>
      <div className={classes.bottom}></div>
      <div className={classes.right}></div>
      <div className={classes.left}></div>
      <div className={classes.center}></div>
    </div>
  );
};

export default VaProjects;
