import classes from "./SeeMoreItem.module.css";
import icons from "../../../resources/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import * as navigationLinks from "../../../utils/links";
import { BENEFITS, BRANDS, BRANCHES, RECIPES, RESTAURANTS, BLOGS, EMPLOYERS } from "../../../resources/config";

const SeeMoreItem = ({ entityType, style, onItemClick }) => {
  const navigate = useNavigate();

  const getImageByEntity = () => {
    switch (entityType) {
      case RESTAURANTS:
        return icons.restaurantsEntity;
      case BRANCHES:
        return icons.businessesEntity;
      case RECIPES:
        return icons.recipesEntity;
      case BENEFITS:
        return icons.benefitsEntity;
      case BRANDS:
        return icons.productsEntity;
      case BLOGS:
        return icons.blogsEntity; 
      case EMPLOYERS: 
        return icons.employersEntity
    }
  };

  const getItemHeight = () => {
    switch (entityType) {
      case RESTAURANTS:
      case BRANCHES:
      case BENEFITS:
        return "24rem";
      case RECIPES:
        return "28rem";
      case BRANDS: case BLOGS: case EMPLOYERS:
        return "24rem";
    }
  };

  return (
    <div
      key="see_more"
      className={` ${style} ${classes.mainContainer}  `}
      style={{ height: getItemHeight() }}
      onClick={onItemClick}
    >
      <div className={classes.imageContainer}>
        <img className={classes.img} src={getImageByEntity()} />
      </div>
      <p className={classes.seeMoreTitle}>
        {t("see_more")} {">"}
      </p>
    </div>
  );
};

export default SeeMoreItem;
