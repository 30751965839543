import { Navigate } from "react-router";
import { Business, Restaurant, Recipe } from "../../pages";
import { linkToBusiness, linkToRecipe, linkToRestaurant } from "../links";
import urlJson from "./oldWebsiteLinks.json";

export const getRestaurantElement = (oldWebsiteLink) => {
  if (checkIfOldWebsite()) {
    return <Navigate to={oldWebsiteLink} />;
  } else {
    return <Restaurant />;
  }
};

export const getBusinessElement = (oldWebsiteLink) => {
  if (checkIfOldWebsite()) {
    return <Navigate to={oldWebsiteLink} />;
  } else {
    return <Business />;
  }
};

export const getRecipeElement = (oldWebsiteLink) => {
  if (checkIfOldWebsite()) {
    return <Navigate to={oldWebsiteLink} />;
  } else {
    return <Recipe />;
  }
};

export const checkIfOldWebsite = () => {
  const params = window.location.pathname.split("/");
  if (params.length > 2) {
    const id = decodeURI(params[2]);
    return isNumeric(id);
  }
  return false;
};

function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export const getOldWebsiteLink = () => {
  const params = window.location.pathname.split("/");
  if (checkIfOldWebsite()) {
    if (params.length > 1) {
      const path = decodeURI(params[1]);

      switch (path) {
        case "restaurant":
        case "מסעדה":
          return getRestaurantLink(params);
        case "businness":
        case "בית-עסק":
        case "נותן-שירות":
          return getBusinessLink(params);
        case "recipe":
        case "מתכון-טבעוני":
          return getRecipeLink(params);
      }
    }
  }
  return "/about";
};

const getRestaurantLink = (params) => {
  if (params.length > 2) {
    const restaurantId = params[2];
    const restaurant = [...urlJson.restaurants, ...urlJson.chains].find(
      (r) => r.legacy_id == restaurantId
    );
    if (restaurant.type === "RESTAURANT") {
      return linkToRestaurant(restaurant.id, restaurant.name);
    } else {
      return `../search?entity=restaurants&chainId=${restaurant.id}`;
    }
  }
};

const getBusinessLink = (params) => {
  if (params.length > 2) {
    const businessId = params[2];
    const business = [...urlJson.businesses].find((r) => r.legacy_id == businessId);
    return linkToBusiness(business.id, business.name);
  }
};

const getRecipeLink = (params) => {
  if (params.length > 2) {
    const recipeId = params[2];
    const recipe = [...urlJson.recipes].find((r) => r.legacy_id == recipeId);
    return linkToRecipe(recipe.id, recipe.name);
  }
};
