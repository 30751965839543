import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import classes from "./SearchModal.module.css";
import Modal from "react-bootstrap/Modal";

import { getCurrentLocale } from "../../utils/utilFunctions";
import icons, { iconsAlts } from "../../resources/icons";
import HomepageSearch from "../../components/HomePage/HomePageSearch/HomepageSearch";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import * as entitiesActions from "../../actions/entitiesActions";
import { useEffect } from "react";

const SearchModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const userLocation = useSelector((state) => state.user.userLocation);

  return (
    <Modal
      show={show}
      contentClassName={classes.mainContainer}
      backdropClassName={classes.backdrop}
      onHide={onHide}
      size={"lg"}
    >
      <img
        className={getCurrentLocale() === "he" ? classes.exitButtonIL : classes.exitButton}
        src={icons.closeXWhite}
        onClick={onHide}
        alt={iconsAlts.closeXWhite}
      />
      <HomepageSearch
        onFocus={() => {}}
        userLocation={userLocation}
        placeholder={t("search_bar_place_holder")}
        isResultsAlwaysOpen={true}
        onResultsClickExtraAction={onHide}
        onEnter={onHide}
      />
    </Modal>
  );
};

export default SearchModal;
