import classes from "./ErrorableTextArea.module.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import { t } from "i18next";

const ErrorableTextArea = ({
  id,
  type,
  onChange,
  onBlur,
  value,
  placeholder,
  hasError,
  errorMessage,
  minChars = 0,
}) => {
  return (
    <div className={classes.inputContainer}>
      <textarea
        id={id}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
        className={hasError ? classes.inputError : classes.input}
      />
      <div className={classes.row}>
        {hasError && <p className={classes.errorMessage}>{errorMessage}</p>}
        {minChars > 0 && (
          <p className={classes.minChars}>
            {value ? value.trim().length + " " + t("chars") : ""} {t("min_chars")}
          </p>
        )}
      </div>
    </div>
  );
};

export default ErrorableTextArea;
