import { json } from "react-router-dom";

const transformRange = (value, r1, r2) => {
  const scale = (r2.max - r2.min) / (r1.max - r1.min);
  const result = (value - r1.min) * scale;
  return result;
};

export const sortResultsByLocationWithScore = (results) => {
  const filteredResults = results.filter((r) => r.item.distance).map((r) => r.item.distance);
  const min = Math.min(...filteredResults);
  const max = Math.max(...filteredResults);

  results.map((result, index) => {
    // Transform score to normalizate the effect of distance
    const score = 100 / Math.log(1 / result.score);
    result.score = score;

    // Transform the distance so that it has proportionate effect compared to the fuse result
    const distanceScore = transformRange(
      result.item.distance,
      { max: max, min: min },
      { max: 100, min: 1 }
    );
    if (distanceScore) {
      result.newScore = score + Number(distanceScore);
    }
    addScoreForCertified(result);
  });
  results.sort((a, b) => {
    return a.newScore - b.newScore;
  });

  return results;
};

export const addScoreForCertified = (result) => {
  if (result.item.is_certified) {
    result.newScore = result.newScore * 0.9;
  }
};

export const getFuseOptions = (keys) => {
  return {
    keys: keys,
    includeScore: true,
    threshold: 0.3
  };
};

const wSearchNames = 1;
const wRestaurantCategory = 1;
const wBusinessCategory = 1;
const wRecipeCategory = 1;
const wCompanyCategory = 1;
const wBlogCategory = 1;
const wEmployerCategory = 1;
const wBusinessName = 0.9;
const wChainName = 0.9;
const wBranchName = 0.8;
const wRestaurantName = 0.9;
const wRecipeName = 0.8;
const wCompanyName = 0.6;
const wBlogName = 0.6;
const wEmployerName = 0.6;
const wAddress = 0.4;
const wCategories = 0.7;
const wTagName = 0.3;
const wCategoriesSearchNames = 0.2;
const wAdditionalSearchNames = 0.1;
const wCertified = 1;

export const homepageKeys = [
  {
    name: "restaurant_category",
    weight: wRestaurantCategory,
  },
  {
    name: "business_category",
    weight: wBusinessCategory,
  },
  {
    name: "recipe_category",
    weight: wRecipeCategory,
  },
  {
    name: "company_category",
    weight: wCompanyCategory,
  },
  {
    name: "blog_category",
    weight: wBlogCategory,
  },
  {
    name: "employer_category",
    weight: wEmployerCategory,
  },
  {
    name: "business_name",
    weight: wBusinessName,
  },
  {
    name: "chain_name",
    weight: wChainName,
  },
  {
    name: "branch_name",
    weight: wBranchName,
  },
  {
    name: "restaurant_name",
    weight: wRestaurantName,
  },
  {
    name: "recipe_name",
    weight: wRecipeName,
  },
  {
    name: "company_name",
    weight: wCompanyName,
  },
  {
    name: "blog_name",
    weight: wBlogName,
  },
  {
    name: "employer_name",
    weight: wEmployerName,
  },
  {
    name: "address",
    weight: wAddress,
  },
  {
    name: "categories",
    weight: wCategories,
  },
  {
    name: "categories_search_names",
    weight: wCategoriesSearchNames,
  },
  {
    name: "additional_search_names",
    weight: wAdditionalSearchNames,
  },
  {
    name: "search_names",
    weight: wSearchNames,
  },
  {
    name: "tag_name",
    weight: wTagName,
  }
];

export const restaurantKeys = [
  {
    name: "name",
    weight: wRestaurantName,
  },
  {
    name: "search_names",
    weight: wSearchNames,
  },
  {
    name: "address",
    weight: wAddress,
  },


];

export const businessKeys = [
  {
    name: "name",
    weight: wBusinessName,
  },
  {
    name: "address",
    weight: wAddress,
  },
  ,
  {
    name: "search_names",
    weight: wSearchNames,
  },
  {
    name: "business_subcats.category_name",
    weight: wCategories,
  },
  {
    name: "business_subcats.search_names",
    weight: wCategoriesSearchNames,
  },
  {
    name: "business_categories.search_names",
    weight: wAdditionalSearchNames,
  },
  {
    name: "tags.name",
    weight: wTagName,
  },
];

export const recipeKeys = [
  {
    name: "name",
    weight: wRecipeName,
  },
  {
    name: "search_names",
    weight: wSearchNames
  }
];

export const benefitKeys = [
  {
    name: "restaurant.name",
    weight: wRestaurantName,
  },
  {
    name: "restaurant.address",
    weight: wAddress,
  },
  {
    name: "restaurant.categories.category_name",
    weight: wCategories,
  },
  {
    name: "restaurant.categories.search_names",
    weight: wCategoriesSearchNames,
  },
  {
    name: "restaurant.tags.name",
    weight: wTagName,
  },
  {
    name: "branch.name",
    weight: wBranchName,
  },
  {
    name: "branch.address",
    weight: wAddress,
  },
  {
    name: "branch.categories.category_name",
    weight: wCategories,
  },
  {
    name: "branch.categories.search_names",
    weight: wCategoriesSearchNames,
  },
  {
    name: "branch.tags.name",
    weight: wTagName,
  },
];

export const companiesKeys = [
  {
    name: "name",
    weight: wCompanyName,
  },
  {
    name: "search_names",
    weight: wSearchNames,
  },
  {
    name: "subCategories.category_name",
    weight: wCategories,
  },
];

export const blogsKeys = [
  {
    name: "name",
    weight: wBlogName,
  },
  {
    name: "search_names",
    weight: wSearchNames,
  },
  {
    name: "categories.category_name",
    weight: wCategories,
  },
];


export const employersKeys = [
  {
    name: "name",
    weight: wEmployerName,
  },
  {
    name: "search_names",
    weight: wSearchNames,
  },
  {
    name: "categories.category_name",
    weight: wCategories,
  },
];
