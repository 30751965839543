import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useParams, useNavigate } from "react-router-dom";
import * as entitiesActions from "../../actions/entitiesActions";
import ReactMarkdown from "react-markdown";
import { logEvents } from "../../eventsManager";
import classes from "./Employer.module.css";
import Skeleton from "react-loading-skeleton";
import { EntityHeader, EntityReviews } from "../../components/EntityPages";
import { employerUtils } from "../../utils/entityUtils";
import { t } from "i18next";
import { BusinessSocial } from "../../components/EntityPages/BusinessAndRestaurant";

const EMPLOYERS = "employers";

const Employer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const employer = useSelector((state) => state.employer.fullEmployer);
  const entityCategories = useSelector((state) => state.entities.entityCategories);

  const [preparedEmployer, setPreparedEmployer] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [employerEventProps, setEmployerEventProps] = useState({});
  const { employerId } = useParams();


  useEffect(() => {
    entitiesActions.getFullEmployer(dispatch, employerId).catch(() => {
      navigate("/not-found"); 
    });
    
    window.scrollTo(0, 0);

  }, []);


  const getEmployerEventsProps = (employer) => {
    return {
      employer_id: employer.id,
      entity_type: EMPLOYERS,
      employer_name: employer.name
    };
  };

  useEffect(() => {

    if (employer && entityCategories && entityCategories.employers && employer.id && employer.id == employerId) {
      const prepared = employerUtils.prepareEmployer(employer, entityCategories.employers);
      setPreparedEmployer(prepared);
      const props = getEmployerEventsProps(prepared);
      logEvents("screen_employer_open", props);
      setEmployerEventProps(props);
      setIsLoading(false);
    }

  }, [employer, entityCategories]);

  const componentToPrint = useRef();

  function formatLineBreaks(inputText) {
    // Replace multiple consecutive line breaks with a single line break and &nbsp;
    const formattedText = inputText.replace(/(\n\s*){2,}/g, '\n&nbsp;\n');
    return formattedText;
  }

  const reactToPrintContent = useCallback(() => {
    return componentToPrint.current;
  }, [componentToPrint.current]);

  const employerDescription = preparedEmployer && preparedEmployer.description ? formatLineBreaks(preparedEmployer.description) : '';

  return (
    <div className={classes.mainContainer} ref={componentToPrint}>
      <EntityHeader
        entityType={EMPLOYERS}
        className={classes.headerContainer}
        title={employer.name}
        thumbnail={preparedEmployer.logo ? preparedEmployer.logo : ""}
        categories={preparedEmployer.employer_categories}
        isLoading={isLoading}
        coverImages={preparedEmployer.pics ? preparedEmployer.pics.map((p) => p.url) : []}
        componentToPrint={reactToPrintContent}
        eventProps={employerEventProps}
      />

      <p className={classes.description}>{employerDescription}</p>
      {!isLoading && (
        <BusinessSocial
          className={classes.socialsContainer}
          restaurantName={preparedEmployer.name}
          websiteLink={preparedEmployer.website_url}
          instagramLink={preparedEmployer.instagram_url}
          facebookLink={preparedEmployer.facebook_url}
          linkedinLink={preparedEmployer.linkedin_url}
        />
      )}
    </div>)
};

export default Employer;
