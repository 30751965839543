import classes from "./BenefitItem.module.css";
import { useNavigate } from "react-router-dom";
import * as navigationLinks from "../../../utils/links";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { RESTAURANTS } from "../../../resources/config";
import icons from "../../../resources/icons";
import { t } from "i18next";
import BenefitModal from "../../../modals/BenefitModal/BenefitModal";
import { useState } from "react";

const BenefitItem = ({ benefit, style, isLoading = true }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const entity =
    benefit && benefit.entity_type
      ? benefit.entity_type === RESTAURANTS
        ? benefit.restaurant
        : benefit.branch
      : null;

  const onBenefitClick = () => {
    if (!isModalOpen) {
      setModalOpen(true);
    }
  };

  const getBenefitTags = () => {
    const tags = [];
    if (benefit.entity_type === RESTAURANTS) {
      tags.push({
        name: t("restaurant_benefit"),
        icon: icons.restaurantBenefits,
      });
    } else {
      tags.push({
        name: t("business_benefit"),
        icon: icons.businessBenefits,
      });
    }
    if (benefit.has_delivery) {
      tags.push({
        name: t("delivery"),
        icon: icons.deliveryTag,
      });
    }
    return tags;
  };

  return (
    <div className={`${style} ${classes.mainContainer} `} onClick={onBenefitClick}>
      <div className={classes.imageContainer}>
        {isLoading || !entity ? (
          <Skeleton
            height="100%"
            style={{
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
            }}
          />
        ) : (
          <img className={classes.image} src={entity.logo} />
        )}
      </div>
      <div className={classes.contentContainer}>
        <p className={classes.benefitTitle}>
          {isLoading ? <Skeleton borderRadius="20px" width="80%" /> : benefit.description}
        </p>
        <p className={classes.benefitDescription}>
          {isLoading ? <Skeleton borderRadius="20px" width="80%" /> : entity ? entity.name : ""}
        </p>
        <div className={classes.tagsContainer} data-testid="tagsContainer">
          {getBenefitTags().map((tag) => (
            <div className={classes.tag} key={tag.name}>
              <img className={classes.tagImg} src={tag.icon} />
              <p className={classes.tagName}>{tag.name}</p>
            </div>
          ))}
        </div>
      </div>
      <BenefitModal show={isModalOpen} onHide={() => setModalOpen(false)} benefit={benefit} />
    </div>
  );
};

export default BenefitItem;
