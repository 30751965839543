import {
  SET_USER_LOCATION,
  SET_LOCATION_PERMISSIONS,
  SET_USER,
  SET_PENDING_REVIEWS,
  ADD_NEW_PENDING_REVIEW,
  LOCATION_ERROR,
} from "../actions/userActions";

const defaultState = {
  user: null,
  userPendingReviews: null,
  userLocation: null,
  hasLocationPermissions: false,
  locationError: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
  }
  switch (action.type) {
    case SET_USER_LOCATION:
      return {
        ...state,
        userLocation: action.payload,
      };
    case SET_LOCATION_PERMISSIONS:
      return {
        ...state,
        hasLocationPermissions: action.payload,
      };
    case SET_PENDING_REVIEWS:
      return {
        ...state,
        userPendingReviews: action.payload,
      };
    case ADD_NEW_PENDING_REVIEW:
      return {
        ...state,
        userPendingReviews: [action.payload, ...state.userPendingReviews],
      };
    case LOCATION_ERROR:
      return {
        ...state,
        locationError: action.payload,
      };
  }

  return state;
};
