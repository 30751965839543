import classes from "./PageNotFound.module.css";
import images, { imagesAlts } from "../../resources/images";
import { PrimaryButton } from "../../components/Common";
import { useNavigate } from "react-router-dom";
import { linkToMainPage } from "../../utils/links";
import { t } from "i18next";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.mainContainer}>
      <img className={classes.image} src={images.page404} alt={imagesAlts.page404} />
      <p className={classes.text}>{t("404page")}</p>
      <PrimaryButton
        title={t("back_to_main")}
        isSlim={true}
        onClick={() => navigate(linkToMainPage())}
      />
    </div>
  );
};

export default PageNotFound;
