import classes from "./FooterList.module.css";
import { t } from "i18next";
import { Link, useNavigate } from "react-router-dom";
import * as links from "../../utils/links";
import { RESTAURANTS, BRANCHES, RECIPES, BENEFITS, BRANDS, BLOGS, EMPLOYERS } from "../../resources/config";
import MediaButtons from "../Media/MediaButtons/MediaButtons";
import { logEvents } from "../../eventsManager";

const FooterList = ({
  title,
  list,
  isMedia,
  className,
  newsLetterHandler,
  contactUsHandler,
  certifiedHandler,
}) => {
  const navigate = useNavigate();

  const linkClickHandler = (item) => {
    switch (item.action) {
      case "getCertified":
        certifiedHandler();
        break;
      case "restaurants":
        navigate(links.linkToSearch({ entityType: RESTAURANTS }));
        break;
      case "branches":
        navigate(links.linkToSearch({ entityType: BRANCHES }));
        break;
      case "companies":
        navigate(links.linkToSearch({ entityType: BRANDS }));
        break;
      case "blogs":
          navigate(links.linkToSearch({ entityType: BLOGS }));
          break;
      case "employers":
          navigate(links.linkToSearch({ entityType: EMPLOYERS }));
          break;
      case "recipes":
        navigate(links.linkToSearch({ entityType: RECIPES }));
        break;
      case "benefits":
        navigate(links.linkToSearch({ entityType: BENEFITS }));
        break;
      case "donate":
        links.linkToExternalPage(links.URLS.donate);
        break;
      case "joinVeganActive":
        links.linkToExternalPage(links.URLS.vegan_active_homepage);
        break;
      case "aboutUs":
        navigate(links.linkToAbout());
        break;
      case "criteria-for-certification":
          navigate(links.linkToCriteria());
          break;
      case "volunteer":
        process.env.REACT_APP_COUNTRY === "US" ? links.linkToExternalPage("https://forms.monday.com/forms/7b025018c60b85488f10fd8578f67ac5?r") : contactUsHandler();
        break;
      case "careers":
        links.linkToExternalPage(links.URLS.careers);
        break;
      case "newsletter":
        newsLetterHandler();
        break;
      case "termsOfService":
        links.linkToExternalPage(links.URLS.termsOfUse);
        break;
      case "privacyPolicy":
        links.linkToExternalPage(links.URLS.privacyPolicy);
        break;
      case "accessibility":
        navigate(links.linkToAccessibility());
        break;
    }

    logEvents("header_and_footer_click", { button_name: item.label });
  };

  return isMedia ? (
    <div className={`${classes.mainContainer} ${className}`}>
      <h4 className={classes.titleText}>{t(title)}</h4>

      <MediaButtons
        className={classes.mediaButtonsTop}
        facebookLink={links.URLS.facebook}
        linkedinLink={links.URLS.linkedin}
        tiktokLink={links.URLS.tiktok}
        instagramLink={links.URLS.instagram}
      />
    </div>
  ) : (
    <div className={`${classes.mainContainer} ${className}`}>
      <h4 className={classes.titleText}>{t(title)}</h4>

      <div className={classes.itemsContainer}>
        {list &&
          list.map((item) => (
            <a key={item.label} className={classes.itemText} onClick={() => linkClickHandler(item)}>
              {t(item.label)}
            </a>
          ))}
      </div>
    </div>
  );
};

export default FooterList;
