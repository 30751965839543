import classes from "./EntityList.module.css";

import { useNavigate } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import BusinessesItem from "../../EntityListItems/BusinessesItem";
import { RESTAURANTS } from "../../../../resources/config";
import PrimaryButton from "../../../Common/PrimaryButton/PrimaryButton";
import { t } from "i18next";
import InfiniteScroll from "react-infinite-scroller";
import { useState } from "react";

const EntityList = ({
  entityType,
  entities,
  isLoading,
  isNavigateToEntityEnabled = false,
  onNavigateToEntity,
  onEntityHover,
  hoveredItem,
  isChain,
}) => {
  const chainName = isChain && entities && entities.length > 0 ? entities[0].chainName : null;

  return (
    <div className={classes.mainContainer}>
      {chainName && (
        <h4 className={classes.searchTitle}>
          {t("search_for_branches")}
          {chainName}
          {t("for_branches")}
        </h4>
      )}
      <div className={classes.listContainer}>
        {entities &&
          entities.length &&
          entities.map((entity, index) => (
            <BusinessesItem
              entity={entity}
              type={entityType}
              isLoading={isLoading}
              key={entity.branch_id ? entity.branch_id : entity.id ? entity.id : index}
              isHovered={hoveredItem && entity.id === hoveredItem.id}
              hoveredItem={hoveredItem}
              onEntityHover={onEntityHover}
            />
          ))}
      </div>
      {/* <InfiniteScroll
        useWindow={false}
        pageStart={0}
        loadMore={loadFunc}
        hasMore={hasMore}
        loader={
          <div className="loader" key={0}>
            Loading ...
          </div>
        }
      >
        {items.map((entity) => (
          <BusinessesItem
            entity={entity}
            type={entityType}
            isLoading={isLoading}
            key={entityType === RESTAURANTS ? entity.id : entity.branch_id}
            isHovered={hoveredItem && entity.id === hoveredItem.id}
            hoveredItem={hoveredItem}
            onEntityHover={onEntityHover}
          />
        ))}
      </InfiniteScroll> */}
      {isNavigateToEntityEnabled && (
        <button
          type="button"
          className={classes.seeAllBtn}
          onClick={() => onNavigateToEntity(entityType, 2)}
        >
          {t("show_all") + t(entityType)}
        </button>
      )}
    </div>
  );
};

export default EntityList;
