import { SET_FULL_BLOG } from "../actions/entitiesActions";

const defaultState = {
  fullBlog: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_FULL_BLOG:
        return {
          ...state,
          fullBlog: action.payload,
        };
  }
  return state;
};
