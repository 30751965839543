import MapView from "../../../Map/MapView";
import EntityWrongInfo from "../../EntityWrongInfo/EntityWrongInfo";
import classes from "./BusinessMap.module.css";

const BusinessMap = ({ entity, className, isLoading, lastUpdate }) => {
  return (
    <div className={ `${classes.mainContainer} ${className}` }>
      { entity.location && (
        <div className={ classes.map } id='branch_map_container'>
          <MapView
            center={ entity.location }
            zoom={ 13 }
            markers={ [entity] }
            showLocationRequest={ false }
            isLoading={ isLoading }
          />
        </div>
      ) }
      { !isLoading && (
        <EntityWrongInfo
          className={ classes.wrongInfoContainer }
          entityName={ entity.business ? entity.business.name : entity.restaurant_name }
          lastUpdate={ lastUpdate }
        />
      ) }
    </div>
  );
};

export default BusinessMap;
