import classes from "./ContactUsSuccessModal.module.css";
import Modal from "react-bootstrap/Modal";
import { t } from "i18next";

import { PrimaryButton } from "../../../components/Common";
import images, { imagesAlts } from "../../../resources/images";

const ContactUsSuccessModal = ({ show, onHide, caseNumber }) => {
  return (
    <Modal
      id="contact-us-success-modal"
      show={show}
      contentClassName={classes.mainContainer}
      onHide={onHide}
      size={"sm"}
      centered
    >
      <div className={classes.contentContainer}>
        <img className={classes.cowImg} src={images.contactUsGirl} alt={imagesAlts.contactUsGirl} />
        <p className={classes.title}>{t("contact_us__suc_modal_title")}</p>
        <p className={classes.subTitle}>{t("contact_us_suc_modal_subtitle")}</p>
        <p className={classes.caseNumber}>
          {t("contact_us_suc_modal_case")} {caseNumber}
        </p>
        <PrimaryButton
          id="contact-us-success-modal-exit"
          className={classes.button}
          title={t("contact_us_suc_modal_back")}
          onClick={onHide}
          isBig={true}
        />
      </div>
    </Modal>
  );
};

export default ContactUsSuccessModal;
