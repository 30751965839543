import classes from "./EmployersItem.module.css";
import { useNavigate } from "react-router-dom";
import * as navigationLinks from "../../../utils/links";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import images from "../../../resources/images";

const EmployersItem = ({ data, style, isLoading = true }) => {
  const employer = data;
  const navigate = useNavigate();

  const onEmployerClick = () => {
    navigate(navigationLinks.linkToEmployer(data.id, data.name));
  };

  return (
    <div className={`${style} ${classes.mainContainer} `} onClick={onEmployerClick}>
      <div className={classes.imageContainer}>
        {isLoading ? (
          <Skeleton
            height="100%"
            style={{
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
            }}
          />
        ) : (
          <img
            className={classes.image}
            src={employer.logo ? employer.logo : images.defaultEntityLogo}
            alt="employer picture"
          />
        )}
      </div>
      <div className={classes.contentContainer}>
        <p className={classes.employerTitle}>
          {isLoading ? <Skeleton borderRadius="20px" width="80%" /> : employer.name}
        </p>
        <p className={classes.employerDescription}>{employer.description ? employer.description : employer.content ? employer.content.slice(0, 100) : ''}</p>
        {employer.employer_categories && (
          <div className={classes.tagContainer}>
            {employer.employer_categories.map((category, index, row) => (
              <p className={classes.tag} key={category.category_name}>
                {category.category_name}
                {index !== row.length - 1 ? "," : ""}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployersItem;
