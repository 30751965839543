import classes from "./NewsModal.module.css";
import Modal from "react-bootstrap/Modal";
import { t } from "i18next";
import PrimaryButton from "../../components/Common/PrimaryButton/PrimaryButton";
import images from "../../resources/images";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { s3 } from "../../resources/config";
import { Carousel } from "react-responsive-carousel";
import icons, { iconsAlts } from "../../resources/icons";
import { getCurrentCountry, getCurrentLocale } from "../../utils/utilFunctions";
import { linkToExternalPage, linkToInternalPage } from "../../utils/links";
import * as dateUtils from "../../utils/dateUtils";
import { logEvents } from "../../eventsManager";

const NewsModal = ({ show, onHide, newsId }) => {
  const news = useSelector((state) => state.entities.newsEntities);
  const [sortedNew, setSortedNews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);

  const getNewsEventsProps = (currentNews) => {
    return {
      business_id: currentNews.id,
      business_type: "news",
      business_name: currentNews.title,
    };
  };

  useEffect(() => {
    if (currentIndex != null) {
      const currentNews = news[currentIndex];
      logEvents("screen_business_open", getNewsEventsProps(currentNews));
    }
  }, [currentIndex]);

  const getSelectedIndex = () => {
    if (news && show) {
      let sortedNews = news;

      if (getCurrentCountry() == "IL") {
        sortedNews = [...news].reverse();
      }

      const current = sortedNews.find((news) => news.id === newsId);
      const index = sortedNews.indexOf(current);

      if (index != -1) {
        return index;
      } else {
        return 0;
      }
    }
  };

  useEffect(() => {
    if (news && show) {
      let sortedNews = news;

      if (getCurrentCountry() == "IL") {
        sortedNews = [...news].reverse();
      }

      const current = sortedNews.find((news) => news.id === newsId);
      const index = sortedNews.indexOf(current);
      if (index != -1) {
        setCurrentIndex(index);
      } else {
        setCurrentIndex(0);
      }
      setSortedNews(sortedNews);
    }
  }, [show, newsId, news]);

  const arrowPrevHandler = (clickHandler, _, label) => {
    let hasPrev;
    hasPrev = currentIndex !== 0;

    return hasPrev ? (
      <button type="button" className={`${classes.previousButton}`} onClick={clickHandler}>
        <p className={classes.buttonText}>
          {t("previous_news")}: {sortedNew[currentIndex - 1].title}
        </p>
        <img
          className={classes.buttonImage}
          src={icons.newsLeftButton}
          alt={iconsAlts.newsLeftButton}
        />
      </button>
    ) : (
      <></>
    );
  };

  const arrowNextHandler = (clickHandler, _, label) => {
    let hasNext;
    hasNext = news.length - currentIndex - 1;

    return hasNext ? (
      <button
        type="button"
        className={`${classes.nextButton}`}
        onClick={() => {
          clickHandler();
        }}
      >
        <p className={classes.buttonText}>
          {t("next_news")}: {sortedNew[currentIndex + 1].title}
        </p>
        <img className={classes.buttonImage} src={icons.newsRightButton} />
      </button>
    ) : (
      <></>
    );
  };

  const getDirection = () => {
    return getCurrentLocale() == "he" ? classes.directions : "";
  };

  const onChangeHandler = (index) => {
    setCurrentIndex(index);
  };

  const handleButtonClick = (currentNews) => {
    if (currentNews.is_external_link == true) {
      linkToExternalPage(currentNews.link);
    } else {
      linkToInternalPage(currentNews.link);
    }
  };

  return (
    <Modal
      show={show}
      contentClassName={classes.modalContainer}
      backdropClassName={classes.backdrop}
      onHide={onHide}
      size={"xl"}
      centered
      dialogClassName={classes.modalClass}
    >
      <img
        className={getCurrentLocale() === "he" ? classes.exitButtonIL : classes.exitButton}
        src={icons.closeXWhite}
        onClick={onHide}
        alt={iconsAlts.closeXWhite}
      />
      <Carousel
        className={classes.carousel}
        autoPlay={false}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        dynamicHeight={false}
        swipeable={false}
        renderArrowPrev={arrowPrevHandler}
        renderArrowNext={arrowNextHandler}
        selectedItem={getSelectedIndex()}
        onChange={onChangeHandler}
      >
        {sortedNew.map((currentNews) => (
          <div className={`${classes.mainContainer} ${getDirection()}`}>
            <img
              className={classes.newsImage}
              src={
                currentNews.pics && currentNews.pics.length > 0
                  ? s3 + currentNews.pics[0]
                  : currentNews.logo
                  ? s3 + currentNews.logo
                  : images.newsCover
              }
              alt="news image"
            />
            <div className={classes.contentContainer}>
              <div className={`${classes.textContainer}  ${"hideScrollBars"}`}>
                {currentNews.link && (
                  <PrimaryButton
                    title={currentNews.button_text}
                    onClick={() => handleButtonClick(currentNews)}
                    isLight={true}
                    isSlim={false}
                    className={classes.mobileButton}
                  />
                )}
                {currentNews.publish_date && (
                  <p className={classes.date}>
                    {dateUtils.formatDateMonthLong(currentNews.publish_date)}
                  </p>
                )}
                <p className={classes.title}>{currentNews.title}</p>
                <p className={classes.subtitle}> {currentNews.subtitle}</p>
                {currentNews.categories && currentNews.categories.length > 0 && (
                  <p className={classes.tags}>
                    #
                    {currentNews.categories
                      .map((c) => t(c.type))
                      .join(" #")
                      .toLowerCase()}
                  </p>
                )}
                <p className={`${classes.description}`}>
                  {currentNews.description}
                  {/* <img
                    className={classes.veganActiveLogoMobile}
                    src={images.logoVeganActiveSmall}
                  /> */}
                </p>
              </div>
            </div>
            <div className={classes.buttonContainer}>
                {currentNews.link && (
                  <PrimaryButton
                    title={currentNews.button_text}
                    onClick={() => handleButtonClick(currentNews)}
                    isLight={true}
                    isSlim={true}
                  />
                )}
              </div>
          </div>
        ))}
      </Carousel>
    </Modal>
  );
};

export default NewsModal;
