import * as entitiesUtils from "./entitiesUtils";
import * as scheduleUtils from "./scheduleUtils";
import { s3 } from "../../resources/config";
import { cleanSearchNames } from "../../components/HomePage/HomePageSearch/searchResultsPreparation";

/* Preparing business entity - adding full categories tags and add missing fields */
export function prepareBusinessBranch(
  businessBranch,
  businessSubCategories,
  entityTags,
  userLocation,
  businessCategories = null
) {
  const preparedBranch = JSON.parse(JSON.stringify(businessBranch));
  if (preparedBranch.location && preparedBranch.location.name) {
    preparedBranch.name = businessBranch.name + "-" + preparedBranch.location.name;
  }

  preparedBranch.logo = s3 + businessBranch.logo;

  preparedBranch.pics =
    businessBranch.pics && businessBranch.pics.length > 0
      ? businessBranch.pics
      : businessBranch.menu &&
        businessBranch.menu.food_pics &&
        businessBranch.menu.food_pics.length > 0
      ? businessBranch.menu.food_pics
      : [];

  preparedBranch.rating = entitiesUtils.numberToFixed(preparedBranch.rating);

  //Businesses categories
  preparedBranch.business_subcats = entitiesUtils.prepareFullCategories(
    businessBranch.business_subcats,
    businessSubCategories
  );

  preparedBranch.business_categories = entitiesUtils.prepareFullCategories(
    businessBranch.business_categories,
    businessCategories
  );

  //Entity tags
  preparedBranch.tags = entitiesUtils.prepareFullTags(businessBranch.tags, entityTags);

  preparedBranch.distance = entitiesUtils.calculateDistance(businessBranch.location, userLocation);
  preparedBranch.is_open = scheduleUtils.checkIfEntityIsOpen(businessBranch);
  entitiesUtils.addTagsFieldToEntity(preparedBranch);
  preparedBranch.has_discount =
    preparedBranch.is_certified &&
    preparedBranch.discount_list &&
    preparedBranch.discount_list.length > 0;


    preparedBranch.search_names = [...cleanSearchNames(businessBranch.search_names), ...preparedBranch.business_categories.map(b => cleanSearchNames(b.search_names)).flat()];
  return preparedBranch;
}

export const prepareBusinessBranchesList = (businessList) => {
  let businessBranches = [];
  businessList.filter((business) => business.branches && business.branches.length > 0);
  businessList.map((business) => {
    businessBranches = businessBranches.concat(prepareBusinessBranches(business));
  });
  return businessBranches;
};

export const prepareBusinessBranches = (business) => {
  const businessBranches = [];
  business.branches.map((branch) => {
    businessBranches.push({
      ...business,
      branch_id: branch.id,
      location: branch.location,
      address: branch.address,
      timezone_offset: branch.timezone_offset,
      schedule: branch.schedule,
      rating: branch.rating,
      reviews: branch.num_of_reviews,
    });
  });

  return businessBranches;
};
