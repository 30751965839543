import classes from "./MediaButtons.module.css";
import PropTypes from "prop-types";
import icons, { iconsAlts } from "../../../resources/icons";
import * as links from "../../../utils/links";

const MediaButtons = (props) => {
  const onClickHandler = (link) => {
    links.linkToExternalPage(link);
  };

  return (
    <div className={`${classes.row} ${props.className}`}>
      {props.text && (
        <h6 className={classes.headerGreenTitle} style={{ color: props.textColor }}>
          {props.text}
        </h6>
      )}
      {props.facebookLink && (
        <img
          className={classes.headerIcon}
          src={icons.facebookGreen}
          alt={iconsAlts.facebookGreen}
          onClick={() => onClickHandler(props.facebookLink)}
        />
      )}
      {props.linkedinLink && (
        <img
          className={classes.headerIcon}
          src={icons.linkedinGreen}
          alt={iconsAlts.linkedinGreen}
          onClick={() => onClickHandler(props.linkedinLink)}
        />
      )}
      {props.tiktokLink && (
        <img
          className={classes.headerIcon}
          src={icons.tiktokGreen}
          alt={iconsAlts.tiktokGreen}
          onClick={() => onClickHandler(props.tiktokLink)}
        />
      )}
      {props.instagramLink && (
        <img
          className={classes.headerIcon}
          src={icons.instagramGreen}
          alt={iconsAlts.instagramGreen}
          onClick={() => onClickHandler(props.instagramLink)}
        />
      )}
      {props.whatsappLink && (
        <img
          className={classes.headerIcon}
          src={icons.whatsappGreen}
          alt={iconsAlts.whatsappGreen}
          onClick={() => onClickHandler(props.whatsappLink)}
        />
      )}
    </div>
  );
};

MediaButtons.propTypes = {
  text: PropTypes.string,
  textColor: PropTypes.string,
  facebookLink: PropTypes.string,
  linkedinLink: PropTypes.string,
  tiktokLink: PropTypes.string,
  instagramLink: PropTypes.string,
  whatsappLink: PropTypes.string,
};

export default MediaButtons;
