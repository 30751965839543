import api from "../api";

export const SET_FULL_RECIPE = "SET_FULL_RECIPE";

export const getFullRecipe = async (dispatch, id) => {
  dispatch({
    type: SET_FULL_RECIPE,
    payload: {},
  });

  const response = await api.getFullRecipe(id);

  response.reviews.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  dispatch({
    type: SET_FULL_RECIPE,
    payload: response,
  });
};
