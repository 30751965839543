import {
  SET_FEATURED_INSTAGRAM_POST,
  SET_ABOUT_US_ENTITIES,
} from "../actions/generalActions";

const defaultState = {
  featuredInstagramPosts: [],
  aboutUsEntities: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_FEATURED_INSTAGRAM_POST:
      return { ...state, featuredInstagramPosts: action.payload };
    case SET_ABOUT_US_ENTITIES:
      return { ...state, aboutUsEntities: action.payload };
  }
  return state;
};
