import Fuse from "fuse.js";
import { useEffect, useRef, useState } from "react";
import { getFuseOptions } from "../../../utils/searchUtils/fuseUtils";
import SearchBar from "../SearchBar/SearchBar";
import classes from "./AutoCompleteSearch.module.css";

function AutoCompleteSearch({
  enteredInput,
  searchItems,
  keys,
  onSearch,
  formatResult,
  additionalResults,
  onFocus,
  placeholder,
  onEnter,
  onCloseResults,
  searchBarClassName,
  onResultsOpen,
  isResultsAlwaysOpen,
}) {
  const [searchResults, setSearchResults] = useState([]);
  const [fuse, setFuse] = useState(null);

  useEffect(() => {
    if (!isResultsAlwaysOpen) {
      try {
        window.addEventListener("click", function (e) {
          const clickBox = document.getElementById("clickbox");
          if (clickBox && clickBox.contains(e.target)) {
          } else {
            closeResultsHandler();
          }
        });
      } catch (ex) { }
    }
  }, []);

  useEffect(() => {
    const isOpen =
      (searchResults != null && searchResults.length > 0) ||
      (additionalResults != null && additionalResults.length > 0);

    onResultsOpen(isOpen);
  }, [searchResults, additionalResults]);

  useEffect(() => {
    if (searchItems) {
      initSearch();
    }
  }, [searchItems]);

  const initSearch = () => {
    const options = getFuseOptions(keys);
    setFuse(new Fuse(searchItems, options));
  };

  const search = (searchTerm) => {
    if (fuse) {
      let fuseResults = fuse.search(searchTerm);
      onSearch(fuseResults, searchTerm);
      if (additionalResults) {
        fuseResults = fuseResults.filter(
          (result) =>
            !additionalResults.some(
              (additional) =>
                additional.id === result.item.id &&
                additional.entity_type === result.item.entity_type
            )
        );
      }
      const finalResults = fuseResults.slice(0, 4);
      setSearchResults(finalResults);
    }
  };

  const onClear = () => {
    closeResultsHandler();
    onEnter("");
  };

  const closeResultsHandler = () => {
    onCloseResults();
    setSearchResults([]);
  };

  return (
    <div className={ classes.mainContainer } id="clickbox">
      <div className={ classes.wrapper }>
        <SearchBar
          enteredInput={ enteredInput }
          onSearch={ search }
          onFocus={ onFocus }
          placeholder={ placeholder }
          onCloseResults={ closeResultsHandler }
          onEnter={ (searchTerm) => {
            onEnter(searchTerm);
            closeResultsHandler();
          } }
          onClear={ onClear }
          searchBarClassName={ searchBarClassName }
        />
        { (searchResults || additionalResults) && (
          <div className={ classes.resultsContainer } onClick={ closeResultsHandler }>
            { additionalResults.map((result) => formatResult(result)) }
            { searchResults.map((result) => formatResult(result.item)) }
          </div>
        ) }
      </div>
    </div>
  );
}

export default AutoCompleteSearch;
