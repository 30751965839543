import * as entitiesUtils from "./entitiesUtils";
import { s3 } from "../../resources/config";
import { cleanSearchNames } from "../../components/HomePage/HomePageSearch/searchResultsPreparation";

/* Preparing business entity - adding full categories tags and add missing fields */
export function prepareEmployer(employer, employerCategories) {
  let preparedEmployer = JSON.parse(JSON.stringify(employer));
  preparedEmployer.logo = employer.logo ? s3 + employer.logo : "";
  preparedEmployer.employer_categories = entitiesUtils.prepareFullCategories(
    employer.employer_categories,
    employerCategories
  );

  preparedEmployer.search_names = [...cleanSearchNames(employer.search_names), ...preparedEmployer.employer_categories.map(e => cleanSearchNames(e.search_names)).flat()];

  return preparedEmployer;
}
