import { BRANCHES, RESTAURANTS } from "../../resources/config";
import * as entitiesUtils from "./entitiesUtils";
import * as scheduleUtils from "./scheduleUtils";
import { s3 } from "../../resources/config";
import config from "../../config";
import { restaurantSearchNames } from "../../resources/entities/entitySearchNames";
import images from "../../resources/images";
import { cleanSearchNames } from "../../components/HomePage/HomePageSearch/searchResultsPreparation";
/* Preparing restaurant entity - adding full categories tags and add missing fields */
export function prepareRestaurant(restaurant, restaurantCategories, entityTags, userLocation) {
  const preparedRestaurant = JSON.parse(JSON.stringify(restaurant));
  
  preparedRestaurant.logo = restaurant.logo ? s3 + restaurant.logo : images.defaultEntityLogo;

  preparedRestaurant.rating = entitiesUtils.numberToFixed(restaurant.rating);

  //Restaurant categories
  preparedRestaurant.rest_categories = entitiesUtils.prepareFullCategories(
    restaurant.rest_categories,
    restaurantCategories
  );

  preparedRestaurant.categories = preparedRestaurant.rest_categories;

  //Entity tags
  preparedRestaurant.tags = entitiesUtils.prepareFullTags(restaurant.tags, entityTags);

  preparedRestaurant.distance = entitiesUtils.calculateDistance(restaurant.location, userLocation);

  preparedRestaurant.is_open = scheduleUtils.checkIfEntityIsOpen(restaurant);

  entitiesUtils.addTagsFieldToEntity(preparedRestaurant);
  preparedRestaurant.has_discount =
    preparedRestaurant.is_certified &&
    restaurant.discount_list &&
    restaurant.discount_list.length > 0;

  // preparedRestaurant.vegan_options =
  //   config.currentCountry === "US" &&
  //   !preparedRestaurant.is_certified &&
  //   !preparedRestaurant.is_vegan;

  preparedRestaurant.search_names = [...cleanSearchNames(restaurant.search_names), ...preparedRestaurant.categories.map(r => cleanSearchNames(r.search_names)).flat()];
  preparedRestaurant.isPrepared = true;

  return preparedRestaurant;
}
