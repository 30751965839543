import config from "../../config";

import benefitTag from "../../assets/icons/tagIcons/greenTags/benefit.svg";
import openNowTag from "../../assets/icons/tagIcons/greenTags/open_now.svg";
import veganTag from "../../assets/icons/tagIcons/greenTags/vegan.svg";
import deliveryTag from "../../assets/icons/tagIcons/greenTags/delivery.svg";
import kosherTag from "../../assets/icons/tagIcons/greenTags/kosher.svg";
import certifiedTag from "../../assets/icons/tagIcons/greenTags/certified.svg";
import glutenFreeTag from "../../assets/icons/tagIcons/greenTags/gluten_free.svg";
import restaurantBenefits from "../../assets/icons/tagIcons/greenTags/restaurant_benefits.svg";
import businessBenefits from "../../assets/icons/tagIcons/greenTags/business_benefits.svg";
import veganOptions from "../../assets/icons/tagIcons/greenTags/vegan_options.svg";

export const ENTITY_TAGS = {
  benefit: {
    name: "benefit",
    label: "benefit",
    icon: benefitTag,
    info: "benefit_tooltip",
  },
  openNow: {
    name: "openNow",
    label: "open_now",
    icon: openNowTag,
    info: "open_now_tooltip",
  },
  vegan: {
    name: "vegan",
    label: "100_vegan",
    icon: veganTag,
    info: "vegan_tooltip",
  },
  delivery: {
    name: "delivery",
    label: "delivery",
    icon: deliveryTag,
    info: "delivery_tooltip",
  },
  kosher: {
    name: "kosher",
    label: "kosher",
    icon: kosherTag,
    info: "kosher_tooltip",
  },
  certified: {
    name: "certified",
    label: "certified",
    icon: certifiedTag,
    info: "certified_tooltip",
  },
  glutenFree: {
    name: "glutenFree",
    label: "gluten_free",
    icon: glutenFreeTag,
    info: "gluten_free_tooltip",
  },
  restaurantBenefit: {
    name: "restaurantBenefit",
    label: "restaurant_benefit",
    icon: restaurantBenefits,
    info: "",
  },
  businessBenefit: {
    name: "businessBenefit",
    label: "business_benefit",
    icon: businessBenefits,
    info: "",
  },
};

export const BUSINESSES_TAGS_US = [
  ENTITY_TAGS.openNow,
  ENTITY_TAGS.vegan,
  ENTITY_TAGS.delivery,
  ENTITY_TAGS.certified,
  ENTITY_TAGS.glutenFree,
];

export const BUSINESSES_TAGS_GB = [
  ENTITY_TAGS.benefit,
  ENTITY_TAGS.openNow,
  ENTITY_TAGS.vegan,
  ENTITY_TAGS.delivery,
  ENTITY_TAGS.kosher,
  ENTITY_TAGS.certified,
  ENTITY_TAGS.glutenFree,
];

export const BUSINESSES_TAGS_IL = [
  ENTITY_TAGS.benefit,
  ENTITY_TAGS.openNow,
  ENTITY_TAGS.vegan,
  ENTITY_TAGS.delivery,
  ENTITY_TAGS.kosher,
  ENTITY_TAGS.certified,
  ENTITY_TAGS.glutenFree,
];

export const BENEFITS_TAGS = [
  ENTITY_TAGS.restaurantBenefit,
  ENTITY_TAGS.businessBenefit,
  ENTITY_TAGS.delivery,
];

const getBusinessTagsByCountry = () => {
  const country = config.currentCountry;
  switch (country) {
    case "IL":
      return BUSINESSES_TAGS_IL;
    case "GB":
      return BUSINESSES_TAGS_GB;
    case "US":
      return BUSINESSES_TAGS_US;
  }
};

export const BUSINESSES_TAGS = getBusinessTagsByCountry();
