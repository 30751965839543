import { useState, useEffect } from "react";
import classes from "./MobileSearchResults.module.css";
import SearchResultsBottomSheet from "./SearchResultsBottomSheet";
import MapView from "../../Map/MapView";
import { sectionsRender } from "../../../pages/Search/SearchHelpers";
import icons, { iconsAlts } from "../../../resources/icons";
import { useNavigate } from "react-router-dom";
import { linkToSearch } from "../../../utils/links";

const MobileSearchResults = ({
  bottomSheetRef,
  sectionList,
  isLoading,
  userLocation,
  entitySelectHandler,
  searchParams,
  isMapShowing,
  onResultsPageResize,
  news,
  hasResults,
  isSearchOpen,
  totalPages,
  resultsNumber,
}) => {
  const navigate = useNavigate();

  const getEntities = () => {
    if (sectionList) {
      const entities = [];
      sectionList.map((section) =>
        entities.push(
          ...section.entities
            .filter((entity) => entity.location && entity.location.lon && entity.location.lat)
            .map((entity) => {
              entity.entityType = section.entityType;
              return entity;
            })
        )
      );
      return entities;
    }
    return [];
  };

  const [isMapButtonVisible, setMapButtonVisible] = useState(true);
  const resultsPageResizeHandler = (event) => {
    if (event === "SNAP_DOWN") {
      setMapButtonVisible(false);
    } else {
      setMapButtonVisible(true);
    }
    onResultsPageResize(event);
  };

  const onShowMapHandler = () => {
    if (isMapButtonVisible) {
      bottomSheetRef.current.snapTo(({ maxHeight }) => maxHeight * 0.05);
    }
  };

  const pageSelectHandler = (page) => {
    navigate(
      linkToSearch({
        entityType: searchParams.selectedEntity.name,
        input: searchParams.enteredInput,
        categoryNames: searchParams.selectedCategories.map((category) => category.category_name),
        tagNames: searchParams.selectedTags.map((tag) => tag.name),
        chainId: searchParams.selectedChainId,
        businessId: searchParams.selectedBusinessId,
        page,
        suggestOverride: true
      })
    );
  };

  return (
    <div className={classes.mainContainer}>
      {isMapShowing ? (
        <div className={classes.containerWithMap}>
          <div className={classes.showMapButtonContainer}>
            <button
              type="button"
              className={
                isMapButtonVisible ? classes.showMapButtonVisible : classes.showMapButtonHidden
              }
              onClick={onShowMapHandler}
            >
              <p className={classes.showMapText}>Map</p>
              <img src={icons.map} alt={iconsAlts.map} />
            </button>
          </div>

          <div className={classes.map}>
            <MapView
              center={userLocation && userLocation.coords ? userLocation.coords : null}
              zoom={14}
              markers={getEntities()}
              isLoading={isLoading}
              showLocationRequest={false}
              showInfoWindow={true}
            />
          </div>
          <SearchResultsBottomSheet
            sheetRef={bottomSheetRef}
            onPageResize={resultsPageResizeHandler}
            sectionList={sectionList}
            isLoading={isLoading}
            entitySelectHandler={entitySelectHandler}
            searchParams={searchParams}
            news={news}
            hasResults={hasResults}
            isSearchOpen={isSearchOpen}
            currentPage={searchParams.currentPage}
            totalPages={totalPages}
            onPageSelected={pageSelectHandler}
            resultsNumber={resultsNumber}
          />
        </div>
      ) : (
        <div className={classes.containerNoMap}>
          {sectionList &&
            sectionList.length &&
            sectionsRender.renderSections(
              sectionList,
              isLoading,
              entitySelectHandler,
              null,
              null,
              searchParams.selectedChainId,
              news
            )}
        </div>
      )}
    </div>
  );
};

export default MobileSearchResults;
