import { useEffect, useState, useRef } from "react";
import classes from "./SearchBar.module.css";
import icons from "../../../resources/icons";

const SearchBar = ({
  enteredInput = "",
  onSearch = () => {},
  onFocus = () => {},
  placeholder,
  onCloseResults = () => {},
  onClear = () => {},
  onEnter = () => {},
  searchBarClassName,
}) => {
  const [input, setInput] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);

  const ref = useRef();

  useEffect(() => {
    if (input !== enteredInput) {
      setInput(enteredInput);
      onCloseResults();
    }
  }, [enteredInput]);

  const inputHandler = (event) => {
    const newInput = event.target.value;
    setInput(newInput);

    if (timeoutId) clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        onSearch(newInput);
      }, 300)
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onEnter(input);
      e.target.blur();
    }
  };

  const onFocusHandler = () => {
    if (input) {
      onSearch(input);
    } else {
      onFocus();
    }
  };

  const clearHandler = () => {
    setInput("");
    onClear();
  };

  return (
    <div className={`${classes.mainContainer} ${searchBarClassName}`} ref={ref}>
      {/* <img className={classes.searchIcon} src={icons.searchIcon} /> */}
      <input
        ref={ref}
        value={input ? input : ""}
        id="input"
        type="search"
        className={classes.input}
        onChange={inputHandler}
        onFocus={onFocusHandler}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        autoComplete="off"
      />
      <img className={classes.searchIcon} src={icons.searchIcon} />
      {/* {input && <img className={classes.clearIcon} onClick={clearHandler} src={icons.closeX} />} */}
    </div>
  );
};

export default SearchBar;
